import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import in18n from './i18n';

import { Container, Nav, Navbar, NavDropdown, Row } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeConfig, writeDarkSwitch } from 'bootstrap-darkmode';
import { loadSetting, saveSetting } from "./store/SettingsStore";

import { createBrowserRouter, RouterProvider, } from 'react-router-dom';

import "./App.scss";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Symbols from './Symbols';
import Trade from './Trade';
import AltcoinSeasion from './com/AltcoinSeasion';
import AltcoinSeasonChart from './com/AltcoinSeasonChart';
import InterestCompound from './com/InterestCompound';
import NewTokenAddLiquit from './com/NewTokenAddLiquit';
import Bot, { TradingViewWidget } from './com/Bot';
import { log } from './std';
import AltcoinSeasonPair from './com/AltcoinSeasonPair';

const themeConfig = new ThemeConfig();
// place customizations here
themeConfig.initTheme();

themeConfig.loadTheme = () => {
  // custom logic
  return 'dark';
};

themeConfig.saveTheme = theme => {
  // custom logic
  console.log(theme);
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <AltcoinSeasonChart />,
  },
  {
    path: "/symbols",
    element: <Symbols />,
  },
  {
    path: "/altcoinPair/:pair",
    element: <AltcoinSeasonPair />
  },
  {
    path: "/trade",
    element: <Trade />,
  },
  {
    path: "/altcoinseasion",
    element: <AltcoinSeasonChart />,
  },
  {
    path: "/bot",
    element: <Bot />,
  },
  {
    path: "/InterestCompound",
    element: <InterestCompound />,
  },
  {
    path: "/NewTokenAddLiquit",
    element: <NewTokenAddLiquit />,
  },
]);

class App extends React.Component {

  state = {}

  constructor(props) {
    super(props)
    // const darkSwitch = writeDarkSwitch(themeConfig);
  }

  componentDidMount() {
    let { loadSetting, i18n } = this.props

    loadSetting().then(r => {
      i18n.changeLanguage(this.props.setting.language)
      // console.clear()
    })

    document.title = "Coin X Signals crypto";
    document.documentElement.setAttribute('data-bs-theme', 'dark')
  }

  changeTheme() {
    console.log(themeConfig.loadTheme(), themeConfig.saveTheme("dark"));
  }

  changeLanguage(lang = 'en') {
    let { saveSetting, i18n } = this.props
    let key = "language", value = lang;
    saveSetting({ key, value })
    i18n.changeLanguage(lang)
  }

  render() {
    const { t, setting } = this.props;
    return (
      <Container fluid>
        {/* menu */}
        <Row>
          <Navbar bg="primary" expand="lg">
            <Container> {/* {pathname} */}
              <Navbar.Brand href="/">🏠</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav style={{ color: "white" }}>
                  <Nav.Link href="/symbols">Symbols</Nav.Link>
                  <Nav.Link href="/trade">Trade</Nav.Link>
                  <Nav.Link href="/altcoinseasion">Altcoin Seasion</Nav.Link>
                  <Nav.Link href="/bot">Bot</Nav.Link>
                  <Nav.Link href="/InterestCompound">{t("Interest")}++</Nav.Link>
                  <Nav.Link href="/NewTokenAddLiquit">{t("New token add liquit")}</Nav.Link>

                  <NavDropdown title={"🌎 " + t("FLAG")}>
                    <NavDropdown.Item href={"#vi"} onClick={() => this.changeLanguage('vi')}>
                      Tiếng Việt
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#en" onClick={() => this.changeLanguage()}>
                      English
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Row >


        <Row id="router">
          <RouterProvider router={router} />
        </Row>

        {/* footer */}
        <footer className="footer">

          <ul className="social-links">
            <a className="social-link" href="https://t.me/coinx99" target='_blank'><img src={"/images/telegram.svg"} /></a>
            <a className="social-link" href="https://www.tiktok.com/@coinx99" target='_blank'><img src={"/images/tiktok.svg"} /></a>
            <a className="social-link" href="https://facebook.com/coinX99" target='_blank'><img src={"/images/facebook.svg"} /></a>
            <a className="social-link" href="https://www.youtube.com/@coinx99" target='_blank'><img src={"/images/youtube.svg"} /></a>
            <a className="social-link" href="https://twitter.com/coinx_trade" target='_blank'><img src={"/images/twitter.svg"} /></a>
            <a className="social-link" href="https://www.instagram.com/coinx.trade/" target='_blank'><img src={"/images/instagram.svg"} /></a>
          </ul>

          <p>
            &copy; 2023 &nbsp;
            <a className="menu__link" target='_blank' href={"https://" + setting.domain + "/about"}>@coinx99</a> | &nbsp;
            <a className="menu__link" target='_blank' href={"https://" + setting.domain}>{setting.domain}</a> | &nbsp;
            All Rights Reserved
          </p>

          <div className="waves">
            <div className="wave wave1"></div>
            <div className="wave wave2"></div>
            <div className="wave wave3"></div>
            <div className="wave wave4"></div>
          </div>
        </footer>


        <ToastContainer />
      </Container >
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  // web3: state.web3Store.web3,
  // accounts: state.web3Store.accounts,
  // WssUrl: state.WssStore.WssUrl,
  // Wss: state.WssStore.Wss,
  // connected: state.WssStore.connected,
  setting: state.SettingsStore.setting,
});

export default connect(mapStateToProps, {
  // connectWeb3: connectWeb3,
  // connectWebsocketServer: connectWebsocketServer,
  saveSetting: saveSetting,
  loadSetting: loadSetting,
})(withTranslation()(App));
