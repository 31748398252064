
import { createChart, ColorType } from 'lightweight-charts';
import React, { Component, createRef, } from 'react';
import { log } from '../std';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { loadSetting, saveSetting } from '../store/SettingsStore';

let count = 0;
class Tradingview extends Component {
    state = {
        colors: {
            backgroundColor: '#222',
            lineColor: '#2962FF',
            textColor: '#ddd',
            areaTopColor: '#2962FF',
            areaBottomColor: 'rgba(41, 98, 255, 0.28)',
        },
        dataSeries: undefined
    }
    constructor(props) {
        super(props);
        this.handleResize.bind(this)
        this.chartContainerRef = createRef();
        this.chart = undefined;
    }

    handleResize() {
        let { chartContainerRef } = this
        this.chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    componentDidMount() {
        let { chartContainerRef } = this
        let { colors } = this.state
        let { backgroundColor, lineColor, textColor, areaTopColor, areaBottomColor } = this.props
        log(areaBottomColor)
        count++;
        // if (count > 1) {
            this.chart = createChart(chartContainerRef.current, {
                layout: {
                    background: { color: backgroundColor || colors.backgroundColor },
                    textColor: textColor || colors.textColor,
                },
                width: chartContainerRef.current.clientWidth,
                height: 800,
                grid: {
                    vertLines: { color: '#444' },
                    horzLines: { color: '#444' },
                },
            });
            this.chart.timeScale().fitContent();

            const dataSeries = this.chart.addCandlestickSeries({ lineColor: lineColor || colors.lineColor, topColor: areaTopColor || colors.areaTopColor, bottomColor: areaBottomColor || colors.areaBottomColor });
            dataSeries.setData(this.props.data);

            window.addEventListener('resize', this.handleResize);
            this.setState({ dataSeries })
            // return () => {
            //     window.removeEventListener('resize', this.handleResize);
            //     chart.remove();
            // };
        // }
    }

    componentDidUpdate(preProps) {
        const { data } = this.props
        const { dataSeries } = this.state
        log(data)
        if (preProps.data && preProps.data !== data) {
            dataSeries.setData(data)
        }
    }


    render() {
        return (
            <div ref={this.chartContainerRef} />
        );
    }
}


const mapStateToProps = (state, ownProps) => ({
    setting: state.SettingsStore.setting,
});

export default connect(mapStateToProps, {
    saveSetting: saveSetting,
    loadSetting: loadSetting,
})(withTranslation()(Tradingview));
