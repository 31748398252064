import { logerror, logwarn, logsuccess, log, percentChange } from '../std';
import axios from 'axios';
import { EventEmitter } from 'events';
import { EMA, SMA, TRIX, WEMA } from 'technicalindicators'

import moment from 'moment-timezone';
import Exchanges from './Exchanges';
import Coinmarketcap from './Coinmarketcap';

const { error } = console;

export const DataTypes = {
    VolumeChange: "VolumeChange",
    PriceChange: "PriceChange",
    Performed: "Performed",
    NumberOfTrades: "NumberOfTrades",
    PerformedQuoteVolume: "PerformedQuoteVolume"
}

export const PairDataTypes = {
    changed: "changed",
    volume: "volume",
    trade: "trade"
}

export const StableCoins = ['USDT', 'BUSD', 'USDC', 'TUSD', 'USDP', 'UST', 'USDD', 'GUSD', 'USTC', 'DAI', 'USDJ', 'FRAX']
export const FIATS = ["USD", "GBP", "EUR", "BIDR", "BRL", "RUB", "TRY", "UAH", "NGN", "ZAR", "IDRT", "PLN", "RON", "ARS"]
export const WRAPCOINS = ["WBTC", "WETH", "WBNB", "WNXM"]
/**
 *  filter Duplicate Base Assets, example: 
const symbols = ['BTCUSDT','BTCUSDT','BTCBUSD','BTCBUSD', 'ETHBUSD', 'XRPUSDT', 'BNBBUSD', 'LTCBUSD', 'ETHUSDT', 'LINKBUSD', 'XRPBUSD'];
 * @param {string[]} symbols danh sách các cặp tiền
 * @param {string[]} QuoteAssets danh sách các quote tiền /USDT, /BUSD...
 * @returns {string[]} symbols
 */
export function filterDuplicateBaseAssets(symbols = [], QuoteAssets = StableCoins) {
    let matchQuoteAssets = QuoteAssets.join("|");
    // lọc trùng nhưng ưu tiên theo thứ tự 
    let filtered = {}
    symbols.forEach((symbol, index, self) => {
        let match = symbol.match(`(.+)(${matchQuoteAssets})$`)
        if (match) {
            const [base, quote] = match.slice(1);

            if (!filtered[base] || QuoteAssets.indexOf(quote) < QuoteAssets.indexOf(filtered[base]))
                filtered[base] = quote;

            return [base, quote];
        }
    })
    return Object.entries(filtered).map(([base, quote]) => base + quote)
}

export function filterDuplicateBaseAssetsNoPriority(symbols = [], QuoteAssets = StableCoins) {
    let matchQuoteAssets = QuoteAssets.join("|");
    let pattern = `(.+)(${matchQuoteAssets})$`

    return symbols.filter((symbol, index, self) => {
        if (symbol.match(pattern)) {
            const [baseAsset, quoteAsset] = symbol.match(pattern).slice(1);
            return index === self.findIndex((t) => {
                if (t.match(pattern)) {
                    const [_baseAsset, _quoteAsset] = t.match(pattern).slice(1);
                    return baseAsset === _baseAsset
                }
            })
        }
    })
}


export const CandlestickMap = {
    OpenTime: 0,
    Open: 1,
    High: 2,
    Low: 3,
    Close: 4,
    Volume: 5,
    CloseTime: 6,
    QuoteAssetVolume: 7,
    NumberOfTrades: 8,
    TakerBuyBaseAssetVolume: 9,
    TakerBuyQuoteAssetVolume: 10,
}

export const SmoothType = {
    None: "None", EMA: "EMA"
}

// Intervals: 1m,3m,5m,15m,30m,1h,2h,4h,6h,8h,12h,1d,3d,1w,1M
export const TIMEFRAMES = {
    // s1: "1s",
    m1: "1m",
    m3: "3m",
    m5: "5m",
    m15: "15m",
    m30: "30m",
    h1: "1h",
    h2: "2h",
    h4: "4h",
    h6: "6h",
    h8: "8h",
    d1: "1d",
    d3: "3d",
    w: "1w", // week
    M1: "1M", // month
    M3: "3M", // 3 months
    Y1: "1Y", // 1 year
    Y3: "3Y", // 1 year

    toMiliSecond: (timeframe = "5m") => {
        switch (timeframe) {
            case "1s":
                return 1000;
            case "1m":
                return 60000; // 1000 * 60;
            case "3m":
                return 180000; // 1000 * 60 * 3;
            case "5m":
                return 300000; // 1000 * 60 * 5;
            case "15m":
                return 900000; // 1000 * 60 * 15;
            case "30m":
                return 1800000; // 1000 * 60 * 30;
            case "45m":
                return 2700000; // 1000 * 60 * 45;
            case "1h":
                return 3600000; // 1000 * 60 * 60;
            case "2h":
                return 7200000; // 1000 * 60 * 60 * 2;
            case "3h":
                return 10800000; // 1000 * 60 * 60 * 3;
            case "4h":
                return 14400000; // 1000 * 60 * 60 * 4;
            case "6h":
                return 21600000; // 1000 * 60 * 60 * 6;
            case "8h":
                return 28800000; // 1000 * 60 * 60 * 8;
            case "1d":
                return 86400000; // 1000 * 60 * 60 * 24;
            case "3d":
                return 259200000; // 1000 * 60 * 60 * 24 * 3;
            case "1w": // week
                return 604800000; // 1000 * 60 * 60 * 24 * 7;
            case "1M": // month
                return 2592000000; // 1000 * 60 * 60 * 24 * 30;
            case "3M":
                return 7776000000; // 1000 * 60 * 60 * 24 * 30 * 3;
            case "1Y":
                return 31536000000; // 1000 * 60 * 60 * 24 * 365;
            case "3Y":
                return 94608000000; // 1000 * 60 * 60 * 24 * 365 * 3;

            default: // 1d
                return 86400000; // 1000 * 60 * 60 * 24;
        }
    },

    plusTimeWait: (timeframe = "5m") => {
        switch (timeframe) {
            case "1s":
                return 1;
            case "1m":
                return 1;
            case "3m":
                return 1;
            case "5m":
                return 1; // 1000 * 30;
            case "15m":
                return 40000; // 1000 * 40;
            case "30m":
                return 50000; // 1000 * 50;
            case "1h":
                return 60000; // 1000 * 60;
            case "4h":
                return 70000; // 1000 * 70;
            case "8h":
                return 80000; // 1000 * 80;
            case "1d":
                return 90000; // 1000 * 90;
            case "3d":
                return 100000; // 1000 * 100;
            case "1w": // week
                return 120000; // 1000 * 120;
            case "1M": // month
                return 140000; // 1000 * 140;
            case "3M": // month
                return 140000; // 1000 * 140;
            case "1Y": // month
                return 140000; // 1000 * 140;
            case "3Y": // month
                return 140000; // 1000 * 140;

            default: // 1d
                return; // 1000 * 90;
        }
    },

    /**
     * chuyển đổi từ timeframe sang số chu kì so với đơn vị, ví dụ 1h thì có bao nhiêu 1phút. hoặc bao nhiêu 15 phút 
     * @param {string} timeframe 
     * @param {string} periodTimeframe 
     * @return {int} period 
     */
    toPeriod: (timeframe = "5m", periodTimeframe = "1m") => {
        return Math.floor(TIMEFRAMES.toMiliSecond(timeframe) / TIMEFRAMES.toMiliSecond(periodTimeframe))
    },

    mapDownUnit: {
        "m": "m",
        "h": "m",
        "d": "h",
        "w": "d",
        "M": "d",
        "Y": "M",
    },

    /**
     * hạ đơn vị, ví dụ: 1h => 1m, 4h => 1h
     * @param {string} timeframe 
     * @returns {string}
     */
    downUnit: (timeframe = "5m") => {
        const regex = /^(\d+)([a-zA-Z]+)$/;
        const matches = timeframe.match(regex);
        const [_, number, unit] = matches;
        if (unit == "Y")
            return 1 + TIMEFRAMES.mapDownUnit[unit];
        else if (TIMEFRAMES.mapDownUnit[unit] == "m")
            return "5m";
        else if (number == 1)
            return 1 + TIMEFRAMES.mapDownUnit[unit];
        else
            return 1 + unit;
    },

    /**
     * tìm các timeframe lớn hơn cùng đơn vị , ví dụ: 1m => 3m, 5m,... 1h, 1h => 2h, 4h, 1d
     * @param {string} timeframe 
     * @returns {string[]}
     */
    upUnits: (timeframe = "5m") => {
        // tìm phần tử cùng hoặc trên đơn vị có chung downUnit, ví dụ 1h => 1d
        let down = TIMEFRAMES.downUnit(timeframe)
        let time = TIMEFRAMES.toMiliSecond(timeframe)

        return Object.values(TIMEFRAMES).filter(v => typeof (v) == "string")
            .filter(v => ((TIMEFRAMES.toMiliSecond(v)) > time && (TIMEFRAMES.downUnit(v) == down)))
    },

    /**
     * lấy tất cả các đơn vị thời gian lớn hơn
     * @param {string} timeframe 
     * @returns {string[]}
     */
    allUpUnits: (timeframe = "5m") => {
        let time = TIMEFRAMES.toMiliSecond(timeframe);
        return Object.values(TIMEFRAMES).filter(v => typeof (v) == "string")
            .filter(v => (time < TIMEFRAMES.toMiliSecond(v)));
    },

    /**
     * Tự động tính chu kì của timeframe có bao nhiêu chu kì con
     * @param {string} timeframe 
     * @returns {int}
     */
    toPeriodDownUnit(timeframe = "5m") {
        return TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe))
    },

    toColor: (timeframe = "5m") => {
        let Colors = {
            m1Color: "#c0c0c0",
            m3Color: "#fbaed2",
            m5Color: "#4f81bc",
            m15Color: "#c0504e",
            m30Color: "#9bbb58",
            h1Color: "#23bfaa",
            h2Color: "#af69ee",
            h4Color: "#8064a1",
            h6Color: "#702963",
            h8Color: "#f79647",
            d1Color: "#3bb143",
            d3Color: "#0b6623",
            w1Color: "#066399", // week
            M1Color: "red", // month
            M3Color: "#5e3906",
            M6Color: "#325a04",
            Y1Color: "#08045a",
            Y3Color: "#92083a",
            Performed: "#4208ad",
            changed: "#4208ad",
            VolumeChange: "#ffc107",
            volume: "#ffc107",
            NumberOfTrades: "#f0f8ffad",
            EMA48: "#4CAF50",
            EMA30: "#4CAF50",
            EMA96: "#ffeb3b",
            EMA60: "#ffeb3b",
            EMA288: "#ff5252",
            EMA240: "#ff5252",
            EMA864: "#0f92b4",
            EMA480: "#0f92b4",
        }
        if (Colors[timeframe])
            return Colors[timeframe]

        function splitString(str) {
            const pattern = /([a-zA-Z]+)|(\d+)/g; // Regular expression để phân tách chữ và số
            const result = [];

            let match;
            while ((match = pattern.exec(str)) !== null) {
                if (match[1]) {
                    result.push(match[1]); // Thêm chữ vào mảng kết quả
                } else {
                    result.push(match[2]); // Thêm số vào mảng kết quả
                }
            }

            return result;
        }
        let tf = splitString(timeframe)
        return Colors[tf[1] + tf[0] + "Color"]

    },
}

class AltcoinSeason {
    Settings = {}
    event
    exchange
    AltcoinSeasonsNow = {}
    pairs = {
        BTCUSDT: []
    }
    coinmarketcap = new Coinmarketcap()

    /**
     * @param {object} Data dữ liệu timeframe đã truy vấn
     * là object với key là {timeframe : SymbolsKlines}
     * SymbolsKlines là object với key là {Symbol : Klines}
     */
    Data = {}

    /**
     * @param {object[]} Symbols
     * Dữ liệu 24h của các đồng coin lấy từ CoinmarketCap
     */
    Symbols = []

    constructor(exchange = new Exchanges(Exchanges.Binance), event = new EventEmitter()) {
        this.event = event
        this.exchange = exchange
        this.loadSetting()

    }

    async loadSetting() {
        return axios.get("/Settings.json")
            .then(r => r.data)
            .then(data => {
                this.Settings = { ...data, ...this.Settings };
            })
            .catch(err => {
                console.error(err);
            })
    }

    // lưu altcoinSeason đã tính được 
    saveData(name, data) {
        // console.info({ "saveData": name, "length": data.length, })
        localStorage.removeItem(name)
        localStorage.setItem(name, JSON.stringify(data))
    }


    /**
     * tính altcoin season của SymbolsKlines, là chart của nhiều cặp tiền trong 1 timeframe
     * @param {object} SymbolsKlines mảng chứa danh sách cặp tiền và mảng nến của cặp đó
     * @param {string} timeframe example: "5m", "1h",...
     * @returns {object[]}Altcoin Season Data
     */
    async calAltcoinSeason(SymbolsKlines = {}, timeframe) {
        let SYMBOL = false;
        let openTime = 0;
        let AltcoinSeasonData = []
        // duyệt tất cả các SymbolsKlines, tìm xem cặp nào có nến mới nhất và dài nhất, đánh dấu cặp đó làm mốc chuẩn
        for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
            let Kline = Klines.length > 1 ? Klines[Klines.length - 2] : Klines[Klines.length - 1];
            if (!SYMBOL || (
                // openTime < Number(Kline[CandlestickMap.OpenTime]) &&
                SymbolsKlines[Symbol].length > SymbolsKlines[SYMBOL].length)) {
                openTime = Number(Kline[CandlestickMap.OpenTime]);
                SYMBOL = Symbol;
            }
        }

        // duyệt các phần tử trong cặp chuẩn, 
        for (const _Kline of SymbolsKlines[SYMBOL]) {
            const OpenTime = Number(_Kline[CandlestickMap.OpenTime]);

            let _PositiveChanges = [];
            let _TotalVolumeChange = 0;
            let _TotalVolume = 0;
            let _time;
            let _countSymbolsIncrease = 0
            let _countSymbols = 0
            let PriceChange = 0

            // mỗi nến, tìm nến ở các cặp Symbols khác, có chung OpenTime, đưa vào mảng và tính altcoin seasion 
            for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
                let Kline = Klines.find(Kline => Number(Kline[CandlestickMap.OpenTime]) == OpenTime)
                if (Kline) {

                    // tính altcoin season
                    // nếu giá thay đổi thì thêm vào danh sách PositiveChanges
                    if (parseFloat(Kline[CandlestickMap.Open]) - parseFloat(Kline[CandlestickMap.Close]) > 0) {
                        // _PositiveChanges.push(Symbol);
                        _countSymbolsIncrease++;
                        // Tổng khối lượng giao dịch
                        _TotalVolumeChange += parseFloat(Kline[CandlestickMap.QuoteAssetVolume]);
                    }

                    // tổng số phần trăm giá thay đổi
                    PriceChange += percentChange(parseFloat(Kline[CandlestickMap.Open]), parseFloat(Kline[CandlestickMap.Close]))

                    _time = Number(Kline[CandlestickMap.CloseTime])
                    _TotalVolume += parseFloat(Kline[CandlestickMap.QuoteAssetVolume]);
                    _countSymbols++
                }
            }

            // Calculate the percentage of total altcoin trading volume represented by the positive-change coins
            let VolumeChange = (_TotalVolumeChange / _TotalVolume) * 100;
            // số lượng symbol tăng giá tính theo %
            let Performed = (_countSymbolsIncrease / _countSymbols) * 100;
            if (!isNaN(VolumeChange)) {
                AltcoinSeasonData.push({
                    // PositiveChanges: _PositiveChanges,
                    TotalVolumeChange: _TotalVolumeChange,
                    TotalVolume: _TotalVolume,
                    Time: _time,
                    VolumeChange,
                    PriceChange,
                    Performed,
                })
            }
        }
        AltcoinSeasonData.sort((a, b) => a.Time - b.Time)
        this.event.emit("calAltcoinSeasonFinished", { timeframe: timeframe, AltcoinSeasons: AltcoinSeasonData })
        return AltcoinSeasonData;
    }

    /**
     * tính altcoin season của đa khung thời gian
     * @param {string[]} Symbols mảng danh sách các cặp tiền
     * @param {string[]} TimeFrames mảng danh sách timeframe
     * @param {moment} StartTime thời gian bắt đầu
     * @param {moment} EndTime thời gian kết thúc
     * @param {int} index thứ tự được đệ quy trong Symbols
     */
    async calAltcoinSeasonMultiTimeFrames(Symbols = [], TimeFrames = [], StartTime, EndTime, index = 0) {
        if (index < TimeFrames.length) {
            try {
                let tf = TimeFrames[index];
                let SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, tf);

                let AltcoinSeasons = await this.calAltcoinSeason(SymbolsKlines, tf)
                this.saveData(tf, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonMultiTimeFrames", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf, StartTime, EndTime, index })
            } catch (err) { }
            return this.calAltcoinSeasonMultiTimeFrames(Symbols, TimeFrames, StartTime, EndTime, index + 1);
        } else
            return undefined;
    }

    /**
     * tính altcoin season của đa khung thời gian với StartTime, EndTime khác nhau
     * @param {string[]} Symbols mảng danh sách các cặp tiền
     * @param {object[]} TimeFrames 
     * @param {int} index thứ tự được đệ quy trong Symbols
     * @returns 
     */
    async calAltcoinSeasonMultiTimeFramesTimeRanges(Symbols = [], TimeFrames = [{ Name: "1h", StartTime: moment().subtract(4, "hours").valueOf(), EndTime: moment().valueOf() }], index = 0) {
        if (index < TimeFrames.length) {
            try {
                let tf = TimeFrames[index];

                let SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, tf.StartTime, tf.EndTime, tf.Name);

                let AltcoinSeasons = await this.calAltcoinSeason(SymbolsKlines, tf.Name)
                this.saveData(tf.Name, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonMultiTimeFramesTimeRanges", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf, index })
            } catch (err) {
                if (err.code != -1121)
                    logerror("calAltcoinSeasonMultiTimeFramesTimeRanges: ", err)
            }
            return this.calAltcoinSeasonMultiTimeFramesTimeRanges(Symbols, TimeFrames, index + 1);
        }
        return undefined;
    }

    // *******************************


    /**
     * tính altcoin season của SymbolsKlines, là chart của nhiều cặp tiền trong 1 timeframe
     * @param {object} SymbolsKlines mảng chứa danh sách cặp tiền và mảng nến của cặp đó
     * @param {string} timeframe example: "5m", "1h",...
     * @param {string} indicator loại chỉ báo
     * @returns {object[]}Altcoin Season Data
    */
    async calAltcoinSeasonByIndicator(SymbolsKlines = {}, timeframe, indicator) {
        let SYMBOL = false;
        let openTime = 0;
        let AltcoinSeasonData = []
        // duyệt tất cả các SymbolsKlines, tìm xem cặp nào có nến mới nhất và dài nhất, đánh dấu cặp đó làm mốc chuẩn
        for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
            let Kline = Klines[Klines.length - 1];
            if (!SYMBOL || (openTime < Number(Kline[CandlestickMap.OpenTime]) && SymbolsKlines[Symbol].length > SymbolsKlines[SYMBOL].length)) {
                openTime = Number(Kline[CandlestickMap.OpenTime]);
                SYMBOL = Symbol;
            }
        }

        // duyệt các phần tử trong cặp chuẩn, 
        for (const _Kline of SymbolsKlines[SYMBOL]) {
            const OpenTime = Number(_Kline[CandlestickMap.OpenTime]);

            let _PositiveChanges = [];
            let _TotalVolumeChange = 0;
            let _TotalVolume = 0;
            let _time;
            // mỗi nến, tìm nến ở các cặp Symbols khác, có chung OpenTime, đưa vào mảng và tính altcoin seasion 
            for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {

                let Kline = Klines.find(Kline => Number(Kline[CandlestickMap.OpenTime]) == OpenTime)
                if (Kline) {

                    // tính altcoin season
                    // nếu giá tăng thì thêm vào danh sách PositiveChanges
                    if (parseFloat(Kline[CandlestickMap.Close]) - parseFloat(Kline[CandlestickMap.Open]) > 0) {
                        _PositiveChanges.push(Symbol);
                        // Tổng khối lượng giao dịch
                        _TotalVolumeChange += parseFloat(Kline[CandlestickMap.Volume]);

                    }
                    _time = Kline[CandlestickMap.CloseTime]
                    _TotalVolume += parseFloat(Kline[CandlestickMap.Volume]);
                }
            }

            // Calculate the percentage of total altcoin trading volume represented by the positive-change coins
            let VolumeChange = (_TotalVolumeChange / _TotalVolume) * 100;
            if (!isNaN(VolumeChange)) {
                AltcoinSeasonData.push({
                    PositiveChanges: _PositiveChanges,
                    TotalVolumeChange: _TotalVolumeChange,
                    TotalVolume: _TotalVolume,
                    Time: _time,
                    VolumeChange,
                })
            }
        }
        let data = []
        switch (indicator) {
            case "EMA":
                data = EMA.calculate({ period: TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe)), values: AltcoinSeasonData.map(ac => ac.VolumeChange) });
                break;
            case "SMA":
                data = SMA.calculate({ period: TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe)), values: AltcoinSeasonData.map(ac => ac.VolumeChange) });
                break;
            case "WEMA":
                data = WEMA.calculate({ period: TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe)), values: AltcoinSeasonData.map(ac => ac.VolumeChange) });
                break;
            case "TRIX":
                data = TRIX.calculate({ period: TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe)), values: AltcoinSeasonData.map(ac => ac.VolumeChange) });
                break;
        }

        if (data.length > 0) {
            data.forEach((v, i) => {
                AltcoinSeasonData[AltcoinSeasonData.length - data.length + i].VolumeChange = v
            })
            AltcoinSeasonData = AltcoinSeasonData.slice(AltcoinSeasonData.length - data.length)
        }

        this.event.emit("calAltcoinSeasonFinished", { timeframe: timeframe, AltcoinSeasons: AltcoinSeasonData })
        return AltcoinSeasonData;
    }

    /**
     * tính altcoin season của đa khung thời gian
     * @param {string[]} Symbols mảng danh sách các cặp tiền
     * @param {string[]} TimeFrames mảng danh sách timeframe
     * @param {moment} StartTime thời gian bắt đầu
     * @param {moment} EndTime thời gian kết thúc
     * @param {string} indicator loại chỉ báo
     * @param {int} index thứ tự được đệ quy trong Symbols
     */
    async calAltcoinSeasonMultiTimeFramesByIndicator(Symbols = [], TimeFrames = [], StartTime, EndTime, indicator, index = 0) {
        if (index < TimeFrames.length) {
            try {
                let tf = TimeFrames[index];
                let SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, TIMEFRAMES.downUnit(tf));

                let AltcoinSeasons = await this.calAltcoinSeasonByIndicator(SymbolsKlines, tf, indicator)
                this.saveData(tf, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonMultiTimeFrames", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf, StartTime, EndTime, index })
            } catch (err) { }
            return this.calAltcoinSeasonMultiTimeFramesByIndicator(Symbols, TimeFrames, StartTime, EndTime, indicator, index + 1);
        } else
            return undefined;
    }

    /**
     * tính altcoin season của đa khung thời gian với StartTime, EndTime khác nhau
     * @param {string[]} Symbols mảng danh sách các cặp tiền
     * @param {object[]} TimeFrames 
     * @param {string} indicator loại chỉ báo
     * @param {int} index thứ tự được đệ quy trong Symbols
     * @returns 
     */
    async calAltcoinSeasonMultiTimeFramesTimeRangesByIndicator(Symbols = [], TimeFrames = [{ Name: "1h", StartTime: moment().subtract(4, "hours").valueOf(), EndTime: moment().valueOf() }], indicator, index = 0) {
        if (indicator == "HOLGER") {
            return this.calAltcoinSeasonMultiTimeFramesTimeRangesByHolger(Symbols, TimeFrames)
        }

        if (index < TimeFrames.length) {
            try {
                let tf = TimeFrames[index];
                let SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, tf.StartTime, tf.EndTime, TIMEFRAMES.downUnit(tf.Name));

                let AltcoinSeasons = await this.calAltcoinSeasonByIndicator(SymbolsKlines, tf.Name, indicator)

                this.saveData(tf.Name, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonMultiTimeFramesTimeRanges", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf, index })
            } catch (err) {
                if (err.code != -1121)
                    logerror("calAltcoinSeasonMultiTimeFramesTimeRangesByIndicator: ", err)
            }
            return this.calAltcoinSeasonMultiTimeFramesTimeRangesByIndicator(Symbols, TimeFrames, indicator, index + 1);
        }
        return undefined;
    }
    // *******************************


    /**
     * tính Altcoin season chuẩn Holger
     * lấy Top coins và BTCUSDT
     * lấy hết nến 1m, 1h, 1d, 1w, 1M
     * số coin tăng giá có % tăng nhiều hơn BTCUSDT: n
     * tổng số lượng coin: A
     * p = n/A*100
     * @param {Object} SymbolsKlines danh sách các cặp tiền và nến
     * @param {string} timeframe khung thời gian
     * @param {Object} options thông số tùy chọn khi tính toán
     */
    async calAltcoinSeasonByHolger(SymbolsKlines = {}, timeframe, options = { amountOfTops: 50, SymbolAltcoin: "BTCUSDT" }) {
        console.clear()
        log(Object.keys(SymbolsKlines))
        let SYMBOL = false;
        let openTime = 0;
        let AltcoinSeasonData = []
        // duyệt tất cả các SymbolsKlines, tìm xem cặp nào có nến mới nhất và dài nhất, đánh dấu cặp đó làm mốc chuẩn
        for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
            let Kline = Klines.length > 1 ? Klines[Klines.length - 2] : Klines[Klines.length - 1];
            if (!SYMBOL || (
                // openTime < Number(Kline[CandlestickMap.OpenTime]) &&
                SymbolsKlines[Symbol].length > SymbolsKlines[SYMBOL].length)) {
                openTime = Number(Kline[CandlestickMap.OpenTime]);
                SYMBOL = Symbol;
            }
        }

        let LastChanges
        // duyệt các phần tử trong cặp chuẩn
        for (const _Kline of SymbolsKlines[SYMBOL]) {
            const OpenTime = Number(_Kline[CandlestickMap.OpenTime]);

            let Changes = []
            let PriceChange = 0

            let _TotalVolumeChange = 0;
            let _TotalVolume = 0;
            let TotalNumberOfTrades = 0;
            let TotalNumberOfTradesChange = 0;

            // mỗi nến, tìm nến ở các cặp Symbols khác, có chung OpenTime, đưa vào mảng và tính altcoin seasion 
            for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
                let i = Klines.findIndex(Kline => Number(Kline[CandlestickMap.OpenTime]) == OpenTime)
                if (i >= 0) {
                    // chu kì, ví dụ 5m sẽ là 5 nến 1m, lấy Close của i trừ Open của _i
                    let _i = i - TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe))

                    if (_i >= 0) {
                        let changed = percentChange(Number(Klines[i][CandlestickMap.Close]), Number(Klines[_i][CandlestickMap.Open]))
                        PriceChange += changed
                        let volume = 0
                        let QuoteAssetVolume = 0
                        let NumberOfTrades = 0
                        for (let index = _i; index <= i; index++) {
                            volume += Number(Klines[index][CandlestickMap.Volume])
                            QuoteAssetVolume += Number(Klines[index][CandlestickMap.QuoteAssetVolume])
                            NumberOfTrades += Number(Klines[index][CandlestickMap.NumberOfTrades])
                        }
                        if (changed > 0) {
                            _TotalVolumeChange += QuoteAssetVolume;
                            TotalNumberOfTradesChange += NumberOfTrades;
                        }
                        _TotalVolume += QuoteAssetVolume;
                        TotalNumberOfTrades += NumberOfTrades;

                        Changes.push({
                            "Symbol": Symbol, changed, QuoteAssetVolume, volume,
                            Close: Number(Klines[i][CandlestickMap.Close]),
                            Open: Number(Klines[_i][CandlestickMap.Open]),
                            OpenTime: Number(Klines[_i][CandlestickMap.OpenTime]),
                            CloseTime: Number(Klines[i][CandlestickMap.CloseTime]),
                            NumberOfTrades,
                        })
                    }
                }
            }

            if (Changes.length > 0) {
                // xếp giảm dần
                Changes.sort((a, b) => b.QuoteAssetVolume - a.QuoteAssetVolume);
                // lấy 50 đồng có khối lượng giao dịch lớn nhất, xếp tăng dần theo mức độ tăng giá
                Changes = Changes.slice(0, options.amountOfTops || 50)//.sort((a, b) => b.changed - a.changed)

                // danh sách những đồng coin ở nến cuối cùng
                LastChanges = Changes;

                // tìm vị trí của BTC hoặc đồng coin đang xét làm index
                let BTCIndex = Changes.findIndex(s => s.Symbol == (options.SymbolAltcoin || "BTCUSDT"))
                // đếm những đồng coin có mức tăng giá tốt hơn BTC
                let count_better_changed = 1;
                Changes.forEach(s => {
                    if (s.changed < Changes[BTCIndex].changed)
                        count_better_changed++
                })
                let Performed = (count_better_changed / Changes.length) * 100

                // // Những đồng coin có hiệu năng cao hơn BTC, tổng PerformedQuoteAssetVolume / tổng QuoteAssetVolume của toàn bộ * 100
                // let SumPerformedQuoteAssetVolume = 0, SumQuoteAssetVolume = 0;
                // Changes.forEach((c, i) => {
                //     SumQuoteAssetVolume += c.QuoteAssetVolume;
                //     if (i < BTCIndex)
                //         SumPerformedQuoteAssetVolume += c.QuoteAssetVolume;
                // })

                let PerformedQuoteVolume = 0 // SumPerformedQuoteAssetVolume / SumQuoteAssetVolume * 100;

                let VolumeChange = (_TotalVolumeChange / _TotalVolume) * 100;
                let NumberOfTrades = (TotalNumberOfTradesChange / TotalNumberOfTrades) * 100;

                if (!isNaN(Performed)) {
                    AltcoinSeasonData.push({
                        Time: Number(_Kline[CandlestickMap.CloseTime]),
                        Performed,
                        PriceChange,
                        VolumeChange,
                        PerformedQuoteVolume,
                        NumberOfTrades,
                    })
                }
            }
        }

        AltcoinSeasonData.sort((a, b) => a.Time - b.Time)
        AltcoinSeasonData[AltcoinSeasonData.length - 1].Changes = LastChanges.map(s => {
            let c = this.Symbols.find(c => s.Symbol.startsWith(c.symbol))
            if (c) {
                s.marketCap = c.marketCap
            }
            return s;
        });
        this.event.emit("calAltcoinSeasonFinished", { timeframe: timeframe, AltcoinSeasons: AltcoinSeasonData })
        return AltcoinSeasonData;
    }
    async calAltcoinSeasonByHolgerx(SymbolsKlines = {}, timeframe, options = { amountOfTops: 50, SymbolAltcoin: "BTCUSDT" }) {
        let SYMBOL = false;
        let openTime = 0;
        let AltcoinSeasonData = []
        // duyệt tất cả các SymbolsKlines, tìm xem cặp nào có nến mới nhất và dài nhất, đánh dấu cặp đó làm mốc chuẩn
        for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
            let Kline = Klines.length > 1 ? Klines[Klines.length - 2] : Klines[Klines.length - 1];
            if (!SYMBOL || (
                // openTime < Number(Kline[CandlestickMap.OpenTime]) &&
                SymbolsKlines[Symbol].length > SymbolsKlines[SYMBOL].length)) {
                openTime = Number(Kline[CandlestickMap.OpenTime]);
                SYMBOL = Symbol;
            }
        }

        let LastChanges
        // duyệt các phần tử trong cặp chuẩn
        for (const _Kline of SymbolsKlines[SYMBOL]) {
            const OpenTime = Number(_Kline[CandlestickMap.OpenTime]);

            let Changes = []
            let PriceChange = 0

            let _TotalVolumeChange = 0;
            let _TotalVolume = 0;
            let TotalNumberOfTrades = 0;
            let TotalNumberOfTradesChange = 0;

            // mỗi nến, tìm nến ở các cặp Symbols khác, có chung OpenTime, đưa vào mảng và tính altcoin seasion 
            for (const [Symbol, Klines] of Object.entries(SymbolsKlines)) {
                let i = Klines.findIndex(Kline => Number(Kline[CandlestickMap.OpenTime]) == OpenTime)
                if (i >= 0) {
                    // chu kì, ví dụ 5m sẽ là 5 nến 1m, lấy Close của i trừ Open của _i
                    let _i = i - TIMEFRAMES.toPeriod(timeframe, TIMEFRAMES.downUnit(timeframe))

                    if (_i >= 0) {
                        let changed = percentChange(Number(Klines[i][CandlestickMap.Close]), Number(Klines[_i][CandlestickMap.Open]))
                        PriceChange += changed
                        let volume = 0
                        let QuoteAssetVolume = 0
                        let NumberOfTrades = 0
                        for (let index = _i; index <= i; index++) {
                            volume += Number(Klines[index][CandlestickMap.Volume])
                            QuoteAssetVolume += Number(Klines[index][CandlestickMap.QuoteAssetVolume])
                            NumberOfTrades += Number(Klines[index][CandlestickMap.NumberOfTrades])
                        }
                        if (changed > 0) {
                            _TotalVolumeChange += QuoteAssetVolume;
                            TotalNumberOfTradesChange += NumberOfTrades;
                        }
                        _TotalVolume += QuoteAssetVolume;
                        TotalNumberOfTrades += NumberOfTrades;

                        Changes.push({
                            "Symbol": Symbol, changed, QuoteAssetVolume, volume,
                            Close: Number(Klines[i][CandlestickMap.Close]),
                            Open: Number(Klines[_i][CandlestickMap.Open]),
                            OpenTime: Number(Klines[_i][CandlestickMap.OpenTime]),
                            CloseTime: Number(Klines[i][CandlestickMap.CloseTime]),
                            NumberOfTrades,
                        })
                    }
                }
            }

            if (Changes.length > 0) {
                // xếp giảm dần
                Changes.sort((a, b) => b.QuoteAssetVolume - a.QuoteAssetVolume);
                // lấy 50 đồng có khối lượng giao dịch lớn nhất, xếp tăng dần theo mức độ tăng giá
                Changes = Changes.slice(0, options.amountOfTops || 50).sort((a, b) => b.changed - a.changed)
                LastChanges = Changes;

                let BTCIndex = Changes.findIndex(s => s.Symbol == (options.SymbolAltcoin || "BTCUSDT"))
                let Performed = ((Changes.length - BTCIndex - 1) / Changes.length) * 100

                // Những đồng coin có hiệu năng cao hơn BTC, tổng PerformedQuoteAssetVolume / tổng QuoteAssetVolume của toàn bộ * 100
                let SumPerformedQuoteAssetVolume = 0, SumQuoteAssetVolume = 0;
                Changes.forEach((c, i) => {
                    SumQuoteAssetVolume += c.QuoteAssetVolume;
                    if (i < BTCIndex)
                        SumPerformedQuoteAssetVolume += c.QuoteAssetVolume;
                })

                let PerformedQuoteVolume = SumPerformedQuoteAssetVolume / SumQuoteAssetVolume * 100;

                let VolumeChange = (_TotalVolumeChange / _TotalVolume) * 100;
                let NumberOfTrades = (TotalNumberOfTradesChange / TotalNumberOfTrades) * 100;

                if (!isNaN(Performed)) {
                    AltcoinSeasonData.push({
                        Time: Number(_Kline[CandlestickMap.CloseTime]),
                        Performed,
                        PriceChange,
                        VolumeChange,
                        PerformedQuoteVolume,
                        NumberOfTrades,
                    })
                }
            }
        }
        AltcoinSeasonData.sort((a, b) => a.Time - b.Time)
        AltcoinSeasonData[AltcoinSeasonData.length - 1].Changes = LastChanges.map(s => {
            let c = this.Symbols.find(c => s.Symbol.startsWith(c.symbol))
            if (c) {
                s.marketCap = c.marketCap
            }
            return s;
        });
        this.event.emit("calAltcoinSeasonFinished", { timeframe: timeframe, AltcoinSeasons: AltcoinSeasonData })
        return AltcoinSeasonData;
    }

    async calAltcoinSeasonMultiTimeFramesTimeRangesByHolger(Symbols = [], TimeFrames = [{ Name: "1h", StartTime: moment().subtract(4, "hours").valueOf(), EndTime: moment().valueOf() }], options = { amountOfTops: 50 }, index = 0) {
        if (index < TimeFrames.length) {

            let tf = TimeFrames[index];
            if (!Symbols.find(s => s == "BTCUSDT"))
                Symbols.push("BTCUSDT")
            let SymbolsKlines;
            let downUnit = TIMEFRAMES.downUnit(tf.Name)
            // nếu timeframe trước đó mà có chung cấp TIMEFRAMES.downUnit thì khỏi truy vấn mà dùng SymbolsKlines có trong this.Data..SymbolsKlines
            if (TimeFrames[index - 1] && TIMEFRAMES.downUnit(TimeFrames[index - 1].Name) == downUnit && this.Data[downUnit])
                SymbolsKlines = this.Data[downUnit];
            else {
                SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, tf.StartTime, tf.EndTime, downUnit);
                this.Data[downUnit] = SymbolsKlines;
            }
            let AltcoinSeasons = await this.calAltcoinSeasonByHolger(SymbolsKlines, tf.Name, options)

            this.saveData(tf.Name, AltcoinSeasons)
            this.event.emit("calAltcoinSeasonMultiTimeFramesTimeRanges", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf, index })

            return this.calAltcoinSeasonMultiTimeFramesTimeRangesByHolger(Symbols, TimeFrames, options, index + 1);
        }
        return undefined;
    }

    /**
     * Tính altcoin season của 1 cặp tiền
     * @param {string} pair 
     * @param {string} minTimeframe 
     * @param {number} StartTime default 30 days
     * @param {number} EndTime default now
     * @returns 
     */
    async calPairAltcoin(pair = "ETHUSDT", minTimeframe = "1m", StartTime = moment().valueOf() - 2592000000, EndTime = moment().valueOf()) {
        log(pair, minTimeframe, StartTime, "-", EndTime)
        let Klines = await this.exchange.getKlines(pair, StartTime, EndTime, minTimeframe)
        if (!this.pairs[pair])
            this.pairs[pair] = { [minTimeframe]: Klines }
        else
            this.pairs[pair] = { ...this.pairs[pair], [minTimeframe]: Klines }

        return TIMEFRAMES.allUpUnits(minTimeframe).map(tf => {
            // tại mỗi thời điểm, tính xem biên thay đổi giá của nến hiện tại xếp hạng bao nhiêu so với cây nến trước đó
            let amountCandle = parseInt(TIMEFRAMES.toMiliSecond(tf) / TIMEFRAMES.toMiliSecond(minTimeframe));
            if (amountCandle > 2)
                return {
                    name: tf,
                    points: this.calPair(Klines, amountCandle),
                    Klines: Klines,
                }
        }).filter(v => v)
    }

    /**
     * Hàm tính altcoin season 1 cặp tiền theo sự biến đổi giá và khối lượng trong số lượng cây nến
     * @param {Number[]} Klines Nến lấy từ sàn
     * @param {Number} amountCandle Số đơn vị nến dùng để tính, ví dụ 8h/5m = 160
     * @returns @param {object{Time, changed, volume}[]}
     */
    calPair(Klines = [], amountCandle = 96) {
        let lastIndex = Klines.length - 1
        let toIndex = amountCandle - 2
        let points = []
        // lập danh sách nến theo thứ tự hiện tại lùi về trước
        for (let index = lastIndex; index > toIndex; index--) {
            let jTo = index - amountCandle + 1

            let count_changed = 1,
                count_volume = 1,
                count_trade = 1;

            let mark = Number(Klines[jTo][CandlestickMap.Close]),
                current = Number(Klines[index][CandlestickMap.Close]);

            // nếu hiện tại chênh bao nhiêu giá so với nến đầu tiên
            let changed = Math.abs(current - mark)
            // tăng hay giảm
            let isDown = current - mark < 0 ? 1 : (-1)

            let current_volume = Number(Klines[index][CandlestickMap.QuoteAssetVolume]);

            let current_trade = Number(Klines[index][CandlestickMap.NumberOfTrades]);
            // đếm nến
            for (let j = index - 1; j > jTo; j--) {
                // nếu giá của nến được xét chênh ít hơn so với nến hiện tại thì tăng 1 đếm
                if (Math.abs(Number(Klines[j][CandlestickMap.Close]) - mark) < changed)
                    count_changed++

                // nếu khối lượng USDT của nến được xét ít hơn nến hiện tại, tăng 1 đếm
                if (Number(Klines[j][CandlestickMap.QuoteAssetVolume]) < current_volume)
                    count_volume++

                // nếu số lượng giao dịch của nến được xét ít hơn nến hiện tại, tăng 1 đếm
                if (Number(Klines[j][CandlestickMap.NumberOfTrades]) < current_trade)
                    count_trade++
            }
            if (Number(Klines[jTo][CandlestickMap.QuoteAssetVolume]) < current_volume)
                count_volume++
            if (Number(Klines[jTo][CandlestickMap.NumberOfTrades]) < current_trade)
                count_trade++

            // sắp xếp theo thứ tự giảm dần
            let point = { Time: Klines[index][CandlestickMap.OpenTime] }

            // hiệu năng tăng giảm
            point.changed = count_changed / (amountCandle - 1) * 100 * isDown

            // hiệu năng khối lượng
            point.volume = count_volume / amountCandle * 100 * isDown

            // số lượng giao dịch
            point.trade = count_trade / amountCandle * 100 * isDown

            points.push(point)
        }
        return points.sort((a, b) => a.Time - b.Time)
    }
    calPair1(Klines = [], amountCandle = 96) {
        let lastIndex = Klines.length - 1
        let toIndex = amountCandle - 2
        let points = []
        // lập danh sách nến theo thứ tự hiện tại lùi về trước
        for (let index = lastIndex; index > toIndex; index--) {
            let jTo = index - amountCandle
            let arr = []
            for (let j = index; j > jTo; j--) {
                let open = Number(Klines[j][CandlestickMap.Open]),
                    close = Number(Klines[j][CandlestickMap.Close]),
                    high = Number(Klines[j][CandlestickMap.High]),
                    low = Number(Klines[j][CandlestickMap.Low]),
                    volume = Number(Klines[j][CandlestickMap.QuoteAssetVolume]),
                    trade = Number(Klines[j][CandlestickMap.NumberOfTrades]);

                let changed = (open - close) / open * 100
                // let changed = open > close ? (((low - high) / high) * 100) : (((high - low) / low) * 100)

                arr.push([j, changed, volume, trade]);
            }
            // sắp xếp theo thứ tự giảm dần
            let point = { Time: Klines[index][CandlestickMap.OpenTime] }
            // nếu tìm thấy vị trí nến hiện tại đang xét trong mảng thì lấy vị trí / tổng *100
            // hiệu năng tăng giảm
            arr.sort((a, b) => b[1] - a[1]).findIndex((v, i) => {
                if (v[0] === index) {
                    point.changed = (i + 1) / arr.length * 100
                    return true
                }
                return false;
            })
            // hiệu năng khối lượng
            arr.sort((a, b) => b[2] - a[2]).findIndex((v, i) => {
                if (v[0] === index) {
                    point.volume = (i + 1) / arr.length * 100
                    return true
                }
                return false;
            })
            // số lượng giao dịch
            arr.sort((a, b) => b[3] - a[3]).findIndex((v, i) => {
                if (v[0] === index) {
                    point.trade = (i + 1) / arr.length * 100
                    return true
                }
                return false;
            })
            points.push(point)
        }
        return points.sort((a, b) => a.Time - b.Time)
    }

    /**
     * Làm mượt dữ liệu đã tính từ hàm calPair
     * @param {object{Time, changed, volume}[]} points 
     * @param {number} period số lượng nến để làm mượt
     * @param {string} smoothType kiểu làm mượt
     * @returns {object{Time, changed, volume}[]}
     */
    smoothPair(points, period, smoothType = SmoothType.EMA) {
        let volumes, changeds, trades;
        switch (smoothType) {
            case SmoothType.EMA:
                volumes = EMA.calculate({ period: period, values: points.map(v => v.volume) });
                changeds = EMA.calculate({ period: period, values: points.map(v => v.changed) });
                trades = EMA.calculate({ period: period, values: points.map(v => v.trade) });

                break;

            default:
                return points;
        }

        let deviation_volumes = points.length - volumes.length;
        let new_points = points.slice(deviation_volumes).map(v => ({ Time: v.Time }))
        volumes.forEach((v, i) => {
            new_points[i].volume = v
        })

        changeds.forEach((v, i) => {
            new_points[i].changed = v
        })

        trades.forEach((v, i) => {
            new_points[i].trade = v
        })
        return new_points
    }

    /**
     * tính altcoin season của 1 cặp tiền khi đóng nến, tự động tính luôn những timeframe cùng đơn vị nhỏ nhất
     * @param {string} pair 
     * @param {string} timeframe 
     * @param {number} durring default 6 tháng, độ dài để tính toán
     */
    calPairWhenCloseKlineContinuous(pair = "ETHUSDT", minTimeframe = "1d", durring = 15552000000) {
        return this.timer(minTimeframe, async () => {
            let Klines = this.pairs[pair]?.[minTimeframe]
            let now = Date.now()
            if (!Klines) {
                await this.calPairAltcoin(pair, minTimeframe, now - durring, now)
                Klines = this.pairs[pair][minTimeframe]
            }
            now = Date.now()
            let last = Klines[Klines.length - 1][CandlestickMap.OpenTime];

            // nếu thời gian ở đoạn cuối mà chưa gần với hiện tại thì lấy dữ liệu mới bổ sung vào
            if (last + TIMEFRAMES.toMiliSecond(minTimeframe) < now) {
                // lấy dữ liệu mới
                let klines = await this.exchange.getKlines(pair, last, now, minTimeframe)
                // ghép dữ liệu cũ
                if (Klines[Klines.length - 1][CandlestickMap.OpenTime] === klines[0][CandlestickMap.OpenTime])
                    Klines[Klines.length - 1] = klines[0]
                if (klines.length > 1)
                    for (let i = 1; i < klines.length; i++) {
                        Klines.push(klines[i]);
                    }

                // lấy các timeframe lớn hơn, tính hết
                return TIMEFRAMES.upUnits(minTimeframe).map(tf => {
                    // tại mỗi thời điểm, tính xem biên thay đổi giá của nến hiện tại xếp hạng bao nhiêu so với cây nến trước đó
                    let amountCandle = parseInt(TIMEFRAMES.toMiliSecond(tf) / TIMEFRAMES.toMiliSecond(minTimeframe));
                    if (amountCandle > 2) {
                        let data = {
                            name: tf,
                            points: this.calPair(Klines, amountCandle),
                            Klines: Klines,
                        }
                        this.event.emit("calPairWhenCloseKlineContinuous", data)
                        return data;
                    }
                }).filter(v => v)
            }
        })
    }

    /**
     * tính altcoin season mỗi khi đóng nến
     * @param {*} Symbols 
     * @param {*} TimeFrames 
     * @param {int} during là độ dài thời gian muốn lấy đơn vị mili giây
     * @returns {setInterval[]} danh sách các vòng lặp tự động
     */
    async calAltcoinSeasonsWhenCloseKline(Symbols = [], TimeFrames = [{ Name: "5m", during: 86400000 }]) {
        return await Promise.all(TimeFrames.map(async tf => ({
            name: tf.Name,
            loop: this.timer(tf.Name, async () => {
                let EndTime = moment().valueOf()
                let StartTime = moment().subtract(tf.during, 'milliseconds').valueOf()

                let SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, tf.Name);
                let AltcoinSeasons = await this.calAltcoinSeason(SymbolsKlines, tf.Name)
                this.saveData(tf.Name, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonsWhenCloseKline", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf })

            })
        })))
    }

    /**
     * khi đóng nến, tính  altcoin season nhưng tính từ chỗ cuối cùng cho đến hiện tại, rồi gộp lại, 
     * nếu chưa có dữ liệu đã tính thì tính theo during
     * @param {string[]} Symbols 
     * @param {object[]} TimeFrames 
     */
    async calAltcoinSeasonsWhenCloseKlineContinuous(Symbols = [], TimeFrames = [{ Name: "5m", during: 86400000 }]) {
        return await Promise.all(TimeFrames.map(async tf => ({
            name: tf.Name,
            loop: this.timer(tf.Name, async () => {
                let StartTime = moment().subtract(tf.during, 'milliseconds').valueOf()
                let data
                try {
                    data = JSON.parse(localStorage.getItem(tf.Name))
                    data.sort((a, b) => a.Time - b.Time)
                    if ((data[data.length - 1].Time - data[data.length - 2].Time) !== TIMEFRAMES.toMiliSecond(tf.Name))
                        data.splice(data.length - 1, 1);
                    StartTime = data[data.length - 2].Time;
                } catch (err) { console.error(err); }

                let EndTime = moment().valueOf()

                let SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, tf.Name);

                let AltcoinSeasons = await this.calAltcoinSeason(SymbolsKlines, tf.Name)

                if (data && typeof data == typeof []) {

                    AltcoinSeasons.forEach(newItem => {
                        const index = data.findIndex(old => old.Time === newItem.Time)
                        if (index > 0)
                            data[index] = newItem
                        else data.push(newItem)
                    })
                    AltcoinSeasons = data
                }

                this.saveData(tf.Name, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonsWhenCloseKline", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf })

            })
        })))
    }

    /**
     * tính altcoin season mỗi khi đóng nến
     * @param {*} Symbols 
     * @param {*} TimeFrames 
     * @param {int} during là độ dài thời gian muốn lấy đơn vị mili giây
     * @param {string} indicator loại chỉ báo
     * @param {object} options thông số tùy chọn khi tính toán
     * @returns {setInterval[]} danh sách các vòng lặp tự động
     */
    async calAltcoinSeasonsWhenCloseKlineByIndicator(Symbols = [], TimeFrames = [{ Name: "5m", during: 86400000 }], indicator, options = { amountOfTops: 50 }) {
        if (indicator == "HOLGER")
            return this.calAltcoinSeasonsWhenCloseKlineByHolger(Symbols, TimeFrames, options);

        else return await Promise.all(TimeFrames.map(async tf => ({
            name: tf.Name,
            loop: this.timer(tf.Name, async () => {
                let StartTime = moment().subtract(tf.during, 'milliseconds').valueOf()
                let data
                try {
                    data = JSON.parse(localStorage.getItem(tf.Name))
                    data.sort((a, b) => a.Time - b.Time)
                    if ((data[data.length - 1].Time - data[data.length - 2].Time) !== TIMEFRAMES.toMiliSecond(tf.Name))
                        data.splice(data.length - 1, 1);
                    StartTime = data[data.length - 2].Time;
                } catch (err) { console.error(err); }


                let EndTime = moment().valueOf()
                let SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, TIMEFRAMES.downUnit(tf.Name));

                let AltcoinSeasons = await this.calAltcoinSeasonByIndicator(SymbolsKlines, tf.Name, options)

                // nối dữ liệu đã tính từ trước
                if (data && typeof data == typeof []) {
                    AltcoinSeasons.forEach(newItem => {
                        const index = data.findIndex(old => old.Time === newItem.Time)
                        if (index > 0)
                            data[index] = newItem
                        else data.push(newItem)
                    })
                    AltcoinSeasons = data
                }

                this.saveData(tf.Name, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonsWhenCloseKline", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf })
            })
        })))
    }

    HolgerTimeFramesListen = {}
    async calAltcoinSeasonsWhenCloseKlineByHolger(Symbols = [], TimeFrames = [{ Name: "5m", during: 86400000 }], options = { amountOfTops: 50 }) {
        // khi đóng nến, lọc các timeframe có cùng downUnit, tính luôn các timeframe còn lại lớn hơn cùng cấp
        let _timeframes = TimeFrames.reduce((pre, v) => {
            pre[TIMEFRAMES.downUnit(v.Name)] = v;
            return pre;
        }, {})
        return await Promise.all(Object.entries(_timeframes).map(async ([key, tf]) => ({
            name: tf.Name,
            loop: this.timer(key, async () => {
                let downUnit = TIMEFRAMES.downUnit(tf.Name)
                let StartTime = moment().subtract(tf.during, 'milliseconds').valueOf()

                let EndTime = moment().valueOf()
                if (Symbols.length === 0)
                    Symbols = this.Symbols

                if (!Symbols.find(s => s == "BTCUSDT"))
                    Symbols.push("BTCUSDT")

                let SymbolsKlines = this.Data[downUnit]

                // nếu timeframe này chưa có Data thì lấy mới dữ liệu từ server
                if (!SymbolsKlines) {
                    SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, downUnit);
                    this.Data[downUnit] = SymbolsKlines
                } else
                    // nếu đã có dữ liệu thì bắt đầu lấy dữ liệu từ điểm thời gian cuối cùng , sau đó ghép vào 
                    try {
                        let Klines = Object.values(SymbolsKlines)[0]
                        StartTime = Number(Klines[Klines.length - 2][CandlestickMap.OpenTime]);
                        // nếu timeframe downUnit trước đó đã lấy dữ liệu rồi thì ko cần lấy dữ liệu mới nữa
                        if (EndTime - StartTime >= TIMEFRAMES.toMiliSecond(downUnit)) {
                            let _SymbolsKlines = await this.exchange.getSymbolsKlines(Symbols, StartTime, EndTime, downUnit);
                            for (let [Symbol, Klines] of Object.entries(_SymbolsKlines)) {
                                Klines.forEach(newItem => {
                                    const index = SymbolsKlines[Symbol].findIndex(old => { return old[CandlestickMap.OpenTime] === newItem[CandlestickMap.OpenTime] })
                                    if (index > 0)
                                        SymbolsKlines[Symbol][index] = newItem
                                    else SymbolsKlines[Symbol].push(newItem)
                                })
                            }
                        }
                    } catch (err) { }

                let AltcoinSeasons = await this.calAltcoinSeasonByHolger(SymbolsKlines, tf.Name, options)

                this.saveData(tf.Name, AltcoinSeasons)
                this.event.emit("calAltcoinSeasonsWhenCloseKline", { AltcoinSeasons, SymbolsKlines, TimeFrame: tf })

                // tính altcoin season của những timeframe lớn hơn
                TIMEFRAMES.upUnits(key).forEach(async v => {
                    let _AltcoinSeasons = await this.calAltcoinSeasonByHolger(SymbolsKlines, v, options)
                    this.saveData(v, _AltcoinSeasons)
                    this.event.emit("calAltcoinSeasonsWhenCloseKline", { AltcoinSeasons: _AltcoinSeasons, SymbolsKlines, TimeFrame: { Name: v, during: tf.during } })
                })
            })
        })))
    }

    /**
     * hẹn giờ khi đóng nến thì làm gì đó
     * @param {string} Timeframe khung thời gian
     * @param {function} callback hàm thực thi
     * @returns {setInterval[]} vòng lặp
     */
    timer(Timeframe = "4h", callback) {
        return new Promise(async (rs, rj) => {
            let now = Date.now()
            let TimeframeMS = TIMEFRAMES.toMiliSecond(Timeframe)

            if (TIMEFRAMES.toMiliSecond(Timeframe) > TIMEFRAMES.toMiliSecond("1w"))
                TimeframeMS = TIMEFRAMES.toMiliSecond("1w")

            // lấy cây nến cuối
            let Klines = await this.exchange.getKlines('BTCUSDT', now - TimeframeMS, now, Timeframe, 1);
            if (Klines.length > 0) {
                // cộng thêm 1 khoản thời để gọi hàm tránh bị chồng truy vấn , binance sẽ chặn
                let TimeLeft = Klines[Klines.length - 1][CandlestickMap.OpenTime] + TimeframeMS - Date.now()

                setTimeout(() => {
                    callback()

                    // vòng lặp theo chu kì nến, thực thi callback
                    let loop = setInterval(callback, TimeframeMS + TIMEFRAMES.plusTimeWait(Timeframe) - 4)
                    rs(loop);

                }, TimeLeft + TIMEFRAMES.plusTimeWait(Timeframe));

            } else rj("Error get " + Timeframe)
        })
    }

    /**
     * tính altcoin season cứ sau mỗi x phút
     * @param {int} TimeWait thời gian chờ 
     * @param {string[]} Symbols danh sách cặp tiền
     * @param {string[]} TimeFrames danh sách khung thời gian
     * @returns {setInterval} vòng lặp
     */
    calAltcoinSeasonEvery(TimeWait = 5, Symbols = [], TimeFrames = this.Settings.TimeFramesListen) {
        async function cal() {
            let _TimeFrames = TimeFrames.map(tf => {
                let StartTime = moment().subtract(24, "hours").valueOf(), EndTime = moment().valueOf()
                if (["4h", "6h", "8h"].includes(tf))
                    StartTime = moment().subtract(777, "days").valueOf();

                return {
                    Name: tf,
                    StartTime: StartTime,
                    EndTime: EndTime,
                }
            })
            await this.calAltcoinSeasonMultiTimeFramesTimeRanges(Symbols, _TimeFrames)
        }
        cal()

        let loop = setInterval(cal, TimeWait * 60 * 1000);
        return loop;
    }

    /**
     * tính altcoin season liên tục
     * @param {Array} Symbols danh sách các cặp tiền
     * @param {Array} TimeFrames mảng các timeframes
     */
    async calAltcoinSeasonsContinuous(Symbols = [], TimeFrames = this.Settings.TimeFramesListen) {
        let _TimeFrames = TimeFrames.map(tf => {
            let StartTime = moment().subtract(24, "hours").valueOf(), EndTime = moment().valueOf()
            if (["4h", "6h", "8h"].includes(tf))
                StartTime = moment().subtract(7, "days").valueOf();

            return {
                Name: tf,
                StartTime: StartTime,
                EndTime: EndTime,
            }
        })
        await this.calAltcoinSeasonMultiTimeFramesTimeRanges(Symbols, _TimeFrames);
        await this.calAltcoinSeasonsContinuous(Symbols, TimeFrames);
    }

    // nếu 30m & 6h hoặc 5m & 30m cùng mà cùng dưới 15 hoặc trên 85 thì báo động
    alertAltcoinSeasonsBuySell(timeframes = ["5m", "30m", "6h"]) {
        this.event.on("calAltcoinSeasonFinished", (r) => {
            if (timeframes.includes(r.timeframe)) {
                try {
                    let alt = r.AltcoinSeasons.slice(-1)[0]
                    let NowTz7 = moment.tz(Date.now(), "Asia/Ho_Chi_Minh").format("DD/MM hh:mm +7")

                    let maxConsensus = 2, countConsensusBuy = 0, countConsensusSell = 0;
                    timeframes.forEach(tf => {
                        if (this.AltcoinSeasonsNow[tf].AltcoinSeason < 15)
                            countConsensusBuy++;

                        if (this.AltcoinSeasonsNow[tf].AltcoinSeason > 85)
                            countConsensusSell++;
                    });

                    if (countConsensusBuy >= maxConsensus)
                        this.sentAlertTelegram("Tesla Xu hướng 🟢LONG ↗️ " + NowTz7, this.Dev ? "altcointest" : "altcoinseason", this.Settings);

                    if (countConsensusSell >= maxConsensus)
                        this.sentAlertTelegram("Tesla Xu hướng 🔴SHORT ↘️ " + NowTz7, this.Dev ? "altcointest" : "altcoinseason", this.Settings);

                    // if (alt.AltcoinSeason < 15) {
                    //     sentAlertTelegram("Xu hướng 🟢LONG ↗️ " + r.timeframe + " = " + Math.round(alt.AltcoinSeason) + " | " + NowTz7, Dev ? "altcointest" : "altcoinseason", Settings);
                    // }

                    // if (alt.AltcoinSeason > 85) {
                    //     sentAlertTelegram("Xu hướng 🔴SHORT ↘️ " + r.timeframe + " = " + Math.round(alt.AltcoinSeason) + " | " + NowTz7, Dev ? "altcointest" : "altcoinseason", Settings);
                    // }
                } catch (error) { }
            }
        })
    }

    /**
     * gửi thông báo altcoin season now
     * @param {object} alt 
     */
    alertAltcoinSeasonsNow(alt) {

        this.event.emit("AltcoinSeasonsNow", this.AltcoinSeasonsNow)
    }

    /**
     * tính altcoin season dựa trên cặp với /BTC
     * @param {string[]} TimeFramesListen 
     * @param {moment} StartTime 
     * @param {moment} EndTime 
     */
    async calAltcoinSeasonBTC(TimeFramesListen = this.Settings.TimeFramesListen, StartTime, EndTime) {
        let Symbols = await this.exchange.getAllSymbolsWiths(["BTC"])
        await this.calAltcoinSeasonMultiTimeFrames(Symbols, TimeFramesListen, StartTime, EndTime);
    }

}

export default AltcoinSeason;