import moment from 'moment';
import EventEmitter from 'events';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Col, Form, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { PancakeV2Factory, WBNB, PancakeV2Router } from "../contracts";

import { hashBrowser, TIMEFRAMES } from '../std';
import axios from 'axios';
import Web3 from 'web3';



class NewTokenAddLiquit extends Component {
    state = {
        web3: new Web3("https://koge-rpc-bsc.48.club/"),
        tokens: [],
        pancakeV2Factory: false,
        wbnb: false,
        pancakeV2Router: false,
    }

    constructor(props) {
        super(props)
        this.listenEvent.bind(this)
    }

    componentDidMount() {
        let { web3 } = this.state;
        let pancakeV2Factory = new web3.eth.Contract(PancakeV2Factory.Abi, PancakeV2Factory.Address)
        let wbnb = new web3.eth.Contract(WBNB.Abi, WBNB.Address)
        let pancakeV2Router = new web3.eth.Contract(PancakeV2Router.Abi, PancakeV2Factory.Address)

        this.setState({ pancakeV2Factory, wbnb, pancakeV2Factory }, this.listenEvent)
    }

    listenEvent() {
        let { pancakeV2Factory, wbnb, pancakeV2Router } = this.state;

        pancakeV2Factory.getPastEvents("PairCreated", {}, function (error, event) { console.log(event); })
            .then(function (events) {
                console.log(events) // same results as the optional callback above
            });

    }

    render() {
        let { web3, tokens } = this.state;
        return (
            <>
                <Button onClick={this.listenEvent.bind(this)}>listen events</Button>
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tokens.map(t => (
                            <tr>
                                <td>{t.id}</td>
                            </tr>
                        ))}

                    </tbody>

                </Table>
            </>
        )
    }
}



const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps, {
})(NewTokenAddLiquit);

