import React from "react";
import { connect } from 'react-redux';
import { log, logerror } from "../std"
import millify from "millify";
import axios from 'axios';

import "./AltcoinSeasion.scss"
import { Button } from "react-bootstrap";


class AltcoinSeasion extends React.Component {
    state = {
        status: {},
        errors: "not thing",
        percentageOfVolume: 0, positiveChanges: 0,
        percentageOfVolumeHour: 0, positiveChangesHour: 0,
    }

    componentDidMount() {
        // this.getAltcoin.bind(this)()
    }

    async getAltcoin() {
        document.cookie = "";
        let date = Date.now();
        let url = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&date=${date}`;
        // console.log(url);
        await axios.get(url)
            .then(response => {
                const altcoins = response.data;

                // Filter the altcoins to only include those with positive price changes on the specified date
                const positiveChanges = altcoins.filter(coin => coin.price_change_percentage_24h > 0);

                // Calculate the total trading volume for the altcoins with positive price changes
                const totalVolume = positiveChanges.reduce((acc, coin) => acc + coin.total_volume, 0);

                // Calculate the percentage of total altcoin trading volume represented by the positive-change coins
                const percentageOfVolume = (totalVolume / altcoins.reduce((acc, coin) => acc + coin.total_volume, 0)) * 100;

                // Output the results
                this.setState({ date: date, positiveChanges: positiveChanges, percentageOfVolume: percentageOfVolume });
            })
            .catch(error => {
                console.log(error);
            });

        const hours = 24;
        let urlHour = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=250&page=1&sparkline=false&price_change_percentage=${hours}`
        console.log(urlHour);
        // Fetch historical altcoin data for the specified number of hours
        await axios.get(urlHour)
            .then(response => {
                const altcoins = response.data;

                // Filter the altcoins to only include those with positive price changes in the last hour
                const positiveChanges = altcoins.filter(coin => coin.price_change_percentage_1h_in_currency > 0);

                // Calculate the total trading volume for the altcoins with positive price changes
                const totalVolume = positiveChanges.reduce((acc, coin) => acc + coin.total_volume, 0);

                // Calculate the percentage of total altcoin trading volume represented by the positive-change coins
                const percentageOfVolume = (totalVolume / altcoins.reduce((acc, coin) => acc + coin.total_volume, 0)) * 100;

                // Output the results
                console.log(`In the last ${hours} hours, ${positiveChanges.length} altcoins had positive price changes, representing ${percentageOfVolume.toFixed(2)}% of total altcoin trading volume.`);
                this.setState({ positiveChangesHour: positiveChanges, percentageOfVolumeHour: percentageOfVolume });
            })
            .catch(error => {
                console.log(error);
            });

    }

    render() {
        const { } = this.props;
        const { date, positiveChanges, percentageOfVolume, positiveChangesHour, percentageOfVolumeHour } = this.state;
        // console.log(positiveChanges[0]);
        return (
            <div className="AltcoinSeasion">
                <Button onClick={this.getAltcoin.bind(this)}>get Altcoin</Button>
                <div className="AltCoinSeasionDay">AltCoin seasion day: <b>{percentageOfVolume.toFixed(2)}% </b></div>-
                <div className="AltCoinSeasionHour">AltCoin seasion hour: <b> {percentageOfVolumeHour.toFixed(2)}%</b></div>


                {/* {positiveChanges ?
                    `On ${date}, ${positiveChanges.length} altcoins had positive price changes, representing ${percentageOfVolume.toFixed(2)}% of total altcoin trading volume.` : ""} */}
            </div>
        )
    }
}

const styles = {
    title: {
        textDecoration: "none"
    },
}

const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps, {
})(AltcoinSeasion);
