import React from "react";
import { connect } from 'react-redux';
import { Col, Form, ListGroup, Tab, Tabs } from "react-bootstrap";
import { connectWsServer, wsClient, wsHost } from "./store/wsClient";
import { toast } from "react-toastify";

// import "./Trade.scss"

class Trade extends React.Component {
    state = {
        PercentBalance: 10,
        LimitPrice: "",
        AmountByCoin: "",
        AmountByQuote: "",
        Buy: true,
    }

    onChangeBuySell(e) {
        // console.log(e.target.value);
        this.setState({ Buy: e.target.value })
    }

    onChangeLimitPrice(e) {
        let value = Number(e.target.value)

        if (value >= 0) {
            this.setState({ LimitPrice: value })
        }
    }

    onChangeAmountByCoin(e) {
        let value = Number(e.target.value)

        if (value >= 0) {
            this.setState({ AmountByCoin: value })
        }
    }

    onChangeAmountByQuote(e) {
        let value = Number(e.target.value)

        if (value >= 0) {
            this.setState({ AmountByQuote: value })
        }
    }

    onChangePercentBalance(e) {
        let value = Number(e.target.value)

        if (e.deltaY) {
            if (e.deltaY > 0) {
                value++
            } else {
                value--
            }
        }

        if (value >= 0 && value <= 100) {
            this.setState({ PercentBalance: value })
        }
    }


    render() {
        let { PercentBalance, LimitPrice, AmountByCoin, AmountByQuote, Buy } = this.state
        return (
            <>
                <Col>
                    <ListGroup className="accounts">
                        <Form> {
                            [1, 2, 3].map((v, i) => (<ListGroup.Item key={i}><Form.Check
                                label={`accounts-${v}`}
                                name="group1"
                                type={'checkbox'}
                                value={v}
                            // id={`accounts-${v}`}
                            /></ListGroup.Item>))}
                        </Form>
                    </ListGroup>
                </Col>
                <Col>
                    <Form.Check
                        variant="success"
                        inline
                        label="Mua"
                        name="groupBuySell"
                        type={'radio'}
                        value={true}
                        onChange={this.onChangeBuySell.bind(this)}
                    />
                    
                    <Form.Check
                        bg="danger"
                        inline
                        label="Bán"
                        name="groupBuySell"
                        type={'radio'}
                        value={false}
                        onChange={this.onChangeBuySell.bind(this)}
                    />
                    <Tabs>
                        <Tab eventKey="limit" title="limit">
                            <Form className="tradeForm">
                                <Form.Group>
                                    <Form.Label>
                                        Giá mua cố định
                                    </Form.Label>
                                    <Form.Control type="number" placeholder="Price" onChange={this.onChangeLimitPrice.bind(this)} value={LimitPrice} />
                                    <Form.Text>&nbsp;</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Số lượng Coin mua
                                    </Form.Label>
                                    <Form.Control type="number" placeholder="Amount by coin" onChange={this.onChangeAmountByCoin.bind(this)} value={AmountByCoin} />
                                    <Form.Text>&nbsp;</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        Số lượng USD mua
                                    </Form.Label>
                                    <Form.Control type="number" placeholder="Amount by Quote" onChange={this.onChangeAmountByQuote.bind(this)} value={AmountByQuote} />
                                    <Form.Text>&nbsp;</Form.Text>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>
                                        % mua
                                    </Form.Label>
                                    <Form.Control type="number" placeholder="percent balance" value={PercentBalance} onChange={this.onChangePercentBalance.bind(this)} />
                                    <Form.Range onChange={this.onChangePercentBalance.bind(this)} value={PercentBalance}
                                        onWheel={this.onChangePercentBalance.bind(this)}
                                    />
                                </Form.Group>
                            </Form>

                        </Tab>
                    </Tabs>
                </Col>
            </>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    wsClient: state.wsClientStore.client,
    wsHost: state.wsClientStore.wsHost,
});

export default connect(mapStateToProps, {
    connectWsServer: connectWsServer,
})(Trade);
