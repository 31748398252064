import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { withTranslation } from 'react-i18next';

import store from './store/store';

import App from './App';
import reportWebVitals from './reportWebVitals';

import './index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faCopy,
  faPaste,
  faClipboard,
  faFloppyDisk,
  faPlay,
  faPause,
  faStop,
  faPersonCircleCheck,
  faShareFromSquare,
  faMoneyBillTransfer,
  faShuffle,
  faBackwardFast,
  faGear,
  faCircleInfo,
  faTrash,
  faCoins,
  faCircleDollarToSlot,
  faPlus,
  faArrowRotateRight,
  faArrowsRotate,
  faDownload,
  faCloudArrowDown,
  faFileImport,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faCopy,
  faPaste,
  faClipboard,
  faFloppyDisk,
  faPlay,
  faPause,
  faStop,
  faPersonCircleCheck,
  faShareFromSquare,
  faMoneyBillTransfer,
  faShuffle,
  faBackwardFast,
  faGear,
  faCircleInfo,
  faTrash,
  faCoins,
  faCircleDollarToSlot,
  faPlus,
  faArrowRotateRight,
  faArrowsRotate,
  faDownload,
  faCloudArrowDown,
  faFileImport,
  faEye,
  faEyeSlash,
);

const I81NApp = withTranslation()(App);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Suspense fallback={<div className="suspense">loading...</div>} >
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  </Suspense>
);
document.title = "Coin X Signals crypto";
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
