import React from "react";
import { connect } from 'react-redux';
import { log, logerror } from "../std"
import millify from "millify";
import { connectWsServer } from "../store/wsClient";

import "./Symbol.scss"
import BtnCopy from "./BtnCopy";



class Symbol extends React.Component {
    state = {
        status: {},
        errors: "not thing",
        EMA: 0
    }

    getEMA() {
        const { wsClient, wsHost, connectWsServer } = this.props;
        wsClient.onopen = () => {
            this.setState({ btnConnectText: "connected" })
            log("connected " + wsHost)
            this.setState({ isConnected: true })
        }

        wsClient.onerror = err => {
            logerror(err)
            this.setState({ isConnected: false })
            setTimeout(this.connect().catch(e => "error connect"), 3000)
        }

    }

    onClickTag(e) {
        let tag = e.target.innerHTML;
        this.props.handleAddition({ id: tag, text: tag })
    }

    render() {
        const { values, key, future } = this.props;
        return (
            <tr id={key} key={key}>
                <td>{/* symbol */}
                    <a href={"https://www.binance.com/" + (future ? "futures" : "trade") + "/"
                        + values.symbol} target="_blank" style={styles.title}>{values.symbol + (future ? ".P" : "")}</a>
                    &nbsp;<BtnCopy value={values.symbol + (future ? ".P" : "")} />
                </td>
                <td>{/* name */}
                    {values.name}
                    <button value={values.name} onClick={() => this.props.showChartAltcoinPair(values.symbol + (future ? ".P" : ""))}>💹</button> &nbsp;
                    <a href={"/altcoinPair/" + values.symbol} target='_blank'>↗️</a>
                </td>
                <td> {/* price */}
                    {millify(values.lastPrice)}
                </td>
                <td>{/* % distance EMA 200 */}
                    {(values.distanceEMA).toFixed(1)} %
                </td>
                <td>
                    {/* volume */}
                    {millify(Number(values.Klines[values.Klines.length - 2][7]).toFixed(1))}
                </td>
                <td>
                    {millify(values.market_cap)} $
                </td>
                <td>
                    {values.tags?.map(v => <a className="tag" href={"#" + v.id} onClick={this.onClickTag.bind(this)}>{v.id}</a>)}
                </td>
            </tr >
        )
    }
}

const styles = {
    title: {
        textDecoration: "none"
    },
}

const mapStateToProps = (state, ownProps) => ({
    wsClient: state.wsClientStore.client,
    wsHost: state.wsClientStore.wsHost,
});

export default connect(mapStateToProps, {
    connectWsServer: connectWsServer,
})(Symbol);
