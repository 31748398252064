import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { w3cwebsocket } from 'websocket';
import { hashBrowser, log } from "../std";


export const connectWsServer = createAsyncThunk(
    'connectWsServer',
    async (args, thunkAPI) => {
        // console.log(args, thunkAPI.getState().wsClientStore.wsHost)
        let wsHost = args;
        if (!wsHost) wsHost = thunkAPI.getState().wsClientStore.wsHost;
        let client = new w3cwebsocket(wsHost)

        return { client, host: wsHost }
    }
)

export const sendData = createAsyncThunk(
    'sendData',
    async (args, thunkAPI) => {
        let client = thunkAPI.getState().wsClientStore.client;

        let Req = args
        Req.id = hashBrowser(JSON.stringify(args))

        client.send(JSON.stringify(Req))
        return Req.id
    }
)



export const wsClientSlice = createSlice({
    name: "wsClient",
    initialState: {
        client: null,
        wsHost: "ws://localhost:1000"
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(connectWsServer.fulfilled, (state, action) => {
            state.client = action.payload.client;
            state.wsHost = action.payload.host;
        });

        builder.addCase(sendData.fulfilled, (state, action) => { });
    }
})

export default wsClientSlice.reducer;

