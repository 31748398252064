import axios from "axios";
import { log, logsuccess, logwarn } from "../std";
import { EventEmitter } from "events";
import { EMA } from 'technicalindicators'
import Exchanges from "./Exchanges";
import moment from "moment";
import { StableCoins, TIMEFRAMES } from "./AltcoinSeason";
import Coinmarketcap from "./Coinmarketcap";

const { error } = console;

export const Compair = {
    greater: ">",
    greaterEqual: ">=",
    smaller: "<",
    smallerEqual: "<=",
    equal: "==",
}

export const EMACompair = {
    ...Compair,
    cut: "#",
}

Object.freeze(Compair)
Object.freeze(EMACompair)

class ScanEma {
    event;
    exchange
    coinmarketcap
    constructor(exchange = new Exchanges(Exchanges.Binance), event = new EventEmitter()) {
        this.event = event
        this.exchange = exchange
        this.coinmarketcap = new Coinmarketcap()
    }

    saveData(name, data) {
        let content = JSON.stringify(data)

        localStorage.setItem(name, content)
    }

    // giá cắt qua điểm ?
    isPriceCut(high, low, point) {
        return Math.abs(high - low) >= (Math.abs(point - high) + Math.abs(point - low));
    }

    loadData(name = "symbols") {
        let content = localStorage.getItem(name)
        return JSON.parse(content)
    }

    async getData() {
        return axios.get(`https://api.binance.com/api/v3/ticker/24hr`, { responseType: 'json', })
            .then(response => response.data)
    }

    async getDatax() {
        return axios.get(`https://www.binance.com/exchange-api/v2/public/asset-service/product/get-products`, { responseType: 'json', })
            .then(response => response.data)
            .then(data => data.data)
            .catch(console.error)
    }

    /**
     * lấy biểu đồ nến của cặp tiền
     * @param {string} pair cặp tiền
     * @param {string} timeFrame khung thời gian
     * @returns {object[]}
     */
    async getCandles(pair, timeFrame = "1d") {
        let url = `https://api.binance.com/api/v3/klines?interval=${timeFrame}&symbol=${pair}&limit=1000`
        logwarn(url)
        return axios.get(url)
            .then(r => r.data)
            .then(data => {
                // this.saveData(pair, data);
                return data;
            })
            .catch(console.error)
    }

    /**
     * lấy tất cả nến của các cặp tiền
     * @param {string} pairs cặp tiền
     * @param {string} timeFrame khung thời gian
     * @param {int} index số thứ tự
     * @returns {object}
     */
    async getAllCandles(pairs, timeFrame = "1d", index = 0) {
        if (index < pairs.length) {
            let candles = await this.getCandles(pairs[index].s, timeFrame)
            pairs[index].candles = candles;
            return this.getAllCandles(pairs, timeFrame, index + 1);
        }
    }

    /**
     * chỉ lấy cặp USDT hoặc BUSD và market cap < 60tr$
     * @param {int} maxCap 
     * @returns {object[]} pairs
     */
    async getLowCapUnderUSD(maxCap = 100_000_000) {
        return this.getData().then(async data => {
            let d = data.filter(pair => pair.s.includes("USDT") || pair.s.includes("BUSD"))
                .filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        // lọc trùng
                        t.b === value.b && t.b != "BUSD"
                    ))
                ).filter(pair => pair.c * pair.cs < maxCap)
                .map(async pair => {
                    return this.getCandles(pair.s).then(candles => {
                        pair.candles = candles;
                        // console.log(candles);
                        // tính EMA
                        pair.emas = EMA.calculate({ period: 200, values: candles.map(C => parseFloat(C[4])) });
                        console.log(pair.s, candles[candles.length - 1][4] < pair.emas[pair.emas.length - 1]);
                        return pair
                    })
                })
            const pairs = await Promise.all(d);
            console.log(pairs.length);
            return pairs;
        });
    }

    /**
     * chỉ lấy cặp BTC và volume giao dịch của hôm qua < 10 BTC
     * @param {int} limitVol 
     * @returns {object[]} pairs
     */
    async getLowVolUnderBTC(limitVol = 11) {
        return this.getData().then(async data => {
            let d = data.filter(pair => pair.s.includes("BTC"))
                .filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        // lọc trùng
                        t.b === value.b
                    ))
                )
                .map(async pair => {
                    return this.getCandles(pair.s).then(candles => {
                        pair.candles = candles;
                        // console.log(candles);
                        // tính EMA
                        pair.emas = EMA.calculate({ period: 200, values: candles.map(C => parseFloat(C[4])) });
                        // console.log(pair.s, parseFloat(candles[candles.length - 2][7]), limitVol, parseFloat(candles[candles.length - 2][7]) < limitVol);
                        // nếu vol hôm qua tính trên BTC < limitVol
                        if (parseFloat(candles[candles.length - 2][7]) < limitVol) return pair;
                    })
                })
            const pairs = (await Promise.all(d)).filter(pair => pair);
            // log(pairs)
            // this.saveData("getLowVolUnderBTC", pairs);
            console.log("BTCPairs", pairs.length);
            return pairs;
        });
    }

    async get4HCutEMA200(maxCap = 500_000_000) {
        let data = await this.getData()
        let pairs = data.filter(pair => pair.s.includes("USDT") || pair.s.includes("BUSD"))
            .filter((value, index, self) =>
                index === self.findIndex((t) => (
                    // lọc trùng
                    t.b === value.b && t.b != "BUSD"
                ))
            ).filter(pair => pair.c * pair.cs < maxCap)
        await this.getAllCandles(pairs, "4h");
        pairs.map(pair => {
            let candles = pair.candles;
            // tính EMA
            pair.emas = EMA.calculate({ period: 200, values: candles.map(C => parseFloat(C[4])) });
            // console.log(pair.s, parseFloat(candles[candles.length - 2][7]), limitVol, parseFloat(candles[candles.length - 2][7]) < limitVol);

            // nếu 3 cây nến gần đây cắt EMA200 hoặc giá đang dưới EMA200
            if (parseFloat(candles[candles.length - 1][4]) < pair.emas[pair.emas.length - 1]) {
                return pair;
            } else {
                for (let index = 1; index <= 3; index++) {
                    if (this.isPriceCut(parseFloat(candles[candles.length - index][2]), parseFloat(candles[candles.length - index][4]), pair.emas[pair.emas.length - index])) {
                        return pair;
                    }
                }
            }
        })

        // log(pairs)
        // this.saveData("get4HCutEMA200", pairs);
        console.log("4HCutEMA200", pairs.length);
        return pairs;
    }

    async get4HCutEMA99(maxCap = 500_000_000) {
        let data = await this.getData()
        let pairs = data.filter(pair => pair.s.includes("USDT") || pair.s.includes("BUSD"))
            .filter((value, index, self) =>
                index === self.findIndex((t) => (
                    // lọc trùng
                    t.b === value.b && t.b != "BUSD"
                ))
            ).filter(pair => pair.c * pair.cs < maxCap)
        await this.getAllCandles(pairs, "4h");
        pairs.map(pair => {
            let candles = pair.candles;
            // tính EMA
            pair.emas = EMA.calculate({ period: 99, values: candles.map(C => parseFloat(C[4])) });
            // console.log(pair.s, parseFloat(candles[candles.length - 2][7]), limitVol, parseFloat(candles[candles.length - 2][7]) < limitVol);

            // nếu 3 cây nến gần đây cắt EMA200 hoặc giá đang dưới EMA200
            if (parseFloat(candles[candles.length - 1][4]) < pair.emas[pair.emas.length - 1]) {
                return pair;
            } else {
                for (let index = 1; index <= 7; index++) {
                    if (this.isPriceCut(parseFloat(candles[candles.length - index][2]), parseFloat(candles[candles.length - index][4]), pair.emas[pair.emas.length - index])) {
                        return pair;
                    }
                }
            }
        })

        // log(pairs)
        // this.saveData("get4HCutEMA99", pairs);
        console.log("4HCutEMA200", pairs.length);
        return pairs;
    }

    /**
     * lấy khung 1D dưới EMA 200, cap dưới 50 triệu $
     * @param {int} maxCap 
     * @returns 
     */
    async get1DUnderEMA200Under50M(maxCap = 50_000_000) {
        return this.getData().then(async data => {
            let d = data.filter(pair => pair.s.includes("USDT") || pair.s.includes("BUSD"))
                .filter((value, index, self) =>
                    index === self.findIndex((t) => (
                        // lọc trùng
                        t.b === value.b && t.b != "BUSD"
                    ))
                ).filter(pair => pair.c * pair.cs < maxCap)
                .map((pair, i) => {
                    // return new Promise((rs, rj) => setTimeout(() => {
                    return this.getCandles(pair.s, "1d").then(candles => {
                        pair.candles = candles;
                        // console.log(candles);
                        // tính EMA
                        pair.emas = EMA.calculate({ period: 200, values: candles.map(C => parseFloat(C[4])) });
                        // console.log(pair.s, parseFloat(candles[candles.length - 2][7]), limitVol, parseFloat(candles[candles.length - 2][7]) < limitVol);

                        // nếu giá dưới EMA 200 và cap nhỏ hơn maxCap
                        if (parseFloat(candles[candles.length - 1][4]) < pair.emas[pair.emas.length - 1]) {
                            logsuccess(pair.s, candles[candles.length - 1][4], pair.emas[pair.emas.length - 1],
                                parseFloat(candles[candles.length - 1][4]) < pair.emas[pair.emas.length - 1]);
                            return (pair);
                        }
                    })
                    // .catch(err => rj(err))
                    // }, 100 + i * 10));
                });


            const pairs = (await Promise.all(d)).filter(pair => pair);
            // this.saveData("get1DUnderEMA200Under50M", pairs);
            console.log("get1DUnderEMA200Under50M", pairs.length);
            return pairs;
        })
    }

    /**
     * Lọc danh sách nến mà thỏa điều kiện theo EMA, gắn mảng EMA đã tính toán vào
     * @param {object[]} symbolsKlines danh sách symbol có thuộc tính Klines là mảng nến
     * @param {int} ema số EMA 
     * @param {EMACompair} emaCompair 
     * @param {int} amountKline số nến muốn so sánh
     * @returns {object[]} symbolsKlines 
     */
    filterEMA(symbolsKlines = [], ema = 200, emaCompair = EMACompair.smallerEqual, amountKline = 1) {
        return symbolsKlines.filter(({ symbol, Klines }, i) => {
            let emas = EMA.calculate({ period: ema, values: Klines.map(C => parseFloat(C[4])) });
            symbolsKlines[i].emas = emas
            // nếu ema của những cây nến gần nhất so với ema thỏa điều kiện
            if (emaCompair == EMACompair.cut) {
                let low = Klines[Klines.length - 1][3],
                    high = Klines[Klines.length - 1][2];
                // nếu những cây nến cuối cắt ema
                Klines.slice(-1 * amountKline).forEach((k) => {
                    if (low > k[3])
                        low = k[3];
                    if (high < k[2])
                        high = k[2];
                });
                return this.isPriceCut(high, low, emas[emas.length - 1])
            } else {
                return Klines.slice(-1 * amountKline).every(k =>
                    eval(k[2] + emaCompair + emas[emas.length - 1])
                    && eval(k[3] + emaCompair + emas[emas.length - 1])
                )
            }
        })
    }

    /**
     * lọc theo khối lượng giao dịch trong số nến
     * @param {object[]} symbolsKlines danh sách symbol có thuộc tính Klines là mảng nến
     * @param {float} volume khối lượng giao dịch nến muốn tìm 
     * @param {Compair} volumeCompair kiểu so sánh
     * @param {int} amountKline số lượng nến muốn tìm
     * @returns {object[]} symbolsKlines 
     */
    filterVolume(symbolsKlines = [], volume = 10_000_000, volumeCompair = Compair.smallerEqual, amountKline = 1) {
        return symbolsKlines.filter(({ symbol, Klines }, i) => {
            return Klines.slice(-1 * amountKline).every(k =>
                eval(k[7] + volumeCompair + volume)
            )
        })
    }

    /**
     * Lấy những đồng coin theo đường EMA và Volume trong 1 timeframe
     * @param {string} timeframe 
     * @param {integer} ema 
     * @param {string} emaCompair 
     * @param {integer} volume 
     * @param {string} volumeCompair 
     * @returns {object[]}
     */
    async getByTimeframeEMAVolume(timeframe = "1d", ema = 200, emaCompair = EMACompair.smallerEqual, volume = 10_000_000, volumeCompair = Compair.smallerEqual, futureOnly = true) {
        let all = await this.exchange.getAll()

        let symbols = (await this.exchange.getAllSymbolsWiths())

        if (futureOnly) {
            let fsymbols = (await this.exchange.getAllSymbolsFutureWiths())
            symbols = symbols.filter(v => fsymbols.includes(v)) //.slice(0, 20)
        }

        this.exchange.event.on("getKlinesFinish", ({ Symbols, Klines, StartTime, EndTime, timeFrame, index }) => {
            let percent = Math.round((Symbols.length - index) / Symbols.length * 100)
            // console.log(percent) // , Symbols[index], Klines.length, timeFrame, index)
            this.event.emit("getByTimeframeEMAVolume", { Symbols, Klines, StartTime, EndTime, timeFrame, index, percent })
        })
        let symbolsKlines = await this.exchange.getSymbolsKlines(symbols,
            moment().subtract(TIMEFRAMES.toMiliSecond(timeframe), "seconds").valueOf(), moment().valueOf(),
            timeframe
        )

        // lọc những cặp có dữ liệu
        let symbols2 = all.filter(s => {
            if (symbolsKlines[s.symbol] && symbolsKlines[s.symbol].length > 0)
                s.Klines = symbolsKlines[s.symbol];
            return symbolsKlines[s.symbol];
        })
        // lọc theo EMA
        symbols2 = this.filterEMA(symbols2, ema, emaCompair)
        // lọc theo volume
        symbols2 = this.filterVolume(symbols2, volume, volumeCompair)
        this.exchange.event.off("getKlinesFinish", () => { })

        // lấy thông tin của từng đồng bất đồng bộ
        const getInfos = async (symbols = [], i = 0) => {
            let pair = symbols[i]?.symbol
            let symbol = pair
            if (symbol) {
                StableCoins.forEach(stable => {
                    if (symbol)
                        symbol = symbol.replace(stable, "")
                })
                try {
                    let info = await this.coinmarketcap.getInfo(symbol)
                    log(info)
                    this.event.emit("getByTimeframeEMAVolume_symbolInfo", { pair: pair, info: info[symbol] })
                    setTimeout(() => {
                        getInfos(symbols, i + 1);
                    }, 1000);
                } catch (err) {
                    if (err.code === "ERR_BAD_REQUEST") {
                        setTimeout(() => {
                            getInfos(symbols, i);
                        }, 60000); // 1m
                    } else
                        error(err);
                }
            }
        }

        getInfos(symbols2)
        return symbols2
    }

}

export default ScanEma;