import React from "react";
import { connect } from 'react-redux';
import axios from 'axios';
import { Badge, Button, Col, Form, Modal, InputGroup, ProgressBar, Row, Table } from "react-bootstrap";
import { connectWsServer } from "./store/wsClient";
import Symbol from "./com/Symbol";
import { WithContext as ReactTags } from 'react-tag-input';
import { toast } from "react-toastify";
import ScanEma, { Compair, EMACompair } from "./store/ScanEma";
import { withTranslation } from "react-i18next";
import Exchanges from "./store/Exchanges";
import moment from "moment";
import millify from "millify";
import { StableCoins, TIMEFRAMES } from "./store/AltcoinSeason";
import ButtonChooseFile from "./com/ButtonChooseFile";
import AltcoinSeasonPair from "./com/AltcoinSeasonPair";
import BtnCopy from "./com/BtnCopy";

const { log, error } = console;

let countUpdateTags = 0

class Symbols extends React.Component {
    state = {
        host: (window.location.protocol == 'https:' ? 'wss://' : 'ws://') + (new URL(document.location.href)).hostname + ":" + 3001,
        btnConnectText: "connect", isConnected: false,
        status: {}, tags: [], _tags: [],
        sortType: "none", sortASC: false,
        _symbols: [], symbols: [],
        errors: "not thing",

        ScanEma: new ScanEma(),
        processing: 100,

        timeframe: "1d", ema: 200, volume: 10_000_000,

        isConnected: 0, timeWating: "",

        volumeCompair: Compair.smallerEqual, emaCompair: EMACompair.smallerEqual,
        showAltcoinSeasonPair: false, pair: "ETHUSDT",
        futureOnly: true,
    }

    constructor(props) {
        super(props);
        this.getDataJson.bind(this)
        this.rebuildAndShowData.bind(this)
    }

    rebuildAndShowData(data) {
        let { emaCompair } = this.state
        if (data) {
            data.map(s => {
                let close = Number(s.Klines[s.Klines.length - 1][4])
                let ema = s.emas[s.emas.length - 1]
                s.distanceEMA = (close - ema) / ema * 100
                if (!s.market_cap)
                    s.market_cap = 0
                return s;
            })
            this.setState({
                _symbols: data, symbols: data,
            })
        }
    }

    componentDidMount() {
        // this.connect()
        // console.clear()
        let scanEMASetting = JSON.parse(localStorage.getItem("scanEMASetting"));
        if (scanEMASetting) {
            let { timeframe, ema, emaCompair, volume, volumeCompair } = scanEMASetting;
            this.setState({ timeframe, ema, emaCompair, volume, volumeCompair })
        }

        let exchange = new Exchanges(Exchanges.Binance);
        exchange.connect()
        exchange.event
            .on("connected", (e) => {
                this.setState({ isConnected: 1 })

            }).on("error", () => {
                this.setState({ isConnected: 0 })
            })
            .on("close", () => {
                this.setState({ isConnected: -1 })
            })
            .on("retryAfter", (retryAfter) => {
                let after = moment(retryAfter);
                toast.error("Thử lại sau:" + after.format('MM/DD HH:mm:ss'))
                error("Thử lại sau:", after.format('MM/DD HH:mm:ss'))

                let diff = after.diff(moment());
                let timeleft = setInterval(() => {
                    diff = after.diff(moment());
                    if (diff > 0)
                        this.setState({ timeWating: "- " + moment(diff).format("mm:ss") + " s" })
                    else {
                        this.setState({ timeWating: "" })
                        clearInterval(timeleft)
                    }
                }, 1000);
            })
            .on("getKlines", ({ Symbol, StartTime, EndTime, timeFrame, Limit }) => {
                // this.setState({ timeWating: timeFrame + " " + Symbol })
            })
            .on("getKlinesFinish", ({ Symbols, Klines, StartTime, EndTime, timeFrame, index }) => {
                // log(Symbols, StartTime, EndTime, timeFrame, index)
                this.setState({ timeWating: timeFrame + " " + index + "/" + Symbols.length + " " + Symbols[index] })
            })

        let scanEma = new ScanEma(exchange)
        scanEma.event.on("getByTimeframeEMAVolume_symbolInfo", ({ pair, info }) => {
            countUpdateTags++
            let { symbols, _tags, } = this.state
            symbols.findIndex(s => {
                if (s.symbol === pair) {
                    s.name = info.name
                    s.market_cap = s.lastPrice * info.circulating_supply
                    s.tags = info.tags.map(v => ({ id: v, text: v }))

                    let ts = _tags.map(v => v.id)
                    s.tags.forEach(v => {
                        if (!ts.includes(v))
                            _tags.push({ id: v, text: v })
                    })
                    this.setState({ symbols: [...symbols], timeWating: pair, _tags })
                    return true
                }
                return false;
            })
            // if (symbols.length === countUpdateTags)

        })
        this.setState({ ScanEma: scanEma })
    }

    async connect(e) {
        if (e) e.preventDefault()
        let { host } = this.state;
        const { connectWsServer } = this.props;
        this.setState({ btnConnectText: "connecting" })
        console.log("connecting", host);
        connectWsServer(host).then(r => {
            console.log(r);
            if (r.error) {
                toast.error(r.error.message)
            } else {
                this.props.wsClient.onopen = () => {
                    this.setState({ btnConnectText: "connected" })
                    log("connected " + host)
                    this.setState({ isConnected: true })
                }

                this.props.wsClient.onerror = err => {
                    console.log("onerror");
                    error(err)
                    this.setState({ isConnected: false })
                    setTimeout(this.connect().catch(e => "error connect"), 3000)
                }

                this.props.wsClient.onmessage = (msg) => {
                    // log(msg.data)

                    let data = JSON.parse(msg.data)
                    if (data.status) {
                        this.setState({ status: data.status })
                        toast(data.status)
                    }

                    if (data.error) {
                        console.error(data.error)
                        this.setState({ errors: JSON.stringify(data.error) })
                    }

                    if (data.getLowCapUnderUSD) {
                        this.rebuildAndShowData(data.getLowCapUnderUSD)
                    }

                    if (data.getLowVolUnderBTC) {
                        this.rebuildAndShowData(data.getLowVolUnderBTC)
                    }

                    if (data.get4HCutEMA200) {
                        this.rebuildAndShowData(data.get4HCutEMA200)
                    }

                    if (data.get4HCutEMA99) {
                        this.rebuildAndShowData(data.get4HCutEMA99)
                    }

                    if (data.get1DUnderEMA200Under50M) {
                        this.rebuildAndShowData(data.get1DUnderEMA200Under50M)
                    }
                }

                this.props.wsClient.onclose = () => {
                    this.setState({ btnConnectText: "connect" })
                    this.setState({ isConnected: false })
                    console.log("disconnected");
                    // setTimeout(this.connect().catch(e => "error connect"), 3000)
                }
            }
        })
    }

    updateTags() {
        let { tags, symbols, _symbols } = this.state;
        if (tags.length > 0) {
            let ts = tags.map(t => t.id)
            this.setState({
                symbols: symbols.filter(symbol => {
                    return symbol.tags && symbol.tags.some(stag => ts.includes(stag.id))
                })
            })
        } else this.setState({ symbols: _symbols })
    }
    onDeleteTag = i => {
        let { tags } = this.state;
        this.setState({ tags: tags.filter((tag, index) => index !== i) }, this.updateTags);
    };
    onAddTag = tag => {
        let { tags } = this.state;
        let index = tags.findIndex(v => v.id === tag.id)
        if (index < 0)
            this.setState({ tags: [...tags, tag] }, this.updateTags);

    };
    onDragTag = (tag, currPos, newPos) => {
        let { tags } = this.state;
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
        this.setState({ tags: newTags });
    };
    onTagClicked = index => {
        console.log('The tag at index ' + index + ' was clicked');
    };
    onClearAll = () => {
        this.setState({ tags: [] }, this.updateTags)
    }

    sort(type) {
        let { symbols, sortASC } = this.state;
        console.log("sort " + type + ", ASC:" + sortASC);
        switch (type) {
            case "Name":
                symbols.sort((a, b) => sortASC ? (a.symbol > b.symbol) : (b.symbol > a.symbol));
                this.setState({ "symbols": symbols, sortASC: !sortASC })
                break;
            case "percentEMA200":
                symbols.sort((a, b) => sortASC ? (a.distanceEMA - b.distanceEMA) : (b.distanceEMA - a.distanceEMA));
                this.setState({ "symbols": symbols, sortASC: !sortASC })
                break;
            case "vol":
                symbols.sort((a, b) => sortASC ? (Number(a.candles[a.candles.length - 2][7]) - Number(b.candles[b.candles.length - 2][7])) : (Number(b.candles[b.candles.length - 2][7]) - Number(a.candles[a.candles.length - 2][7])));
                this.setState({ "symbols": symbols, sortASC: !sortASC })
                break;
            case "MarketCap":
                symbols.sort((a, b) => sortASC ? (a.market_cap - b.market_cap) : (b.market_cap - a.market_cap));
                this.setState({ "symbols": symbols, sortASC: !sortASC })
                break;
            case "Price":
                symbols.sort((a, b) => sortASC ? (a.lastPrice - b.lastPrice) : (b.lastPrice - a.lastPrice));
                this.setState({ "symbols": symbols, sortASC: !sortASC })
                break;

            default:
                break;
        }
    }

    getDataJson(file) {
        let dataDir = "data/";
        axios({
            url: dataDir + file + ".json",
        }).then(r => {
            // console.log(r.data);
            this.rebuildAndShowData(r.data);
        })
            .catch(err => {
                console.error(err);
                toast.error(err.message)
            })
    }

    // lấy những coin có volume hôm qua < 11 BTC
    getLowVolUnderBTC(e, limit = 11) {
        let { ScanEma } = this.state
        ScanEma.getLowVolUnderBTC(limit).then(this.rebuildAndShowData.bind(this))
    }

    // lấy những coin cap < 100tr$
    getLowCapUnderUSD(e, limit = 100_000_000) {
        let { ScanEma } = this.state
        ScanEma.getLowCapUnderUSD(limit).then(this.rebuildAndShowData.bind(this))
    }

    // Time 4H price cut EMA200
    get4HCutEMA200(e, maxCap = 50_000_000) {
        let { ScanEma } = this.state
        ScanEma.get4HCutEMA200(maxCap).then(this.rebuildAndShowData.bind(this))
    }

    // Time 4H price cut EMA99
    get4HCutEMA99(e, maxCap = 50_000_000) {
        let { ScanEma } = this.state
        ScanEma.get4HCutEMA99(maxCap).then(this.rebuildAndShowData.bind(this))
    }

    // Time 4H price cut EMA200
    get1DUnderEMA200Under50M(e, maxCap = 50_000_000) {
        let { ScanEma } = this.state
        ScanEma.get1DUnderEMA200Under50M(maxCap).then(this.rebuildAndShowData.bind(this))
    }

    async scan() {
        console.clear()
        let { t } = this.props
        let {
            timeframe, ema, emaCompair, volume, volumeCompair,
            ScanEma, processing, futureOnly,
        } = this.state

        log(timeframe, emaCompair, ema, volumeCompair, volume,)
        localStorage.setItem("scanEMASetting", JSON.stringify({ timeframe, ema, emaCompair, volume, volumeCompair }))

        ScanEma.event.on("getByTimeframeEMAVolume", ({ Symbols, Klines, StartTime, EndTime, timeFrame, index, percent }) => {
            this.setState({ processing: percent })
        })
        this.setState({ timeWating: t("Start scan") })
        let filtered = await ScanEma.getByTimeframeEMAVolume(timeframe, ema, emaCompair, volume, volumeCompair, futureOnly)
        this.rebuildAndShowData.bind(this)(filtered)
        ScanEma.event.off("getByTimeframeEMAVolume", () => { })
    }

    reGetInfo() {
        let { symbols, ScanEma } = this.state
        // lấy thông tin của từng đồng bất đồng bộ
        const getInfos = async (symbols = [], i = 0) => {
            let pair = symbols[i]?.symbol
            let symbol = pair
            if (symbol) {
                StableCoins.forEach(stable => {
                    if (symbol)
                        symbol = symbol.replace(stable, "")
                })
                try {
                    let info = await ScanEma.coinmarketcap.getInfo(symbol)
                    ScanEma.event.emit("getByTimeframeEMAVolume_symbolInfo", { pair: pair, info: info[symbol] })
                    setTimeout(() => {
                        getInfos(symbols, i + 1);
                    }, 1000);
                } catch (err) {
                    if (err.code === "ERR_BAD_REQUEST") {
                        setTimeout(() => {
                            getInfos(symbols, i);
                        }, 60000); // 1m
                    } else
                        error(err);
                }
            }
        }

        getInfos(symbols)
    }

    export() {
        let { symbols, timeframe, ema, emaCompair, volume, volumeCompair, futureOnly, } = this.state

        let data = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(symbols))
        var downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute("href", data);
        downloadAnchorNode.setAttribute("download", "symbols" + ".json");
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
    }

    import(e) {
        let reader = new FileReader();
        reader.onload = (event) => {
            let symbols = JSON.parse(event.target.result)

            let _tags = [];
            symbols?.forEach(s => {
                s.tags?.forEach(t => {
                    _tags.push(t.id)
                })
            })
            _tags = [...new Set(_tags)].map(v => ({ id: v, text: v }))

            this.setState({ symbols, _symbols: symbols, _tags })
        };
        reader.readAsText(e.target.files[0]);
    }

    showChartAltcoinPair(pair) {
        this.setState({ pair, showAltcoinSeasonPair: true, })
    }
    closeChartAltcoinPair(e) {
        this.setState({ showAltcoinSeasonPair: false })
    }
    onFutureOnly(e) {
        this.setState({ futureOnly: !this.state.futureOnly })
    }

    render() {
        let { t } = this.props;
        let { _tags, tags, symbols, processing,
            timeframe, ema, emaCompair, volume, volumeCompair,
            isConnected, timeWating, showAltcoinSeasonPair, pair,
            futureOnly,
        } = this.state
        let unit = "$" //symbols.length ? symbols[0].q : "$";
        return (
            <>
                <Row>
                    <Row>
                        <Col></Col>
                        <Col style={{ margin: "auto" }}>
                            {isConnected ? (isConnected > 0 ? (<Badge bg="success">connected</Badge>) : (<Badge bg="warning">connected</Badge>)) : (<Badge bg="danger">connecting...</Badge>)}
                            &nbsp; &nbsp; {timeWating}
                        </Col>
                        <Col></Col>
                    </Row>
                    {/* <Breadcrumb>
                        <Breadcrumb.Item href="/">
                            {this.state.isConnected ? (<Badge bg="success">connected</Badge>) : (<Badge bg="danger">connecting...</Badge>)}
                        </Breadcrumb.Item>
                        <Breadcrumb.Item href="#getLowCapUnderUSD" onClick={this.getLowCapUnderUSD.bind(this)}>{"Get lowcap < 100tr$"}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#getLowVolUnderBTC" onClick={this.getLowVolUnderBTC.bind(this)}>{"Get low volume < 10 BTC"}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#get4HCutEMA200" onClick={this.get4HCutEMA200.bind(this)}>{"4H price cut EMA200"}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#get4HCutEMA99" onClick={this.get4HCutEMA99.bind(this)}>{"4H price cut EMA99"}</Breadcrumb.Item>
                        <Breadcrumb.Item href="#get1DUnderEMA200Under50M" onClick={this.get1DUnderEMA200Under50M.bind(this)}>{"4h price cut EMA99 & cap <50m"}</Breadcrumb.Item>
                    </Breadcrumb> */}
                </Row>

                <Row>
                    <Col><ProgressBar animated now={processing} style={{ height: "0.5em" }} /></Col>
                </Row>
                <br />
                <Row>
                    <Form>
                        <Row>
                            <Col>
                                <InputGroup>
                                    <ButtonChooseFile onChange={this.import.bind(this)} label={t("Import")} icon="📁" />

                                    <Form.Select aria-label={t("Volume")} value={timeframe}
                                        onChange={e => this.setState({ timeframe: e.target.value })} >

                                        {Object.entries(TIMEFRAMES).map(([key, value]) => (
                                            value.toString().length <= 4 ? <option value={value}>
                                                {t(value)}
                                            </option> : ""

                                        ))}
                                    </Form.Select>
                                    EMA
                                    <Form.Select aria-label={t("EMA")} value={emaCompair}
                                        onChange={e => this.setState({ emaCompair: e.target.value })} >

                                        {Object.entries(EMACompair).map(([key, value]) => (
                                            <option value={value}>
                                                {t(key)}
                                            </option>
                                        ))}
                                    </Form.Select>

                                    <Form.Control type={"number"} placeholder={t("EMA")} value={ema}
                                        onChange={e => this.setState({ ema: e.target.value })} />

                                </InputGroup>
                            </Col>
                            <Col>
                                <InputGroup>
                                    {t("Volume")}
                                    <Form.Select aria-label={t("Volume")} value={volumeCompair}
                                        onChange={e => this.setState({ volumeCompair: e.target.value })} >
                                        {Object.entries(Compair).map(([key, value]) => (
                                            <option value={value}>
                                                {t(key)}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control type={"number"} placeholder={t("Volume")} value={volume}
                                        onChange={e => this.setState({ volume: e.target.value })} />

                                    <Form.Check style={{ color: "#fff" }}
                                        checked={futureOnly}
                                        onClick={this.onFutureOnly.bind(this)}
                                        type="switch"
                                        label={t("Future only")}
                                        value={futureOnly}
                                        name={"futureOnly"}
                                    />

                                    <Button onClick={this.scan.bind(this)}>{t("Scan")}</Button>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Form>
                </Row>
                <br />
                <Row>
                    <Table variant="dark">
                        <thead>
                            <tr>
                                <th><Button onClick={this.export.bind(this)}>💾</Button><a href="#sort-Symbol" onClick={() => this.sort("Symbol")}>{symbols.length} Symbols</a></th>
                                <th><a href="#sort-Name" onClick={() => this.sort("Name")}>Name</a></th>
                                <th><a href="#sort-Price" onClick={() => this.sort("Price")}>Price {unit}</a></th>
                                <th><a href="#sort-percentEMA200" onClick={() => this.sort("percentEMA200")}>% EMA {ema}</a></th>
                                <th><a href="#sort-vol" onClick={() => this.sort("vol")}>Volume {millify(volume)}{unit}</a></th>
                                <th> <Button onClick={this.reGetInfo.bind(this)}>♻️</Button> <a href="#sort-MarketCap" onClick={() => this.sort("MarketCap")}>Market Cap {millify(symbols.reduce((sum, v) => sum + v.market_cap, 0))}$</a></th>
                                <th>
                                    <ReactTags
                                        clearAll
                                        tags={tags}
                                        // suggestions={_tags}
                                        handleDelete={this.onDeleteTag}
                                        handleAddition={this.onAddTag}
                                        handleDrag={this.onDragTag}
                                        handleTagClick={this.onTagClicked}
                                        onClearAll={this.onClearAll}
                                        inputFieldPosition="bottom"
                                        autocomplete
                                    />
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {symbols.map((v, i) =>
                                <Symbol key={i} values={v} update={timeWating} future={futureOnly}
                                    showChartAltcoinPair={this.showChartAltcoinPair.bind(this)}
                                    handleAddition={this.onAddTag} ></Symbol>
                            )}</tbody>
                    </Table>

                    <div style={{ textAlign: "center" }}>
                        version: {moment(1700725699991).format("YYYY-MM-DD HH:mm")}
                    </div>
                </Row>


                <Modal show={showAltcoinSeasonPair} variant="dark" data-bs-theme="dark" className='themeMode altcoinPair'
                    onHide={this.closeChartAltcoinPair.bind(this)} animation={true}
                    size='xl' fullscreen>
                    <Modal.Header closeButton className='themeMode'>
                        <Modal.Title className='title'>
                            <a href={"https://www.binance.com/trade/" + pair} target="_blank">{pair} </a>  &nbsp; <BtnCopy value={pair} /> &nbsp;
                            <Button onClick={this.closeChartAltcoinPair.bind(this)}>X</Button>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className='themeMode'>
                        <AltcoinSeasonPair pair={pair} />
                    </Modal.Body>
                </Modal>
            </>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    wsClient: state.wsClientStore.client,
    wsHost: state.wsClientStore.wsHost,
});

export default connect(mapStateToProps, {
    connectWsServer: connectWsServer,
})(withTranslation()(Symbols));
