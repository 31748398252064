import { configureStore } from '@reduxjs/toolkit'
import wsClientReducer from './wsClient'
import SettingsReducer from './SettingsStore'

export default configureStore({
    reducer: {
        wsClientStore: wsClientReducer,
        SettingsStore: SettingsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
})
