import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Badge, Button, Col, Container, Form, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import millify from "millify";
import CanvasJSReact from '../assets/canvasjs.stock.react';
import { TimeFrame } from '../std';


var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSStockChart;
var CanvasJSChartNow = CanvasJSReact.CanvasJSChart;

class InterestCompound extends Component {
    state = {
        result: 0, P: 10, r: 30, n: 30,
    }

    constructor(props) {
        super(props);
        this.calInterestCompound.bind(this)
    }

    componentDidMount() {
        this.calInterestCompound()
    }

    onValueChanged(e) {
        this.setState({ [e.target.name]: e.target.value }, this.calInterestCompound)
    }

    calInterestCompound() {
        let { result, P, r, n } = this.state;
        result = P * (1 + r / 100) ** n;
        this.setState({ result: millify(result) })
    }

    render() {
        let { result, P, r, n } = this.state;

        return (
            <Container>
                <div style={{ fontSize: "22px" }}>
                    <figure className="wp-block-table"><table><tbody><tr><td className="has-text-align-center" data-align="center"><strong><span style={{ "color": "red" }}>A = P x (1 + r)^n</span></strong></td></tr></tbody></table></figure>

                    <ul><li>A = FV (Future Value – giá trị trong tương lai) là số tiền bạn nhận được trong tương lai.&nbsp;</li><li>P = PV (Present Value – giá trị trong hiện tại) là số tiền gốc bạn đầu tư ban đầu.&nbsp;</li><li>r là lãi suất hằng năm.&nbsp;</li><li>n là số chu kỳ của lãi kép.</li></ul>
                    <p><em>Ví dụ: Bạn có tiền vốn 1 tỷ VNĐ, gửi tiết kiệm với lãi suất 7%/năm. Sau 10 năm, tổng số tiền nhận được là bao nhiêu?</em> </p>
                    <p><em>Áp dụng công thức lãi kép, ta có: A = 1 tỷ x (1 + 7%)^10 = 1,967,151,357 VNĐ (Số tiền này bao gồm cả vốn lẫn lãi).</em></p>
                </div>
                <Row>

                    <Form>
                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">
                                P: Vốn ban đầu
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="number" name={"P"} value={P} onChange={this.onValueChanged.bind(this)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">
                                r: là lãi suất mỗi chu kì. (%)
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="number" name={"r"} value={r} onChange={this.onValueChanged.bind(this)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">
                                n: là số chu kỳ của lãi kép
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="number" name={"n"} value={n} onChange={this.onValueChanged.bind(this)} />
                            </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-3" >
                            <Form.Label column sm="2">
                                Kết quả:
                            </Form.Label>
                            <Col sm="10">
                                <Form.Control type="text" value={result} onChange={() => { }} />
                            </Col>
                        </Form.Group>
                    </Form>
                </Row>
            </Container >
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    wsClient: state.wsClientStore.client,
    wsHost: state.wsClientStore.wsHost,
});

export default connect(mapStateToProps, {
})(InterestCompound);

