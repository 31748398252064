import PancakeV2FactoryAbi from "./PancakeV2Factory.json";
import PancakeV3FactoryAbi from "./PancakeV3Factory.json";

import PancakeV2RouterAbi from "./PancakeV2Router.json";
import PancakeV3RouterAbi from "./PancakeV3Router.json";

import WBNBAbi from "./WBNB.json";

const
    PancakeV2Factory = { Address: "0xcA143Ce32Fe78f1f7019d7d551a6402fC5350c73", Abi: PancakeV2FactoryAbi },
    PancakeV3Factory = { Address: "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865", Abi: PancakeV3FactoryAbi },

    PancakeV2Router = { Address: "0x10ED43C718714eb63d5aA57B78B54704E256024E", Abi: PancakeV2RouterAbi },
    PancakeV3Router = { Address: "0x13f4EA83D0bd40E75C8222255bc855a974568Dd4", Abi: PancakeV3RouterAbi },

    WBNB = { Address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c", Abi: WBNBAbi }

export {
    PancakeV3Factory, PancakeV2Factory,
    PancakeV3Router, PancakeV2Router,
    WBNB,
}