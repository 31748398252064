// RB373CRKZ3HSY76N

import { w3cwebsocket } from 'websocket';
import { EventEmitter } from 'events';
import axios from 'axios';
import { toast } from 'react-toastify';
import { logerror, logwarn, logsuccess, log } from '../std';
import moment from 'moment';
import AltcoinSeason, { StableCoins, TIMEFRAMES, filterDuplicateBaseAssets, CandlestickMap, FIATS } from './AltcoinSeason';
import Coinmarketcap from './Coinmarketcap';

export default class Alphavantage {
    event
    url
    WebSocket
    WebSocketFuture
    isConnected = false;
    altcoinSeason
    constructor(url = "https://www.alphavantage.co/query?function=TIME_SERIES_DAILY&apikey=RB373CRKZ3HSY76N", event = new EventEmitter()) {
        this.url = url;
        this.event = event;
    }

    static TIMEFRAMES = {
        m1: "1min",
        m5: "5min",
        m15: "15min",
        m30: "30min",
        h1: "60min",
        d1: "daily",
        w: "weekly", // week
        M1: "monthly", // month
    }

    async getKlines(Symbol, StartTime, EndTime, timeFrame = this.TIMEFRAMES.d1, Limit = 1000) {
        let url = `${this.url}&symbol=${Symbol}&interval${timeFrame}`;
        log(url)
        let r = await axios.get(url);
        let data = window.rdataaaa = Object.values(r.data)[1]
        return Alphavantage.toTradingviewData(data)
    }

    static toTradingviewData(data) {
        return Object.entries(data).map(([key, value]) => ({
            time: moment(key).valueOf() / 1000,
            open: Number(value["1. open"]),
            high: Number(value["2. high"]),
            low: Number(value["3. low"]),
            close: Number(value["4. close"]),
            volume: Number(value["5. volume"]),
        })).sort((a, b) => a.time - b.time)
    }

}