import moment from 'moment';
import React, { Component, createRef } from 'react';
import { Button, Modal, Table } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";
import "./AltcoinSeasonPoints.scss"
import BtnCopy from './BtnCopy';
import { log } from '../std';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import millify from 'millify';
import AltcoinSeasonPair from './AltcoinSeasonPair';


class AltcoinSeasonPoints extends Component {
    state = {
        AltcoinSeasonPoints: [], SortUp: true,
        showAltcoinSeasonPair: false,
        pair: "ETHUSDT"
    }

    constructor(props) {
        super(props);
    }

    componentDidMount() { }
    componentDidUpdate(prevProps) {
        if (this.props.listCoins && prevProps.listCoins != this.props.listCoins) {
            this.setState({ AltcoinSeasonPoints: this.props.listCoins })
        }
    }

    sort(e) {
        let type = e.target.name // .href.split("-")[1]
        let { AltcoinSeasonPoints, SortUp } = this.state;
        log(type)
        AltcoinSeasonPoints.sort((a, b) => {
            if (!isNaN(a[type]))
                return SortUp ? Number(a[type]) - Number(b[type]) : Number(b[type]) - Number(a[type]);

            let compair = a[type] > b[type] ? 1 : -1;
            return SortUp ? compair : compair * -1;
        })

        this.setState({ AltcoinSeasonPoints: AltcoinSeasonPoints, SortUp: !SortUp });
    }

    showChartAltcoinPair(e) {
        let pair = e.target.value
        this.setState({ showAltcoinSeasonPair: true, pair })
    }

    closeChartAltcoinPair(e) {
        this.setState({ showAltcoinSeasonPair: false })
    }

    render() {
        let { AltcoinSeasonPoints, showAltcoinSeasonPair, pair } = this.state;
        let { listCoins, SymbolAltcoin, t, altcoinSeason } = this.props;
        return (
            <>
                <div style={{ textAlign: "center", fontSize: "30px", fontWeight: "bold" }}>{t("Altcoin Season points")}</div>
                <Table striped bordered hover variant="dark" className='AltcoinSeasonPoints'>
                    <thead key={"thead"}>
                        <tr key={"theadtr"}>
                            <th key={"symbolIndex"}>{AltcoinSeasonPoints.length} symbols</th>
                            {AltcoinSeasonPoints.length > 0 ? Object.keys(AltcoinSeasonPoints[0]).map((key) => (<>
                                <th key={key}><a href={"#sort-" + key} name={key} onClick={this.sort.bind(this)}>{key}</a></th>
                            </>)) : ""}
                        </tr>
                    </thead>
                    <tbody key={"tbody"}>
                        {AltcoinSeasonPoints.map((v, i) => (
                            <tr key={i}>
                                <td key={i}>
                                    {i + 1} <button value={v.Symbol} onClick={this.showChartAltcoinPair.bind(this)}>💹</button> &nbsp;
                                    <a href={"/altcoinPair/" + v.Symbol} target='_blank'>↗️</a>
                                </td>

                                {Object.entries(v).map(([key, value]) => {
                                    if (key == "Symbol")
                                        return (
                                            <td key={v.Symbol}>
                                                {v.Symbol == SymbolAltcoin ? "🔴" : ""} <a href={"https://www.binance.com/trade/" + v.Symbol} target="_blank">{v.Symbol} </a> <BtnCopy value={v.Symbol} /></td>
                                        )

                                    if (key.toLowerCase().includes("time"))
                                        return (
                                            <td>{moment(value).format('DD/MM/YYYY H:mm')} < BtnCopy value={value} /></td>
                                        )
                                    if (["open", "close"].includes(key.toLocaleLowerCase()))
                                        return (
                                            <td>{value} < BtnCopy value={value} /></td>
                                        )
                                    return (
                                        <td>{isNaN(value) ? value : (value - Math.floor(value)).toString().length > 5 ? millify(Number(value)) : value} < BtnCopy value={value} /></td>
                                    )
                                })}

                            </tr>
                        ))}
                    </tbody>
                </Table >

                <Modal show={showAltcoinSeasonPair} variant="dark" data-bs-theme="dark" className='themeMode altcoinPair'
                    onHide={this.closeChartAltcoinPair.bind(this)} animation={true}
                    size='xl' fullscreen>
                    <Modal.Body className='themeMode'>
                        <a href={"https://www.binance.com/trade/" + pair} target="_blank">{pair} </a>  &nbsp; <BtnCopy value={pair} /> &nbsp;
                        <Button onClick={this.closeChartAltcoinPair.bind(this)} variant='warning'>X</Button>

                        <AltcoinSeasonPair pair={pair} />
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => ({
});

export default connect(mapStateToProps, {
})(withTranslation()(AltcoinSeasonPoints));

