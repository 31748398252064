import moment from 'moment-timezone';
import momentDurationFormatSetup from "moment-duration-format";
import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, OverlayTrigger, Pagination, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from "axios";
import DatePicker from 'react-datepicker';
import { createChart, LineStyle, LineType } from 'lightweight-charts';

import { getH8Times, sentAlertTelegram, TIMEZONES, } from '../std';
import "react-datepicker/dist/react-datepicker.css";
import "./AltcoinSeasonChart.scss"
import AltcoinSeasonPoints from './AltcoinSeasonPoints';

import AltcoinSeason, { StableCoins, DataTypes, TIMEFRAMES } from "../store/AltcoinSeason";
import Exchanges from '../store/Exchanges';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./AltcoinSeasion.scss"

const { log, error } = console


momentDurationFormatSetup(moment);
window.moment = moment

var TimeFramesDefault = ["5m", "15m", "30m", "1h"];
let _tfd = JSON.parse(localStorage.getItem("TimeFramesListen"))
if (_tfd) {
    TimeFramesDefault = _tfd;
}

class AltcoinSeasonChart extends Component {
    state = {
        WsUrl: (window.location.protocol == 'https:' ? 'wss://' : 'ws://') + (new URL(document.location.href)).hostname + ":" + 3006,
        api: document.location.protocol + "//" + (new URL(document.location.href)).hostname + ":" + 3002 + "/", data: null,
        PublicDataDir: "data/",
        isConnected: 0, // -1 là ko kết nối được với binance
        timeWating: "",
        FirstTime: true, ZoomStep: 5,
        StartTime: moment().subtract(7, 'days').valueOf(), EndTime: moment().valueOf(),
        pass: "123", TimeframeSelectAll: false,

        AltcoinSeasonDataType: [DataTypes.Performed],
        TimeFrames: Object.values(TIMEFRAMES).map(tf => tf).filter(tf => typeof (tf) === "string" && TIMEFRAMES.toMiliSecond(tf) >= TIMEFRAMES.toMiliSecond("5m"))
            .map(tf => ({ TimeFrame: tf, visible: TimeFramesDefault.includes(tf) })),
        avgs: Object.keys(DataTypes).reduce((avgs, key) => { avgs[key] = { visible: false, name: key }; return avgs; }, {}),

        Symbols: [], SymbolAltcoin: "BTCUSDT", symbol: "0 symbols", symbolsType: "getTopLargestCap", numberOfSymbols: 50,
        indicator: "None", lineType: LineType.Simple,
        lineGreen: 10, lineRed: 90, rangeLines: undefined,
        lines: {}, autoCalAltcoin: false,
        H8s: undefined,

        listCoins: {}, listCoinsTimeframe: undefined,
        prefixAvgs: "avgs", showOtherLines: true,
        colors: {
            backgroundColor: '#222',
            lineColor: '#2962FF',
            textColor: '#ddd',
            areaTopColor: '#2962FF',
            areaBottomColor: 'rgba(41, 98, 255, 0.28)',
        },
        dataSeries: undefined, data: [],
        timezone: (new Date()).getTimezoneOffset() * (-1) / 60,
        altcoinSeason: undefined,
    }

    constructor(props) {
        super(props);
        this.getData.bind(this)
        this.setData.bind(this)
        this.restart.bind(this)
        this.onConnected.bind(this)
        this.alert.bind(this)

        this.chart = undefined;
        this.handleResize.bind(this)
        this.chartContainerRef = createRef();
    }

    componentDidMount() {
        let { colors, data, lines, lineType, } = this.state
        let { t, } = this.props
        this.connect()
        /** load settings default */
        let indicator = localStorage.getItem("indicator") || "HOLGER"
        let AltcoinSeasonDataType = ["Performed"]
        try {
            AltcoinSeasonDataType = JSON.parse(localStorage.getItem("AltcoinSeasonDataType")) || AltcoinSeasonDataType
        } catch (err) { }
        let autoCalAltcoin = localStorage.getItem("autoCalAltcoin") == "true"

        let symbolsType = localStorage.getItem("symbolsType") || "getTopLargestCap"
        let numberOfSymbols = localStorage.getItem("numberOfSymbols") || 50

        let lineRed = Number(localStorage.getItem("lineRed")) || 85
        let lineGreen = Number(localStorage.getItem("lineGreen")) || 15
        let showOtherLines = localStorage.getItem("showOtherLines") == 'true' ? true : false;

        lineType = Number(localStorage.getItem("lineType")) || LineType.Simple;

        let timezone = Number(localStorage.getItem("timezone")) || (new Date()).getTimezoneOffset() * (-1) / 60
        this.setState({ timezone, autoCalAltcoin })

        // listCoins[listCoinsTimeframe]
        let { TimeFrames, avgs, listCoins } = this.state
        TimeFrames.filter(v => v.visible).forEach(v => {
            try {
                let dataPoints = JSON.parse(localStorage.getItem(v.TimeFrame))
                listCoins[v.TimeFrame] = dataPoints[dataPoints.length - 1].Changes
                this.setState({ listCoins, listCoinsTimeframe: v.TimeFrame })
            } catch (err) { }
        })
        window.TimeFrames = TimeFrames;

        try {
            avgs = JSON.parse(localStorage.getItem("avgs"))
            if (avgs)
                this.setState({ avgs })
        } catch (err) { }
        /** end load settings default */

        /** init chart */
        if (!this.chart)
            window.chart = this.chart = createChart(this.chartContainerRef.current, {
                layout: {
                    background: { color: colors.backgroundColor },
                    textColor: colors.textColor,
                },
                width: this.chartContainerRef.current.clientWidth,
                height: this.chartContainerRef.current.clientHeight,
                grid: {
                    vertLines: { color: '#2f2f2f' },
                    horzLines: { color: '#2f2f2f' },
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                },
            });
        this.chart.timeScale().fitContent();

        const rangeLines = this.chart.addLineSeries({
            color: '#ffffff45',
            lineWidth: 2,
            // disabling built-in price lines
            lastValueVisible: false,
            priceLineVisible: false,
        }); window.rangeLines = rangeLines;

        rangeLines.minLine = rangeLines.createPriceLine({ price: lineGreen, color: '#008706', lineWidth: 2, lineStyle: LineStyle.Solid, axisLabelVisible: true, });
        rangeLines.maxLine = rangeLines.createPriceLine({ price: lineRed, color: '#ef5350', lineWidth: 2, lineStyle: LineStyle.Solid, axisLabelVisible: true, });
        rangeLines.setMinMax = (min, max) => {
            rangeLines.minLine.applyOptions({ price: min })
            rangeLines.maxLine.applyOptions({ price: max })
            try {
                let { H8s, lineRed, lineGreen } = this.state
                let h8s = H8s.data()
                h8s.forEach(v => v.open = v.close = (lineRed + lineGreen) / 2)
                H8s.setData(h8s)
            } catch (err) { error(err) }
        }

        let timeDeviation = timezone * 60 * 60
        rangeLines.setData([{ value: (lineRed + lineGreen) / 2, time: parseInt(moment().valueOf() / 1000 + timeDeviation) }]);

        window.addEventListener('resize', this.handleResize.bind(this));
        this.setState({ lineGreen, lineRed, rangeLines, })
        /** end init chart */

        this.setState({ indicator, AltcoinSeasonDataType, symbolsType, numberOfSymbols, lineRed, lineGreen, showOtherLines, lineType, }, () => {
            window.lines = this.state.lines
            let { TimeFrames } = this.state
            TimeFrames.forEach(tf => {
                if (tf.visible) {

                    let _altcoinSeason = JSON.parse(localStorage.getItem(tf.TimeFrame))
                    if (_altcoinSeason) {
                        this.setData(tf.TimeFrame, _altcoinSeason).then(() => {
                            this.chart.timeScale().setVisibleRange({ from: moment().subtract(4, 'hour').valueOf() / 1000, to: Date.now() / 1000 })//
                        })
                    }
                }
            })
        })
    }

    connect() {
        let { indicator } = this.state;
        let exchange = new Exchanges(Exchanges.Binance);
        let altcoinSeason = new AltcoinSeason(exchange)
        altcoinSeason.Settings.indicator = indicator;
        this.setState({ altcoinSeason: altcoinSeason })

        exchange.event
            .on("connected", (e) => {
                this.setState({ isConnected: 1 })
                this.onConnected()

            }).on("error", () => {
                this.setState({ isConnected: 0 })
            })
            .on("close", () => {
                this.setState({ isConnected: -1 })
            })
            .on("retryAfter", (retryAfter) => {
                let after = moment(retryAfter);
                toast.error("Thử lại sau:" + after.format('MM/DD HH:mm:ss'))
                error("Thử lại sau:", after.format('MM/DD HH:mm:ss'))

                let diff = after.diff(moment());
                let timeleft = setInterval(() => {
                    diff = after.diff(moment());
                    if (diff > 0)
                        this.setState({ timeWating: "- " + moment(diff).format("mm:ss") + " s" })
                    else {
                        this.setState({ timeWating: "" })
                        clearInterval(timeleft)
                    }
                }, 1000);
            })
            .on("getKlines", ({ Symbol, StartTime, EndTime, timeFrame, Limit }) => {
                // this.setState({ symbol: timeFrame + " " + Symbol })
            })
            .on("getKlinesFinish", ({ Symbols, StartTime, EndTime, timeFrame, index }) => {
                this.setState({ symbol: timeFrame + "  " + index + "/" + Symbols.length + " " + Symbols[index] })
            })

        altcoinSeason.event.
            on("calAltcoinSeasonsWhenCloseKline", ({ AltcoinSeasons, SymbolsKlines, TimeFrame }) => {
                this.setData(TimeFrame.Name, AltcoinSeasons)
                this.alert(TimeFrame.Name, AltcoinSeasons)
                if (AltcoinSeasons[AltcoinSeasons.length - 1].Changes) {
                    let { listCoins, listCoinsTimeframe } = this.state;
                    listCoins[TimeFrame.Name] = AltcoinSeasons[AltcoinSeasons.length - 1].Changes;
                    this.setState({ listCoins, listCoinsTimeframe: TimeFrame.Name })
                }
            })
            .on("calAltcoinSeasonMultiTimeFrames", ({ AltcoinSeasons, SymbolsKlines, TimeFrame, index }) => {
                this.setData(TimeFrame.Name, AltcoinSeasons)
                this.alert(TimeFrame.Name, AltcoinSeasons)
                if (AltcoinSeasons[AltcoinSeasons.length - 1].Changes) {
                    let { listCoins, listCoinsTimeframe } = this.state;
                    listCoins[TimeFrame.Name] = AltcoinSeasons[AltcoinSeasons.length - 1].Changes;
                    this.setState({ listCoins, listCoinsTimeframe: TimeFrame.Name })
                }
            })
            .on("calAltcoinSeasonMultiTimeFramesTimeRanges", ({ AltcoinSeasons, SymbolsKlines, TimeFrame, index }) => {
                this.setData(TimeFrame.Name, AltcoinSeasons)
                this.alert(TimeFrame.Name, AltcoinSeasons)
                if (AltcoinSeasons[AltcoinSeasons.length - 1].Changes) {
                    let { listCoins, listCoinsTimeframe } = this.state;
                    listCoins[TimeFrame.Name] = AltcoinSeasons[AltcoinSeasons.length - 1].Changes;
                    this.setState({ listCoins, listCoinsTimeframe: TimeFrame.Name })
                }
            })

        exchange.connect()
        this.setState({ exchange, })
    }

    alert(timeframe, altcoinseason) {
        if (altcoinseason && altcoinseason.length > 0) {
            let { lineGreen, lineRed, timezone, } = this.state
            let { settings } = this.props
            let current = altcoinseason[altcoinseason.length - 1],
                pre = altcoinseason[altcoinseason.length - 2];
            let mes = ""
            if (pre["Performed"] > lineGreen && current["Performed"] < lineGreen) {
                mes = `🟢 ${timeframe} LONG BUY ${moment(current.Time).format("YYYY-MM-DD HH:mm")} ${timezone}`
                sentAlertTelegram(mes, "altcointest", settings)  // 
            }

            if (pre["Performed"] < lineRed && current["Performed"] > lineRed) {
                mes = `🔴 ${timeframe} SELL SHORT ${moment(current.Time).format("YYYY/MM/DD HH:mm ")} ${timezone}`
                sentAlertTelegram(mes, "altcointest", settings)  // 
            }
        }
    }

    async onConnected() {
        let { exchange, altcoinSeason, TimeFrames, symbolsType, } = this.state;
        // let timeframes = TimeFrames.filter(tf => tf.visible).map(tf => tf.TimeFrame)
        await this.changeSymbols({ target: { value: symbolsType } }).then(() => this.onAutoCalAltcoinChange({ target: { checked: this.state.autoCalAltcoin } }))

        // nếu 30m, 1h mà dưới 15 hoặc trên 85 thì báo động
        // altcoinSeason.alertAltcoinSeasonsBuySell()
        // let QuoteAssets = ["BTC", ...StableCoins]

        // Tìm các đồng tiền mà có cặp với BTC, trả về đồng cặp với StableCoins, ví dụ: ETH/USDT
        // let Symbols = await exchange.getSymbolsQuoteBTCQuoteWith()

        // await calAltcoinSeasonMultiTimeFrames(Symbols, Settings.TimeFramesListen, StartTime, EndTime);

        // sự kiện thời gian còn kết thúc 1 timeframe
        exchange.timer(TimeFrames.map(tf => tf.TimeFrame))
            .on("percentageRemaining", ({ Timeframe, openTime, closeTime, percentageRemaining, elapsedTime }) => {
                let tfs = [...this.state.TimeFrames]
                tfs.forEach(tf => {
                    if (tf.TimeFrame == Timeframe) {
                        let elapsedTime_ = Math.abs(elapsedTime)
                        let customTemplateTimeDuration = elapsedTime_ > TIMEFRAMES.toMiliSecond("1w") ? "w[w] d[d]" :
                            (elapsedTime_ > TIMEFRAMES.toMiliSecond("1d") ? "d[d] hh:mm" : "hh:mm:ss");

                        tf.remaining = moment.duration(elapsedTime, 'milliseconds').format(customTemplateTimeDuration, { trim: true });
                        tf.percentageRemaining = Math.floor(Math.abs(percentageRemaining))
                    }
                })
                this.setState({ TimeFrames: tfs })
            })


    }

    set_calAltcoinSeasonsWhenCloseKline(timeframe, loop) {
        let { TimeFrames, } = this.state
        TimeFrames.findIndex(async tf => {
            if (tf.TimeFrame === timeframe) {
                tf.calAltcoinSeason = loop
                this.setState({ TimeFrames })
                return true;
            }
            return false;
        })
    }

    /**
     * Bắt đầu quét dữ liệu dựa trên những timeframes đã chọn
     */
    async getData() {
        let { TimeFrames, isConnected, exchange, altcoinSeason, Symbols, SymbolAltcoin, indicator, autoCalAltcoin, } = this.state;
        if (!isConnected)
            exchange.connect()

        let list = TimeFrames.filter(tf => tf.visible).map(v => v.TimeFrame)
        if (list.length <= 0) {
            toast.error("Vui lòng chọn timeframe")
        } else {
            toast("Đang tính " + list.toString())
            list.map(timeframe => {
                let _altcoinSeason
                try {
                    _altcoinSeason = JSON.parse(localStorage.getItem(timeframe))
                    if (_altcoinSeason) {
                        this.setData(timeframe, _altcoinSeason)
                    }

                } catch (err) {
                    error(timeframe, _altcoinSeason, err)
                }
            })

            let _TimeFrames = list.map(tf => {
                let StartTime = 0
                let EndTime = moment().valueOf()

                return {
                    Name: tf,
                    StartTime: StartTime,
                    EndTime: EndTime,
                }
            })
            if (indicator == "None") {
                await altcoinSeason.calAltcoinSeasonMultiTimeFramesTimeRanges(Symbols, _TimeFrames)
                // tính altcoin season khi đóng nến
                // nếu bật tự động tính altcoin khi đóng nến
                if (autoCalAltcoin)
                    await altcoinSeason.calAltcoinSeasonsWhenCloseKlineContinuous(Symbols, _TimeFrames.map(v => ({ Name: v.Name, during: (v.EndTime - v.StartTime) })))
                        .then(loops => {
                            TimeFrames.forEach(tf => {
                                loops.forEach(async loop => {
                                    if (tf.TimeFrame == loop.name)
                                        tf.calAltcoinSeason = await loop.loop;
                                })
                            })
                            this.setState({ TimeFrames })
                        })

            } else if (indicator == "HOLGER") {
                await altcoinSeason.calAltcoinSeasonMultiTimeFramesTimeRangesByHolger(Symbols, _TimeFrames, { amountOfTops: 50, SymbolAltcoin })
                // tính altcoin season khi đóng nến
                // nếu bật tự động tính altcoin khi đóng nến
                if (autoCalAltcoin)
                    await altcoinSeason.calAltcoinSeasonsWhenCloseKlineByHolger(Symbols, _TimeFrames.map(v => ({ Name: v.Name, during: (v.EndTime - v.StartTime) })), { amountOfTops: 50, SymbolAltcoin })
                        .then(loops => {
                            TimeFrames.forEach(tf => {
                                loops.forEach(async loop => {
                                    if (tf.TimeFrame == loop.name) {
                                        tf.calAltcoinSeason = await loop.loop;
                                    }
                                })
                            })
                            this.setState({ TimeFrames })
                        })
            } else {
                await altcoinSeason.calAltcoinSeasonMultiTimeFramesTimeRangesByIndicator(Symbols, _TimeFrames, indicator)
                // tính altcoin season khi đóng nến
                // nếu bật tự động tính altcoin khi đóng nến
                if (autoCalAltcoin)
                    await altcoinSeason.calAltcoinSeasonsWhenCloseKlineByIndicator(Symbols, _TimeFrames.map(v => ({ Name: v.Name, during: (v.EndTime - v.StartTime) })), indicator)
                        .then(loops => {
                            TimeFrames.forEach(tf => {
                                loops.forEach(async loop => {
                                    if (tf.TimeFrame == loop.name) {
                                        tf.calAltcoinSeason = await loop.loop;
                                    }
                                })
                            })
                            this.setState({ TimeFrames })
                        })
            }
        }
    }

    onAutoCalAltcoinChange(e) {
        let { autoCalAltcoin, Symbols, SymbolAltcoin, indicator, TimeFrames, altcoinSeason, } = this.state;
        autoCalAltcoin = e.target.checked;
        localStorage.setItem("autoCalAltcoin", autoCalAltcoin)
        this.setState({ autoCalAltcoin })

        if (autoCalAltcoin) {
            let downUints = []
            // khi đóng nến, nếu hẹn giờ tự động tính altcoin thì bật
            TimeFrames.forEach(async tf => {
                if (!downUints.includes(TIMEFRAMES.downUnit(tf.TimeFrame))) {
                    downUints.push(TIMEFRAMES.downUnit(tf.TimeFrame));
                    log(downUints)
                    if (!tf.calAltcoinSeason)
                        tf.calAltcoinSeason = true;
                    if (altcoinSeason && altcoinSeason.calAltcoinSeasonsWhenCloseKline && tf.calAltcoinSeason === true) {
                        // nếu chưa có vòng lặp thì tạo vòng lặp 
                        // tính luôn altcoin season 
                        let StartTime = 0,
                            EndTime = moment().valueOf();

                        if (indicator == "None")
                            // tính altcoin season khi đóng nến
                            await altcoinSeason.calAltcoinSeasonsWhenCloseKlineContinuous(Symbols, [{ Name: tf.TimeFrame, during: (EndTime - StartTime) }])
                                .then(loops => {
                                    tf.calAltcoinSeason = loops[0].loop
                                    loops[0].loop.then(loop => {
                                        this.set_calAltcoinSeasonsWhenCloseKline(tf.TimeFrame, loop)
                                    })
                                })
                        else if (indicator == "HOLGER") {
                            await altcoinSeason.calAltcoinSeasonsWhenCloseKlineByHolger(Symbols, [{ Name: tf.TimeFrame, during: (EndTime - moment().subtract(777, "days").valueOf()) }], { amountOfTops: 50, SymbolAltcoin })
                                .then(loops => {
                                    tf.calAltcoinSeason = loops[0].loop
                                    loops[0].loop.then(loop => {
                                        this.set_calAltcoinSeasonsWhenCloseKline(tf.TimeFrame, loop)
                                    })
                                })
                        } else
                            await altcoinSeason.calAltcoinSeasonsWhenCloseKlineByIndicator(Symbols, [{ Name: tf.TimeFrame, during: (EndTime - moment().subtract(777, "days").valueOf()) }], indicator)
                                .then(loops => {
                                    tf.calAltcoinSeason = loops[0].loop
                                    loops[0].loop.then(loop => {
                                        this.set_calAltcoinSeasonsWhenCloseKline(tf.TimeFrame, loop)
                                    })
                                })
                    }
                }
            })
            this.setState({ TimeFrames });
        }
    }

    /**
     * khi timeframe được bật tắt
     * @param {event} e 
     */
    onTimeFramesChanged(e) {
        let { lines, TimeFrames, Symbols, SymbolAltcoin, altcoinSeason, indicator, autoCalAltcoin } = this.state;
        let value = e.target.value;

        let CountVisible = TimeFrames.filter(tf => {
            if (tf.TimeFrame == value)
                tf.visible = e.target.checked
            return tf.visible;
        }).length;
        this.setState({
            TimeFrames: TimeFrames,
            TimeframeSelectAll: CountVisible == TimeFrames.length
        }, () => {
            let _altcoinSeason = JSON.parse(localStorage.getItem(value))
            if (_altcoinSeason) {
                this.setData(value, _altcoinSeason)
            }
        });
    }

    handleResize() {
        let { chartContainerRef } = this
        this.chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    /**
     * hiển thị altcoin season
     * @param {string} timeframe 
     * @param {Array} altcoinSeason 
     */
    async setData(timeframe, altcoinSeason) {
        let { AltcoinSeasonDataType, timezone, rangeLines, lineRed, lineGreen, lines, lineType, TimeFrames, H8s } = this.state;
        let timeDeviation = timezone * 60 * 60
        // NumberOfTrades | Performed | PerformedQuoteVolume | PriceChange | Time | VolumeChange 
        Object.values(DataTypes).forEach(dataType => {
            let data = altcoinSeason.map(v => v[dataType] ? ({
                time: parseInt(v.Time / 1000) + timeDeviation,
                value: v[dataType] ? parseFloat(v[dataType].toFixed(2)) : 0
            }) : null).filter(v => v)

            let lineSeries = lines[timeframe] ? lines[timeframe][dataType] : undefined;

            // nếu có thì xóa thêm lại
            if (lineSeries)
                try {
                    this.chart.removeSeries(lineSeries);
                } catch (err) { }

            // nếu timeframe và AltcoinSeasonDataType được chọn thì hiển thị
            let visible = (TimeFrames.find(tf => tf.TimeFrame == timeframe && tf.visible == true) ? true : false) && AltcoinSeasonDataType.includes(dataType)

            lineSeries = this.chart.addLineSeries({
                color: TIMEFRAMES.toColor(timeframe),
                lineWidth: 2,
                lineType: lineType,
                // disabling built-in price lines
                lastValueVisible: false,
                priceLineVisible: false,
                lineStyle: dataType == DataTypes.Performed ? LineStyle.Solid : dataType == DataTypes.VolumeChange ? LineStyle.LargeDashed : LineStyle.Dotted,
            });

            if ((!H8s || H8s.data().length < altcoinSeason.length) && (TIMEFRAMES.toMiliSecond(timeframe) <= TIMEFRAMES.toMiliSecond("8h"))) {
                if (H8s)
                    try {
                        this.chart.removeSeries(H8s)
                    } catch (err) { }

                H8s = this.chart.addCandlestickSeries({
                    upColor: '#f79647a1', downColor: '#f79647a1', borderVisible: false,
                    wickUpColor: '#f79647a1', wickDownColor: '#f79647a1',
                }); window.H8s = H8s;

                let from = altcoinSeason[0].Time,
                    to = altcoinSeason[altcoinSeason.length - 1].Time;
                const candles = getH8Times(from, to).map(time => ({
                    time: time / 1000 + timeDeviation,
                    open: (lineRed + lineGreen) / 2,
                    high: 100,
                    low: 0,
                    close: (lineRed + lineGreen) / 2,
                }))
                H8s.setData(candles)
                this.setState({ H8s })
            }

            lineSeries.setData(data)
            // nếu timeframe và AltcoinSeasonDataType được chọn thì hiển thị
            lineSeries.applyOptions({ visible })

            if (!lines[timeframe]) {
                lines[timeframe] = {};
            }
            lines[timeframe][dataType] = lineSeries
        })
        rangeLines.setData([{ value: (lineRed + lineGreen) / 2, time: parseInt(moment().valueOf() / 1000 + timeDeviation) }]);

        window.lines = lines
        this.setState({ lines })
    }

    /**
     * những timeframe được chọn sẽ được ẩn đi
     * hiện 1 đường trung bình dựa trên những đường đã chọn
     */
    averageLine(e) {
        let { TimeFrames, showOtherLines, AltcoinSeasonDataType, lines, avgs, prefixAvgs, timezone, } = this.state;
        let { t } = this.props;
        let { name, checked } = e.target

        let list = TimeFrames.filter(tf => tf.visible).map(v => v.TimeFrame)

        if (list.length <= 0) {
            return toast.error(t("Please choose timeframe"))
        } else {
            if (checked) {
                // tìm các line được chọn, ẩn đi, đưa data vào danh sách
                let linesData = []
                let timeframe = "", data = [];
                list.forEach((v, i) => {
                    if (lines[v]) {
                        let lineData = lines[v][name].data()
                        linesData.push([v, lineData]);
                        // tìm timeframe có độ dài data lớn nhất
                        if (data.length < lineData.length) {
                            timeframe = v;
                            data = lineData;
                        }
                    }
                })
                // tính trung bình: duyệt các phần tử ở timeframe có độ dài lớn nhất
                let avgData = data.map(v => {
                    // lấy mốc thời gian
                    let time = v.time
                    let founds = [v.value]
                    // tìm trong các timeframe khác có cùng thời điểm, đưa vào founds
                    linesData.forEach(([timeframe_, data_]) => {
                        if (timeframe !== timeframe_) {
                            let found = data_.find(v_ => v_.time === time)
                            if (found) {
                                founds.push(found.value)
                            }
                        }
                    })
                    return {
                        time, value: founds.reduce((s, v) => s + v, 0) / founds.length
                    }
                })
                if (!lines[prefixAvgs]) lines[prefixAvgs] = {};
                let lineSeries = lines[prefixAvgs][name];
                // xóa thêm lại
                if (lineSeries)
                    this.chart.removeSeries(lineSeries)

                lines[prefixAvgs][name] = lineSeries = this.chart.addLineSeries({
                    color: TIMEFRAMES.toColor(name),
                    lineWidth: 5,
                    // disabling built-in price lines
                    lastValueVisible: false,
                    priceLineVisible: false,
                });
                lineSeries.setData(avgData);

                avgs[name].line = lineSeries
                this.setState({ lines })
            } else {
                lines[prefixAvgs][name].applyOptions({ visible: false })
            }
            avgs[name].visible = checked;
            this.setState({ avgs, lines })
            // ẩn hoặc hiện các đường timeframes
            TimeFrames.forEach(tf => {
                if (lines[tf.TimeFrame] && tf.visible)
                    Object.entries(lines[tf.TimeFrame]).forEach(([key, line]) => {
                        line.applyOptions({ visible: showOtherLines && AltcoinSeasonDataType.includes(key) })
                    })
            })
        }
    }

    /**
     ẩn hiện các đường được chọn trên biểu đồ
    */
    onShowOtherLines(e) {
        let showOtherLines = e.target.checked
        this.setState({ showOtherLines }, () => {
            let { lines, TimeFrames, AltcoinSeasonDataType, } = this.state;
            // ẩn hoặc hiện các đường đường được chọn
            TimeFrames.forEach(tf => {
                if (lines[tf.TimeFrame] && tf.visible)
                    Object.entries(lines[tf.TimeFrame]).forEach(([key, line]) => {
                        line.applyOptions({ visible: showOtherLines && AltcoinSeasonDataType.includes(key) })
                    })
            })
            localStorage.setItem("showOtherLines", showOtherLines)
        })
    }

    onTimeframeSelectAll(e) {
        let { TimeFrames, lines, AltcoinSeasonDataType } = this.state;
        let CheckAll = e.target.value.toLowerCase() == "true";
        // duyệt các timeframe, nếu CheckAll là true và timeframe đó chưa hiện, lấy localStorage, hiện tất cả 
        TimeFrames.forEach(tf => {
            tf.visible = CheckAll;
            if (CheckAll && !lines[tf.TimeFrame]) {
                let _altcoinSeason = JSON.parse(localStorage.getItem(tf.TimeFrame))
                if (_altcoinSeason) {
                    this.setData(tf.TimeFrame, _altcoinSeason)
                }
            }
        })
        // Ẩn hoặc hiện các timframe với CheckAll
        Object.values(lines).forEach(tf => {
            Object.entries(tf).forEach(([dataType, line]) => {
                if (AltcoinSeasonDataType.includes(dataType))
                    line.applyOptions({ visible: CheckAll });
            })
        })

        this.setState({ TimeframeSelectAll: CheckAll, TimeFrames, lines })
    }

    async scanData(e) {
        let { TimeFrames, StartTime, EndTime, Symbols, altcoinSeason } = this.state;
        let list = TimeFrames.filter(tf => tf.visible).map(tf => tf.TimeFrame)
        if (list.length > 0) {
            altcoinSeason.calAltcoinSeasonMultiTimeFrames(Symbols, list, ["1d", "3d", "1w", "1M"], moment("2018-12-15", "YYYY-MM-DD").valueOf(), moment().valueOf());

        } else {
            toast.error("Vui lòng chọn Timeframes")
        }
    }

    /**
     * thay đổi timeframes khi khởi động
     */
    setTimeFramesListen(e) {
        let { api, TimeFrames, pass } = this.state;
        let timeFrames = TimeFrames.filter(tf => tf.visible).map(tf => tf.TimeFrame)
        localStorage.setItem("TimeFramesListen", JSON.stringify(timeFrames))
        toast("Đã lưu danh sách, vui lòng Restart")

        document.location.reload()
    }

    async restart() {
        let { pass } = this.state;
        let { sendData } = this.props;
        document.location.reload()
        // sendData({
        //     Restart: { pass: pass }
        // })
    }

    /**
     * thay đổi loại symbols khi tính toán và danh sách Symbols để tính toán
     * @param {event} e 
     */
    async changeSymbols(e) {
        return new Promise((rs, rj) => {
            let { exchange, altcoinSeason, numberOfSymbols, } = this.state;
            let { t } = this.props;

            this.setState({ symbolsType: e.target.value }, async () => {
                let Symbols
                switch (e.target.value) {
                    case "getFutureAllSymbolsWiths":
                        Symbols = await exchange.getFutureAllSymbolsWiths(['USDT']);
                        break;
                    case "getTopLargestCap":
                        try {
                            // nếu lấy ở coinmarket cap thành công
                            let data = await exchange.getTopLargestCap(10_000_000, numberOfSymbols)
                            altcoinSeason.Symbols = data;
                            Symbols = (data).map(v => v.symbol)//.filter(v => !v.startsWith("BTC"));
                            localStorage.setItem("Symbols", JSON.stringify(Symbols))
                        } catch (err) {
                            toast.error(t("Can not get Symbols"))
                            // nếu lấy ở localStorage cap thành công
                            let data = localStorage.getItem("Symbols")
                            if (data)
                                Symbols = JSON.parse(data)
                            else {
                                let { data } = await axios.get("Symbols.json")
                                Symbols = numberOfSymbols < data.length ? data.slice(0, numberOfSymbols - 1) : data
                            }
                        }
                        break;
                    case "getSymbolsQuoteBTCQuoteWith":
                        Symbols = await exchange.getSymbolsQuoteBTCQuoteWith(['USDT']);
                        break;
                    default:
                        Symbols = await exchange.getFutureAllSymbolsWiths(['USDT']);
                        break;
                }
                let symbol = Symbols.length + " " + t("Symbols")
                toast.success(symbol)

                localStorage.setItem("symbolsType", e.target.value)

                altcoinSeason.Symbols = Symbols

                this.setState({ Symbols, altcoinSeason, symbol, }, () => rs(Symbols));
            })
        })
    }

    /**
     * khi kiểu chỉ báo thay đổi
     * @param {event} e 
     */
    onIndicatorChange(e) {
        let { } = this.state
        this.state.altcoinSeason.Settings.indicator = e.target.value

        this.setState({ indicator: e.target.value, })
        localStorage.setItem("indicator", e.target.value)
    }

    onAltcoinSeasonDataTypeChange(e) {
        let { AltcoinSeasonDataType } = this.state
        let { name, checked } = e.target
        if (!checked) {
            AltcoinSeasonDataType = AltcoinSeasonDataType.filter(v => v !== name)
        } else {
            AltcoinSeasonDataType = Array.from(new Set(AltcoinSeasonDataType.concat([name])))
        }
        this.setState({ AltcoinSeasonDataType: AltcoinSeasonDataType }, () => {
            // tìm các timeframe, hiện hoặc ẩn nó tương ứng với ${name}
            let { TimeFrames, lines } = this.state
            TimeFrames.forEach(v => {
                if (v.visible) {
                    if (lines[v.TimeFrame] && lines[v.TimeFrame][name])
                        lines[v.TimeFrame][name].applyOptions({ visible: checked })
                    else {
                        let _altcoinSeason = JSON.parse(localStorage.getItem(v.TimeFrame))
                        if (_altcoinSeason)
                            this.setData(v.TimeFrame, _altcoinSeason);
                    }
                }
            })
        })
        localStorage.setItem("AltcoinSeasonDataType", JSON.stringify(AltcoinSeasonDataType))
    }

    /**
     * thay đổi đường giới hạn trên dưới, đỏ xanh
     * @param {event} e 
     */
    onChangeLine(e) {
        let { name, value } = e.target;
        localStorage.setItem(name, value)
        this.setState({ [name]: Number(value) }, () => {
            let { rangeLines, lineGreen, lineRed } = this.state
            rangeLines.setMinMax(lineGreen, lineRed)
        })
    }

    onListCoinsTimeframeChange(e) {
        let { listCoins, listCoinsTimeframe } = this.state;
        this.setState({ listCoinsTimeframe: e.target.value },)
    }

    onNumberOfSymbols(e) {
        let value = Number(e.target.value)
        if (value >= 2) {
            this.setState({ numberOfSymbols: value })
            localStorage.setItem("numberOfSymbols", value)
        }
    }

    onSymbolAltcoinChange(e) {
        let SymbolAltcoin = e.target.value
        this.setState({ SymbolAltcoin })
    }

    zoom(e) {
        let { TimeFrames, lines, AltcoinSeasonDataType } = this.state;
        let value = e.target.attributes.value.value
        if (value === "fit")
            return this.chart.timeScale().fitContent();

        // tìm những timeframe đang hiện, cái nào có time mới nhất thì chọn làm đầu
        let timeframe = TimeFrames.find(v => v.visible)
        if (timeframe && AltcoinSeasonDataType.length > 0) {
            // let DataType = AltcoinSeasonDataType.find(v => v.visible)
            // if (DataType) {
            let data = lines[timeframe.TimeFrame][AltcoinSeasonDataType[0]].data()
            let to = data[data.length - 1].time
            let rangeTime = TIMEFRAMES.toMiliSecond(value) / 1000;

            this.chart.timeScale().setVisibleLogicalRange({
                from: parseInt(to - rangeTime),// 2h trước
                to
            })
            // }
        }
    }

    onLineTypeChange(e) {
        let { lineType, lines, } = this.state;
        lineType = Number(e.target.value);
        this.setState({ lineType })
        localStorage.setItem("lineType", lineType)
        Object.values(lines).forEach(tf => {
            Object.values(tf).forEach(line => {
                if (line)
                    line.applyOptions({ lineType: lineType })
            })
        })
    }

    onChangeTimezone(e) {
        let timezone = e.target.value;
        localStorage.setItem("timezone", timezone)

        this.setState({ timezone: timezone }, () => {
            let { TimeFrames, rangeLines, lineRed, lineGreen, } = this.state
            let timeDeviation = timezone * 60 * 60
            rangeLines.setData([{ value: (lineRed + lineGreen) / 2, time: parseInt(moment().valueOf() / 1000 + timeDeviation) }]);

            TimeFrames.forEach(tf => {
                let _altcoinSeason = JSON.parse(localStorage.getItem(tf.TimeFrame))
                if (_altcoinSeason) {
                    this.setData(tf.TimeFrame, _altcoinSeason).then(() => {
                        this.chart.timeScale().setVisibleRange({ from: moment().subtract(4, 'hour').valueOf() / 1000, to: Date.now() / 1000 })//
                    })
                }
            })
        })
    }

    render() {
        let { t } = this.props;
        let { altcoinSeason, Symbols, SymbolAltcoin, indicator, symbolsType, TimeFrames, StartTime, EndTime,
            lineGreen, lineRed, numberOfSymbols,
            pass, TimeframeSelectAll, showOtherLines, avgs,
            timeWating, isConnected, symbol, AltcoinSeasonDataType, autoCalAltcoin,
            listCoins, listCoinsTimeframe, timezone, lineType, } = this.state;
        return (
            <>
                {/* Chart */}
                <Row>
                    <div ref={this.chartContainerRef} style={{ height: "38em" }} />
                </Row>
                {/* zoom , thu phóng theo thời gian */}
                <Row>
                    <Col>
                        <Pagination>{
                            ["1m", "5m", "15m", "30m",].map(timerange => (
                                <Pagination.Item key={timerange} value={timerange} onClick={this.zoom.bind(this)}>{timerange}</Pagination.Item>
                            ))
                        }
                            <Pagination.Item key={"fit"} value={"fit"} onClick={this.zoom.bind(this)}>🎛️ {t("Full")}</Pagination.Item>
                        </Pagination>
                    </Col>

                    <Col style={{ "display": "inline-flex" }}>
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("Curve")} name='LineType' value={LineType.Curved}
                            checked={lineType === LineType.Curved} onChange={this.onLineTypeChange.bind(this)}
                        />&nbsp;&nbsp;
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("WithSteps")} name='LineType' value={LineType.WithSteps}
                            checked={lineType === LineType.WithSteps} onChange={this.onLineTypeChange.bind(this)}
                        />&nbsp;&nbsp;
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("Simple")} name='LineType' value={LineType.Simple}
                            checked={lineType === LineType.Simple} onChange={this.onLineTypeChange.bind(this)}
                        />
                    </Col>

                    {/* timezone múi giờ */}
                    <Col>
                        <Form.Select aria-label="timezone" defaultValue={timezone} value={timezone} onChange={this.onChangeTimezone.bind(this)}>
                            {TIMEZONES.map((_tz => (
                                <option value={_tz.offset}>{_tz.offset} · {_tz.name}</option>
                            )))}
                        </Form.Select>
                    </Col>
                </Row>

                {/* các nút chọn timeframe */}
                <Row className='d-flex justify-content-center'>
                    <Form name="TimeFrames">

                        <Row>
                            <Col>
                                <Form.Select aria-label="Symbols" onChange={this.changeSymbols.bind(this)} value={symbolsType}>
                                    <option value="getFutureAllSymbolsWiths">{t("Symbols on Future pair USDT")}</option>
                                    <option value="getTopLargestCap">{t("Top 50 coin largest market cap")}</option>
                                    <option value="getSymbolsQuoteBTCQuoteWith">{t("Symbols pair with BTC")}</option>
                                </Form.Select></Col>
                            <Col>
                                <Form.Control
                                    type="number" name='numberOfSymbols'
                                    placeholder={t("Number of symbols")}
                                    value={numberOfSymbols} onChange={this.onNumberOfSymbols.bind(this)}
                                /></Col>
                            <Col>
                                <Form.Select aria-label={t("Symbols")} onChange={this.onSymbolAltcoinChange.bind(this)} value={SymbolAltcoin}>
                                    {Symbols.map(v => (
                                        <option value={v}>{v}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col>
                                <Form.Select aria-label="Indicators" onChange={this.onIndicatorChange.bind(this)} value={indicator}>
                                    <option value="None">None</option>
                                    <option value="HOLGER">HOLGER</option>
                                    <option value="EMA">EMA</option>
                                    <option value="SMA">SMA</option>
                                    <option value="WEMA">WEMA</option>
                                    <option value="TRIX">TRIX</option>
                                </Form.Select></Col>
                        </Row>

                        <div style={{ display: "inherit", width: "fit-content", margin: "auto" }}>
                            <OverlayTrigger overlay={<Tooltip>
                                {t("Refresh")}
                            </Tooltip>}>
                                <Button onClick={e => window.location.reload()}><FontAwesomeIcon icon="fa-arrows-rotate" /></Button>
                            </OverlayTrigger>
                            {isConnected ? (isConnected > 0 ? (<Button variant="success" disabled>connected</Button>) : (<Button variant="warning" disabled>connected</Button>)) : (<Button variant="danger" disabled>connecting...</Button>)}
                            &nbsp; &nbsp;

                            <Button onClick={this.getData.bind(this)} disabled={Symbols.length == 0}> <FontAwesomeIcon icon="fa-cloud-arrow-down" /> {t("get data")}</Button> &nbsp;

                            <label style={{ fontSize: "30px", color: "red" }}>
                                {timeWating}
                            </label>

                            <label style={{ minWidth: "200px" }}>
                                {symbol}
                                {/* <Loader text={symbol} /> */}
                            </label>

                            {Object.values(DataTypes).map(type => (
                                <label className='selectTimeFrame'>
                                    <Form.Check type="switch" checked={AltcoinSeasonDataType.includes(type)}
                                        name={type} label={t(type)}
                                        onChange={this.onAltcoinSeasonDataTypeChange.bind(this)}
                                    />
                                </label>
                            ))}

                            <Button variant={"danger"} onClick={e => { localStorage.clear(); window.location.reload(); }}><FontAwesomeIcon icon="fa-trash" /> Reset</Button>
                        </div>

                        {/* các timeframe hiển thị */}
                        <div className='selectTimeFrame'>
                            {/* Tự động tính altcoin khi đóng nến ? */}
                            <OverlayTrigger overlay={<Tooltip>
                                ⏰ {t("Auto calculator Altcoin season when close candle")}
                            </Tooltip>}>
                                <Form.Check style={{ color: "#fff" }}
                                    type="switch" label="⏰"
                                    checked={autoCalAltcoin} onChange={this.onAutoCalAltcoinChange.bind(this)}
                                />
                            </OverlayTrigger>

                            {TimeFrames.map((tf, id) => (
                                <label className='selectTimeFrame' key={id} style={{ background: TIMEFRAMES.toColor(tf.TimeFrame) }}>
                                    <Form.Check style={{ color: "#fff" }}
                                        checked={tf.visible}
                                        onChange={this.onTimeFramesChanged.bind(this)}
                                        type="switch"
                                        label={tf.TimeFrame + (tf.calAltcoinSeason ? "~" : "")}
                                        value={tf.TimeFrame}
                                        name="TimeFrames"
                                    />
                                    <ProgressBar now={tf.percentageRemaining} />
                                    <div className='time-remaining'>{tf.remaining}</div>
                                </label>))
                            }

                            <OverlayTrigger overlay={<Tooltip>
                                💾 {t("Save list TimeFrames")}
                            </Tooltip>}>
                                <Button onClick={this.setTimeFramesListen.bind(this)} variant="warning" disabled={TimeFrames.every(v => !v.visible)}>💾</Button>
                            </OverlayTrigger>

                        </div>

                        {/* Ẩn - Hiện tất cả, tính trung bình */}
                        <div className='selectTimeFrame d-flex'>
                            <label className='selectTimeFrame' key={"ShowAll"}>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={TimeframeSelectAll}
                                    onClick={this.onTimeframeSelectAll.bind(this)}
                                    type="radio"
                                    label={t("Show all")}
                                    value={true}
                                    name={"TimeframeSelectAll"}
                                />
                            </label> &nbsp;

                            <label className='selectTimeFrame' key={"HideAll"}>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={!TimeframeSelectAll}
                                    onClick={this.onTimeframeSelectAll.bind(this)}
                                    type="radio"
                                    label={t("Hide all")}
                                    value={false}
                                    name={"TimeframeSelectAll"}
                                />
                            </label> &nbsp;

                            <label className='selectTimeFrame' style={{ background: TIMEFRAMES.toColor(DataTypes.Performed) }}>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={avgs[DataTypes.Performed]?.visible}
                                    onChange={this.averageLine.bind(this)}
                                    type="switch"
                                    label={t(DataTypes.Performed)}
                                    name={DataTypes.Performed}
                                />
                            </label> &nbsp;

                            <label className='selectTimeFrame' style={{ background: TIMEFRAMES.toColor(DataTypes.VolumeChange) }}>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={avgs[DataTypes.VolumeChange]?.visible}
                                    onChange={this.averageLine.bind(this)}
                                    type="switch"
                                    label={t(DataTypes.VolumeChange)}
                                    name={DataTypes.VolumeChange}
                                />
                            </label> &nbsp;

                            <label className='selectTimeFrame' style={{ background: TIMEFRAMES.toColor(DataTypes.NumberOfTrades) }}>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={avgs[DataTypes.NumberOfTrades]?.visible}
                                    onChange={this.averageLine.bind(this)}
                                    type="switch"
                                    label={t(DataTypes.NumberOfTrades)}
                                    name={DataTypes.NumberOfTrades}
                                />
                            </label> &nbsp;

                            <label className='selectTimeFrame'>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={showOtherLines}
                                    onChange={this.onShowOtherLines.bind(this)}
                                    type="switch"
                                    label={t("Show other lines")}
                                    name="showOtherLines"
                                />
                            </label> &nbsp;

                            <label>
                                <Form.Control
                                    type="number" name='lineRed'
                                    placeholder={t("red line upper")}
                                    value={lineRed} onChange={this.onChangeLine.bind(this)}
                                    style={{ color: "red" }}
                                />

                                <Form.Control
                                    type="number" name='lineGreen'
                                    placeholder={t("green line lower")}
                                    value={lineGreen} onChange={this.onChangeLine.bind(this)}
                                    style={{ color: "green" }}
                                />
                            </label>
                        </div>
                    </Form >
                </Row >

                <hr />
                <hr />

                <hr />
                <hr />
                <Row>
                    <Col><label>from day</label> <DatePicker
                        selected={StartTime}
                        onChange={(date) => this.setState({ StartTime: moment(date).valueOf() })}
                        timeIntervals={15}
                        dateFormat="dd/MM/yyyy H:mm"
                        showTimeSelect
                        timeFormat="H:mm"
                        maxDate={EndTime}
                        placeholderText="Select a start date"
                    /></Col>

                    <Col><label>to day</label> <DatePicker
                        selected={EndTime}
                        onChange={(date) => this.setState({ StartTime: moment(date).valueOf() })}
                        timeIntervals={15}
                        dateFormat="dd/MM/yyyy H:mm"
                        showTimeSelect
                        timeFormat="H:mm"
                        maxDate={moment().valueOf()}
                        placeholderText="Select a end date"
                    /></Col>

                    <Col> <Button onClick={this.scanData.bind(this)}>scan data</Button> </Col>

                    <Col>
                        <Form.Control
                            type="password"
                            placeholder='password'
                            value={pass} onChange={e => this.setState({ pass: e.target.value })}
                        />
                        <Button onClick={this.setTimeFramesListen.bind(this)} variant="warning" disabled={TimeFrames.every(v => !v.visible)}>Save list TimeFrames</Button> &nbsp;
                        <Button onClick={this.restart.bind(this)} variant="warning" >Restart</Button>
                    </Col>
                </Row>
                <hr />
                <hr />
                <Row>
                    <ul>
                        {TimeFrames.map((tf, id) => tf.visible ? (
                            <label className='selectTimeFrame' key={id} style={{ background: TIMEFRAMES.toColor(tf.TimeFrame) }}>
                                <Form.Check // prettier-ignore
                                    type={'radio'}
                                    label={`${tf.TimeFrame}`}
                                    value={tf.TimeFrame}
                                    checked={listCoinsTimeframe == tf.TimeFrame}
                                    name="listCoinsTimeframe"
                                    onChange={this.onListCoinsTimeframeChange.bind(this)}
                                />
                            </label>) : "")}</ul>
                    <AltcoinSeasonPoints listCoins={listCoins[listCoinsTimeframe]} SymbolAltcoin={SymbolAltcoin} altcoinSeason={altcoinSeason} />
                </Row>
                <div style={{ textAlign: "center" }}>
                    version: {moment(1700725699991).format("YYYY-MM-DD HH:mm")}
                </div>
            </>
        );
    }
}


const tooltip = (label) => (
    <Tooltip>
        {label}
    </Tooltip>
);


const mapStateToProps = (state, ownProps) => ({
    wsClient: state.wsClientStore.client,
    wsHost: state.wsClientStore.wsHost,
    settings: state.SettingsStore.setting,
});

export default connect(mapStateToProps, {
})(withTranslation()(AltcoinSeasonChart));

