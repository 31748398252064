import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Web3 from "web3";
import axios from 'axios';
import { toast } from "react-toastify"
import { log, logwarn, logerror } from "../std"
import { EventEmitter } from "events";

/**
 * "changed" | "loaded" | "saved" | "NameTokenChaged"
 */
export var SettingsEvent = new EventEmitter();

// loadSettings sẽ lấy cài đặt từ localStorage
export const loadSetting = createAsyncThunk(
    "loadSettings",
    async (args, thunkAPI) => {
        let setting = JSON.parse(localStorage.getItem("setting"))
        if (setting)
            return setting
        else throw new Error("SETTING_NOT_FOUND")
    }
)

// saveSetting sẽ lưu cài đặt vào localStorage
export const saveSetting = createAsyncThunk(
    "saveSettings",
    async ({ key, value }, thunkAPI) => {
        let { setting } = await thunkAPI.getState().SettingsStore
        let _setting = JSON.parse(JSON.stringify(setting));

        console.warn(key, value)

        let keys = key.split('.');
        let lastkey = keys[keys.length - 1].trim();
        let obj = keys.slice(0, keys.length - 1).reduce((acc, key) => acc[key], _setting)

        console.warn(obj, lastkey)
        obj[lastkey] = value;

        localStorage.setItem("setting", JSON.stringify(_setting))
        if (key.includes("NameToken")) {
            SettingsEvent.emit("NameTokenChaged", value)
        }
        return _setting;
    }
)


export const Settings = createSlice({
    name: "Settings",
    initialState: {
        mainPrivateKey: null,
        setting: {
            RPCUrl: "https://koge-rpc-bsc.48.club/",
            GasPrice: 1, // * 1e9
            GasLimit: 100_000,
            Gas: 21_000,
            TimeWait: 1, // seconds
            Slippage: 2, // percent %
            AmountCoinMin: 0.003, // BNB / ETH / ...
            AmountCoinMax: 0.006, // BNB / ETH / ...

            AbiDir: "contracts/",

            telegram: {
                "token": "5774789751:AAF1DadM9LjK9FnonAbhJ_zwDRqy6WN7qD0",
                "chatId": {
                    "altcoinseasonREAL": "-985902010",
                    "altcoinseason": "-954008431",
                    "altcointest": "-954008431"
                }
            },
            language: "vi",

            domain: "coinx.trade",
        }
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(loadSetting.fulfilled, (state, action) => {
            state.setting = action.payload
            SettingsEvent.emit("loaded", action.payload)
            SettingsEvent.emit("changed", action.payload)
        })

        builder.addCase(loadSetting.rejected, (state, action) => {
            SettingsEvent.emit("changed", state.setting)
        })

        builder.addCase(saveSetting.fulfilled, (state, action) => {
            state.setting = action.payload
            SettingsEvent.emit("saved", action.payload)
            SettingsEvent.emit("changed", action.payload)
        })
    },
})


export const { } = Settings.actions;

export default Settings.reducer;