import moment from 'moment-timezone';
import momentDurationFormatSetup from "moment-duration-format";
import React, { Component, createRef, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Form, OverlayTrigger, Pagination, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { EMA, } from 'technicalindicators'
// import { createChart, LineStyle, LineType } from 'lightweight-charts';

import { getH8Times, TIMEZONES, } from '../std';

import AltcoinSeason, { StableCoins, TIMEFRAMES, PairDataTypes, SmoothType, CandlestickMap } from "../store/AltcoinSeason";
import Exchanges from '../store/Exchanges';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import "./AltcoinSeasion.scss";
import "react-datepicker/dist/react-datepicker.css";
import "./AltcoinSeasonChart.scss"
import BtnCopy from './BtnCopy';

const { createChart, LineStyle, LineType, CrosshairMode } = window.LightweightCharts

const { log, error } = console
momentDurationFormatSetup(moment);
window.moment = moment

var TimeFramesDefault = ["5m", "15m", "30m", "1h"];
let _tfd = JSON.parse(localStorage.getItem("TimeFramesListen"))
if (_tfd) {
    TimeFramesDefault = _tfd;
}


class AltcoinSeasonPair extends Component {
    state = {
        pair: "",
        isConnected: 0, // -1 là ko kết nối được với binance
        timeWating: "",
        FirstTime: true, ZoomStep: 5,
        StartTime: moment().subtract(7, 'days').valueOf(), EndTime: moment().valueOf(),
        TimeframeSelectAll: false,

        AltcoinSeasonDataType: [PairDataTypes.changed],
        TimeFrames: Object.values(TIMEFRAMES).map(tf => tf).filter(tf => typeof (tf) === "string")
            .map(tf => ({ TimeFrame: tf, visible: TimeFramesDefault.includes(tf) })),
        avgs: Object.keys(PairDataTypes).reduce((avgs, key) => { avgs[key] = { visible: false, name: key }; return avgs; }, {}),

        Symbols: [], SymbolAltcoin: "BTCUSDT", symbol: "0 symbols", symbolsType: "getTopLargestCap", numberOfSymbols: 50,
        indicator: "None", lineType: LineType.Simple, minTimeframe: TIMEFRAMES.m1,
        lineGreen: 10, lineRed: 90, rangeLines: undefined,
        candles: undefined, indicators: { EMAs: {} }, // indicators là Object của các chỉ báo, ví dụ như EMA {EMA: {12: []}}
        lines: {}, autoCalAltcoin: false,
        H8s: undefined,

        prefixAvgs: "avgs", showOtherLines: true,
        colors: {
            backgroundColor: '#222',
            lineColor: '#2962FF',
            textColor: '#ddd',
            areaTopColor: '#2962FF',
            areaBottomColor: 'rgba(41, 98, 255, 0.28)',
        },
        dataSeries: undefined, data: [],
        timezone: (new Date()).getTimezoneOffset() * (-1) / 60,
        altcoinSeason: undefined,
        points: {}, // chứa các biểu đồ đã tính toán xong, key là timeframe
        smoothType: SmoothType.EMA,
        showFloatControl: true,
    }

    constructor(props) {
        super(props);
        this.getData.bind(this)
        this.setData.bind(this)
        this.restart.bind(this)
        this.setPair.bind(this)
        this.onConnected.bind(this)
        this.fullscreen.bind(this);

        this.chart = undefined;
        this.handleResize.bind(this)
        this.chartContainerRef = createRef();
        this.floatControlRef = createRef();
        this.chartControlRef = createRef();

    }

    setPair(pair) {
        this.setState({ pair, symbol: <GetParams pair={pair} /> })
    }

    componentDidMount() {
        let { colors, data, lines, lineType, } = this.state
        let { t, } = this.props

        window.chartContainerRef = this.chartContainerRef
        this.chartContainerRef.current.scrollIntoView()
        this.connect()
        /** load settings default */
        let indicator = localStorage.getItem("indicator") || "HOLGER"
        let minTimeframe = localStorage.getItem("minTimeframe") || TIMEFRAMES.m1

        let TimeFrames = Object.values(TIMEFRAMES).map(tf => tf).filter(tf => typeof (tf) === "string" && TIMEFRAMES.toMiliSecond(minTimeframe) <= TIMEFRAMES.toMiliSecond(tf))
            .map(tf => ({ TimeFrame: tf, visible: TimeFramesDefault.includes(tf) }))

        let AltcoinSeasonDataType = ["changed"]
        try {
            AltcoinSeasonDataType = JSON.parse(localStorage.getItem("PairDataType")) || AltcoinSeasonDataType
        } catch (err) { }
        let autoCalAltcoin = localStorage.getItem("autoCalAltcoin") == "true"
        let symbolsType = localStorage.getItem("symbolsType") || "getTopLargestCap"
        let numberOfSymbols = localStorage.getItem("numberOfSymbols") || 50

        let lineRed = Number(localStorage.getItem("lineRed")) || 85
        let lineGreen = Number(localStorage.getItem("lineGreen")) || 15
        let showOtherLines = localStorage.getItem("showOtherLines") == 'true' ? true : false;

        lineType = Number(localStorage.getItem("lineType")) || LineType.Simple;

        let timezone = Number(localStorage.getItem("timezone")) || (new Date()).getTimezoneOffset() * (-1) / 60 // GMT +7
        this.setState({ timezone })

        let smoothType = localStorage.getItem("smoothType") || SmoothType.EMA;
        this.setState({ smoothType })

        /** end load settings default */

        /** init chart */
        if (!this.chart)
            window.chartPair = this.chart = createChart(this.chartContainerRef.current, {
                layout: {
                    backgroundColor: colors.backgroundColor,
                    textColor: colors.textColor,
                },
                crosshair: { mode: CrosshairMode.Normal, },
                width: this.chartContainerRef.current.clientWidth,
                height: this.chartContainerRef.current.clientHeight,
                grid: {
                    vertLines: { color: '#2f2f2f' },
                    horzLines: { color: '#2f2f2f' },
                },
                timeScale: {
                    timeVisible: true,
                    secondsVisible: false,
                },
                pane: 0,
            });

        let candles = this.chart.addCandlestickSeries({ pane: 0, })

        const rangeLines = this.chart.addLineSeries({
            color: '#ffffff45',
            lineWidth: 2,
            // disabling built-in price lines
            lastValueVisible: false,
            priceLineVisible: false,
            pane: 1,
        }); window.rangeLines = rangeLines;

        rangeLines.minLine = rangeLines.createPriceLine({ price: lineGreen, color: '#008706', lineWidth: 2, lineStyle: LineStyle.Solid, axisLabelVisible: true, });
        rangeLines.maxLine = rangeLines.createPriceLine({ price: lineRed, color: '#ef5350', lineWidth: 2, lineStyle: LineStyle.Solid, axisLabelVisible: true, });
        rangeLines.setMinMax = (min, max) => {
            rangeLines.minLine.applyOptions({ price: min })
            rangeLines.maxLine.applyOptions({ price: max })
            try {
                let { H8s, lineRed, lineGreen } = this.state
                let h8s = H8s.data()
                h8s.forEach(v => v.open = v.close = (lineRed + lineGreen) / 2)
                H8s.setData(h8s)
            } catch (err) { error(err) }
        }

        let timeDeviation = timezone * 60 * 60
        rangeLines.setData([{ value: (lineRed + lineGreen) / 2, time: parseInt(moment().valueOf() / 1000 + timeDeviation) }]);

        window.addEventListener('resize', this.handleResize.bind(this));
        this.setState({ lineGreen, lineRed, rangeLines, candles, })
        /** end init chart */
        this.fullscreen();
        this.setState({ indicator, TimeFrames, minTimeframe, AltcoinSeasonDataType, autoCalAltcoin, symbolsType, numberOfSymbols, lineRed, lineGreen, showOtherLines, lineType, })
    }

    componentWillUnmount() {
        let { exchange } = this.state
        if (exchange)
            exchange.disconnect()
    }

    connect() {
        let { indicator } = this.state;
        let exchange = new Exchanges(Exchanges.Binance);
        let altcoinSeason = new AltcoinSeason(exchange)
        altcoinSeason.Settings.indicator = indicator;


        this.setState({ altcoinSeason: altcoinSeason })

        exchange.event
            .on("connected", (e) => {
                this.setState({ isConnected: 1 })
                this.onConnected()

            }).on("error", () => {
                this.setState({ isConnected: 0 })
            })
            .on("close", () => {
                this.setState({ isConnected: -1 })
            })
            .on("retryAfter", (retryAfter) => {
                let after = moment(retryAfter);
                toast.error("Thử lại sau:" + after.format('MM/DD HH:mm:ss'))
                error("Thử lại sau:", after.format('MM/DD HH:mm:ss'))

                let diff = after.diff(moment());
                let timeleft = setInterval(() => {
                    diff = after.diff(moment());
                    if (diff > 0)
                        this.setState({ timeWating: "- " + moment(diff).format("mm:ss") + " s" })
                    else {
                        this.setState({ timeWating: "" })
                        clearInterval(timeleft)
                    }
                }, 1000);
            })
            .on("getKlines", ({ Symbol, StartTime, EndTime, timeFrame, Limit }) => {
                // this.setState({ symbol: timeFrame + " " + Symbol })
            })
            .on("getKlinesFinish", ({ Symbols, StartTime, EndTime, timeFrame, index }) => {
                this.setState({ symbol: timeFrame + "  " + index + "/" + Symbols.length + " " + Symbols[index] })
            })


        altcoinSeason.event
            .on("calPairWhenCloseKlineContinuous", (tf) => {
                let { points, smoothType, minTimeframe, } = this.state
                points[tf.name] = tf.points
                this.setData(tf.name, altcoinSeason.smoothPair(tf.points, TIMEFRAMES.toPeriod(tf.name, minTimeframe), smoothType))

                if (tf.Klines) {
                    let { indicators, candles, timezone, minTimeframe, } = this.state
                    let timeDeviation = timezone * 60 * 60
                    let Klines = tf.Klines
                    candles.setData(Klines.map(c => ({
                        time: c[CandlestickMap.OpenTime] / 1000 + timeDeviation,
                        open: Number(c[CandlestickMap.Open]),
                        high: Number(c[CandlestickMap.High]),
                        low: Number(c[CandlestickMap.Low]),
                        close: Number(c[CandlestickMap.Close]),
                    })))

                    // thêm EMA
                    let inputs = minTimeframe === TIMEFRAMES.m1 ? [30, 60, 240, 480] : [48, 96, 288, 864]
                    inputs.forEach(input => {
                        let ema = EMA.calculate({ period: input, values: Klines.map(c => Number(c[CandlestickMap.Close])) })
                        // nếu chưa có line thì tạo line
                        if (!indicators.EMAs)
                            indicators.EMAs = {}
                        if (!indicators.EMAs?.[input])
                            indicators.EMAs[input] = this.chart.addLineSeries({
                                color: TIMEFRAMES.toColor("EMA" + input),
                                lineWidth: 1,
                                lineType: LineType.Curve,
                                // disabling built-in price lines
                                lastValueVisible: false,
                                priceLineVisible: false,
                                lineStyle: LineStyle.Solid,
                                pane: 0,
                            })

                        // ghép line ema với thời gian
                        let ema_deviation = Klines.length - ema.length
                        let line = ema.map((v, i) => ({
                            time: Klines[i + ema_deviation][CandlestickMap.OpenTime] / 1000 + timeDeviation,
                            value: v,
                        }))
                        indicators.EMAs[input].setData(line)
                    })
                    window.indicators = indicators;
                    this.setState({ indicators })
                }
            })

        exchange.connect()
        this.setState({ exchange, })
    }

    async onConnected() {
        let { exchange, altcoinSeason, TimeFrames, symbolsType, } = this.state;
        let timeframes = TimeFrames.filter(tf => tf.visible).map(tf => tf.TimeFrame)

        // sự kiện thời gian còn kết thúc 1 timeframe
        exchange.timer(TimeFrames.map(tf => tf.TimeFrame))
            .on("percentageRemaining", ({ Timeframe, openTime, closeTime, percentageRemaining, elapsedTime }) => {
                // log(Timeframe, percentageRemaining, elapsedTime)
                // log(moment.utc(elapsedTime).format("HH:mm:ss"))
                let tfs = [...this.state.TimeFrames]
                tfs.forEach(tf => {
                    if (tf.TimeFrame == Timeframe) {
                        // log(Math.abs(elapsedTime), TIMEFRAMES.toMiliSecond("1d"))
                        let elapsedTime_ = Math.abs(elapsedTime)
                        let customTemplateTimeDuration = elapsedTime_ > TIMEFRAMES.toMiliSecond("1w") ? "w[w] d[d]" :
                            (elapsedTime_ > TIMEFRAMES.toMiliSecond("1d") ? "d[d] hh:mm" : "hh:mm:ss");

                        tf.remaining = moment.duration(elapsedTime, 'milliseconds').format(customTemplateTimeDuration, { trim: true });
                        tf.percentageRemaining = Math.floor(Math.abs(percentageRemaining))
                    }
                })
                this.setState({ TimeFrames: tfs })
            })

        setTimeout(() => {
            this.getData().then(() => this.chart.timeScale().fitContent())
        }, 1000);
    }

    /**
     * Bắt đầu quét dữ liệu dựa trên những timeframes đã chọn
     */
    async getData() {
        let { TimeFrames, isConnected, exchange, altcoinSeason, smoothType, pair, points, candles, indicators, minTimeframe, timezone, } = this.state;
        if (!isConnected)
            exchange.connect()

        toast("Đang tính " + minTimeframe)

        let StartTime = 0
        let EndTime = moment().valueOf()

        console.clear()
        let timeDeviation = timezone * 60 * 60
        try {
            let data = await altcoinSeason.calPairAltcoin(pair, minTimeframe, StartTime, EndTime)
            data.forEach(tf => {
                points[tf.name] = tf.points
                this.setData(tf.name, altcoinSeason.smoothPair(tf.points, TIMEFRAMES.toPeriod(tf.name, minTimeframe), smoothType))
            })

            if (data[0]) {
                let Klines = data[0].Klines
                candles.setData(Klines.map(c => ({
                    time: c[CandlestickMap.OpenTime] / 1000 + timeDeviation,
                    open: Number(c[CandlestickMap.Open]),
                    high: Number(c[CandlestickMap.High]),
                    low: Number(c[CandlestickMap.Low]),
                    close: Number(c[CandlestickMap.Close]),
                })))

                // thêm EMA
                let inputs = minTimeframe === TIMEFRAMES.m1 ? [30, 60, 240, 480] : [48, 96, 288, 864]
                inputs.forEach(input => {
                    let ema = EMA.calculate({ period: input, values: Klines.map(c => Number(c[CandlestickMap.Close])) })
                    // nếu chưa có line thì tạo line
                    if (!indicators.EMAs)
                        indicators.EMAs = {}
                    if (!indicators.EMAs?.[input])
                        indicators.EMAs[input] = this.chart.addLineSeries({
                            color: TIMEFRAMES.toColor("EMA" + input),
                            lineWidth: 1,
                            lineType: LineType.Curve,
                            // disabling built-in price lines
                            lastValueVisible: false,
                            priceLineVisible: false,
                            lineStyle: LineStyle.Solid,
                            pane: 0,
                        })

                    // ghép line ema với thời gian
                    let ema_deviation = Klines.length - ema.length
                    let line = ema.map((v, i) => ({
                        time: Klines[i + ema_deviation][CandlestickMap.OpenTime] / 1000 + timeDeviation,
                        value: v,
                    }))
                    indicators.EMAs[input].setData(line)
                })
                window.indicators = indicators;
                this.setState({ indicators })
            }

        } catch (err) { error(err) }
        this.setState({ points }, () => {
            if (this.state.autoCalAltcoin)
                this.onAutoCalAltcoinChange({ target: { checked: true } })
        })
    }

    onAutoCalAltcoinChange(e) {
        let { altcoinSeason, autoCalAltcoin, TimeFrames, minTimeframe, pair, } = this.state;
        autoCalAltcoin = e.target.checked;
        this.setState({ autoCalAltcoin }, () => {
            TimeFrames.findIndex(async tf => {
                if (tf.TimeFrame === minTimeframe) {
                    try {
                        tf.calAltcoinSeason = altcoinSeason.calPairWhenCloseKlineContinuous(pair, minTimeframe).then(loop => {
                            let { TimeFrames } = this.state;
                            TimeFrames.findIndex(async tf => {
                                if (tf.TimeFrame === minTimeframe) {
                                    tf.calAltcoinSeason = loop
                                    this.setState({ TimeFrames })
                                    return true
                                }
                                return false
                            })

                        }).catch(err => { error(err) })

                        this.setState({ TimeFrames })
                    } catch (err) { error(err) }
                    return true
                } return false;
            })
        })
    }

    /**
     * khi timeframe được bật tắt
     * @param {event} e 
     */
    onTimeFramesChanged(e) {
        let { lines, TimeFrames, AltcoinSeasonDataType, } = this.state;
        let value = e.target.value;

        TimeFrames.forEach(async tf => {
            if (tf.TimeFrame == value) {
                tf.visible = e.target.checked;
                AltcoinSeasonDataType.forEach(dataType => {
                    if (lines[value] && lines[value][dataType]) {
                        lines[value][dataType].applyOptions({ visible: tf.visible })
                    }
                })
            }
        })

        let CountVisible = TimeFrames.filter(tf => tf.visible).length;
        this.setState({
            TimeFrames: TimeFrames,
            TimeframeSelectAll: CountVisible == TimeFrames.length
        });
    }

    handleResize() {
        let { chartContainerRef } = this
        this.chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    /**
     * hiển thị altcoin season
     * @param {string} timeframe 
     * @param {Array} altcoinSeason 
     */
    async setData(timeframe, altcoinSeason) {
        let { AltcoinSeasonDataType, timezone, rangeLines, lineRed, lineGreen, lines, lineType, TimeFrames, H8s } = this.state;
        let timeDeviation = timezone * 60 * 60
        // time | volume | changed
        Object.values(PairDataTypes).forEach(dataType => {
            let data = altcoinSeason.map(v => v[dataType] ? ({
                time: parseInt(v.Time / 1000) + timeDeviation,
                value: v[dataType] ? parseFloat(v[dataType].toFixed(2)) : 0
            }) : null).filter(v => v)
            let lineSeries = lines[timeframe]?.[dataType];
            // nếu có thì xóa thêm lại
            if (lineSeries)
                try {
                    this.chart.removeSeries(lineSeries);
                } catch (err) { }

            // nếu timeframe và AltcoinSeasonDataType được chọn thì hiển thị
            let visible = (TimeFrames.find(tf => tf.TimeFrame == timeframe && tf.visible == true) ? true : false) && AltcoinSeasonDataType.includes(dataType)

            lineSeries = this.chart.addLineSeries({
                color: TIMEFRAMES.toColor(timeframe),
                lineWidth: 2,
                lineType: lineType,
                // disabling built-in price lines
                lastValueVisible: false,
                priceLineVisible: false,
                lineStyle: dataType == PairDataTypes.changed ? LineStyle.Solid :
                    (dataType == PairDataTypes.volume) ? LineStyle.LargeDashed : LineStyle.Dotted,
                pane: 1,

            });

            if ((!H8s || H8s.data().length < altcoinSeason.length) && (TIMEFRAMES.toMiliSecond(timeframe) < TIMEFRAMES.toMiliSecond("8h"))) {
                if (H8s)
                    try {
                        this.chart.removeSeries(H8s)
                    } catch (err) { }

                H8s = this.chart.addCandlestickSeries({
                    upColor: '#f79647a1', downColor: '#f79647a1', borderVisible: false,
                    wickUpColor: '#f79647a1', wickDownColor: '#f79647a1',
                    pane: 1,
                }); window.H8s = H8s;

                let from = altcoinSeason[0].Time,
                    to = altcoinSeason[altcoinSeason.length - 1].Time;
                const candles = getH8Times(from, to).map(time => ({
                    time: time / 1000 + timeDeviation,
                    open: (lineRed + lineGreen) / 2,
                    high: 100,
                    low: 0,
                    close: (lineRed + lineGreen) / 2,
                }))
                H8s.setData(candles)
                H8s.data = () => candles
                this.setState({ H8s })
            }

            lineSeries.setData(data)
            // nếu timeframe và AltcoinSeasonDataType được chọn thì hiển thị
            lineSeries.applyOptions({ visible })

            if (!lines[timeframe]) {
                lines[timeframe] = {};
            }
            lines[timeframe][dataType] = lineSeries
        })
        rangeLines.setData([{ value: (lineRed + lineGreen) / 2, time: parseInt(moment().valueOf() / 1000 + timeDeviation) }]);

        window.lines = lines
        this.setState({ lines })
    }

    /**
     * những timeframe được chọn sẽ được ẩn đi
     * hiện 1 đường trung bình dựa trên những đường đã chọn
     */
    averageLine(e) {
        let { TimeFrames, showOtherLines, AltcoinSeasonDataType, lines, avgs, prefixAvgs, timezone, } = this.state;
        let { t } = this.props;
        let { name, checked } = e.target

        let list = TimeFrames.filter(tf => tf.visible).map(v => v.TimeFrame)

        if (list.length <= 0) {
            return toast.error(t("Please choose timeframe"))
        } else {
            if (checked) {
                // tìm các line được chọn, ẩn đi, đưa data vào danh sách
                let linesData = []
                let timeframe = "", data = [];
                list.forEach((v, i) => {
                    if (lines[v]) {
                        let lineData = lines[v][name].data()
                        linesData.push([v, lineData]);
                        // tìm timeframe có độ dài data lớn nhất
                        if (data.length < lineData.length) {
                            timeframe = v;
                            data = lineData;
                        }
                    }
                })

                // tính trung bình: duyệt các phần tử ở timeframe có độ dài lớn nhất
                let avgData = data.map(v => {
                    // lấy mốc thời gian
                    let time = v.time
                    let founds = [v.value]
                    // tìm trong các timeframe khác có cùng thời điểm, đưa vào founds
                    linesData.forEach(([timeframe_, data_]) => {
                        if (timeframe !== timeframe_) {
                            let found = data_.find(v_ => v_.time === time)
                            if (found) {
                                founds.push(found.value)
                            }
                        }
                    })
                    // log(founds)
                    return {
                        time, value: founds.reduce((s, v) => s + v, 0) / founds.length
                    }
                })
                if (!lines[prefixAvgs]) lines[prefixAvgs] = {};
                let lineSeries = lines[prefixAvgs][name];
                // xóa thêm lại
                if (lineSeries)
                    this.chart.removeSeries(lineSeries)

                lines[prefixAvgs][name] = lineSeries = this.chart.addLineSeries({
                    color: TIMEFRAMES.toColor(name),
                    lineWidth: 3,
                    lineStyle: name == PairDataTypes.changed ? LineStyle.Solid :
                        (name == PairDataTypes.volume ? LineStyle.LargeDashed : LineStyle.Dotted),
                    // disabling built-in price lines
                    lastValueVisible: false,
                    priceLineVisible: false,
                    pane: 1,
                });
                lineSeries.setData(avgData);

                avgs[name].line = lineSeries
                this.setState({ lines })
            } else {
                lines[prefixAvgs][name].applyOptions({ visible: false })
            }
            avgs[name].visible = checked;
            this.setState({ avgs, lines })
            // ẩn hoặc hiện các đường timeframes
            TimeFrames.forEach(tf => {
                if (lines[tf.TimeFrame] && tf.visible)
                    Object.entries(lines[tf.TimeFrame]).forEach(([key, line]) => {
                        line.applyOptions({ visible: showOtherLines && AltcoinSeasonDataType.includes(key) })
                    })
            })
        }
    }

    /**
     ẩn hiện các đường được chọn trên biểu đồ
    */
    onShowOtherLines(e) {
        let showOtherLines = e.target.checked
        this.setState({ showOtherLines }, () => {
            let { lines, TimeFrames, AltcoinSeasonDataType, } = this.state;
            // ẩn hoặc hiện các đường đường được chọn
            TimeFrames.forEach(tf => {
                if (lines[tf.TimeFrame] && tf.visible)
                    Object.entries(lines[tf.TimeFrame]).forEach(([key, line]) => {
                        line.applyOptions({ visible: showOtherLines && AltcoinSeasonDataType.includes(key) })
                    })
            })
            localStorage.setItem("showOtherLines", showOtherLines)
        })
    }

    onTimeframeSelectAll(e) {
        let { TimeFrames, lines, AltcoinSeasonDataType } = this.state;
        let CheckAll = e.target.value.toLowerCase() == "true";
        // duyệt các timeframe, nếu CheckAll là true và timeframe đó chưa hiện, lấy localStorage, hiện tất cả 
        TimeFrames.forEach(tf => {
            tf.visible = CheckAll;
            if (CheckAll && !lines[tf.TimeFrame]) {

            }
        })
        // Ẩn hoặc hiện các timframe với CheckAll
        Object.values(lines).forEach(tf => {
            Object.entries(tf).forEach(([dataType, line]) => {
                if (AltcoinSeasonDataType.includes(dataType))
                    line.applyOptions({ visible: CheckAll });
            })
        })

        this.setState({ TimeframeSelectAll: CheckAll, TimeFrames, lines })
    }

    async scanData(e) {
        let { TimeFrames, StartTime, EndTime, Symbols, altcoinSeason } = this.state;
        let list = TimeFrames.filter(tf => tf.visible).map(tf => tf.TimeFrame)
        if (list.length > 0) {
            log("ScanData", Symbols.length, list,)
            altcoinSeason.calAltcoinSeasonMultiTimeFrames(Symbols, list, ["1d", "3d", "1w", "1M"], moment("2018-12-15", "YYYY-MM-DD").valueOf(), moment().valueOf());

        } else {
            toast.error("Vui lòng chọn Timeframes")
        }
    }

    /**
     * thay đổi timeframes khi khởi động
     */
    setTimeFramesListen(e) {
        let { TimeFrames, pass } = this.state;
        let timeFrames = TimeFrames.filter(tf => tf.visible).map(tf => tf.TimeFrame)
        localStorage.setItem("TimeFramesListen", JSON.stringify(timeFrames))
        toast("Đã lưu danh sách, vui lòng Restart")

        document.location.reload()
    }

    async restart() {
        let { pass } = this.state;
        let { sendData } = this.props;
        document.location.reload()
        // sendData({
        //     Restart: { pass: pass }
        // })
    }

    /**
     * khi kiểu chỉ báo thay đổi
     * @param {event} e 
     */
    onIndicatorChange(e) {
        let { } = this.state
        this.state.altcoinSeason.Settings.indicator = e.target.value

        this.setState({ indicator: e.target.value, })
        localStorage.setItem("indicator", e.target.value)
    }

    onAltcoinSeasonDataTypeChange(e) {
        let { AltcoinSeasonDataType } = this.state
        let { name, checked } = e.target
        if (!checked) {
            AltcoinSeasonDataType = AltcoinSeasonDataType.filter(v => v !== name)
        } else {
            AltcoinSeasonDataType = Array.from(new Set(AltcoinSeasonDataType.concat([name])))
        }
        log(name, checked, AltcoinSeasonDataType)
        this.setState({ AltcoinSeasonDataType: AltcoinSeasonDataType }, () => {
            // tìm các timeframe, hiện hoặc ẩn nó tương ứng với ${name}
            let { TimeFrames, lines } = this.state
            TimeFrames.forEach(v => {
                if (v.visible) {
                    if (lines[v.TimeFrame] && lines[v.TimeFrame][name])
                        lines[v.TimeFrame][name].applyOptions({ visible: checked })
                    else {

                    }
                }
            })
        })
        localStorage.setItem("PairDataType", JSON.stringify(AltcoinSeasonDataType))
    }

    /**
     * thay đổi đường giới hạn trên dưới, đỏ xanh
     * @param {event} e 
     */
    onChangeLine(e) {
        let { name, value } = e.target;
        localStorage.setItem(name, value)
        this.setState({ [name]: Number(value) }, () => {
            let { rangeLines, lineGreen, lineRed } = this.state
            rangeLines.setMinMax(lineGreen, lineRed)
        })
    }

    onNumberOfSymbols(e) {
        let value = Number(e.target.value)
        if (value >= 2) {
            this.setState({ numberOfSymbols: value })
            localStorage.setItem("numberOfSymbols", value)
        }
    }

    onSymbolAltcoinChange(e) {
        let SymbolAltcoin = e.target.value
        this.setState({ SymbolAltcoin })
    }

    zoom(e) {
        let { TimeFrames, lines, AltcoinSeasonDataType } = this.state;
        if (e.target.attributes.value.value === "fit")
            return this.chart.timeScale().fitContent();

        // tìm những timeframe đang hiện, cái nào có time mới nhất thì chọn làm đầu
        let timeframe = TimeFrames.find(v => v.visible)
        if (timeframe && AltcoinSeasonDataType.length > 0) {
            log(AltcoinSeasonDataType)
            // let DataType = AltcoinSeasonDataType.find(v => v.visible)
            // if (DataType) {
            log(timeframe, AltcoinSeasonDataType)
            let data = lines[timeframe.TimeFrame][AltcoinSeasonDataType[0]].data()
            let to = data[data.length - 1].time
            let rangeTime = TIMEFRAMES.toMiliSecond(e.target.attributes.value.value) / 1000;
            log({
                from: new Date((to - rangeTime) * 1000),
                to: new Date(to * 1000)
            })
            this.chart.timeScale().setVisibleLogicalRange({
                from: parseInt(to - rangeTime),
                to
            })
            // }
        }
    }

    onLineTypeChange(e) {
        let { lineType, lines, } = this.state;
        lineType = Number(e.target.value);
        this.setState({ lineType })
        localStorage.setItem("lineType", lineType)
        Object.values(lines).forEach(tf => {
            Object.values(tf).forEach(line => {
                if (line)
                    line.applyOptions({ lineType: lineType })
            })
        })
    }

    onChangeTimezone(e) {
        let timezone = e.target.value;
        localStorage.setItem("timezone", timezone)

        this.setState({ timezone: timezone }, () => {
            let { TimeFrames, rangeLines, lineRed, lineGreen, } = this.state
            let timeDeviation = timezone * 60 * 60
            rangeLines.setData([{ value: (lineRed + lineGreen) / 2, time: parseInt(moment().valueOf() / 1000 + timeDeviation) }]);

            TimeFrames.forEach(tf => {

            })
        })
    }

    onSmoothTypeChange(e) {
        this.setState({ smoothType: e.target.value }, () => {
            let { lines, points, smoothType, altcoinSeason, minTimeframe, } = this.state;
            localStorage.setItem("smoothType", smoothType)
            Object.keys(lines).forEach(timeframe => {
                log(timeframe, points[timeframe][0])
                this.setData(timeframe, altcoinSeason.smoothPair(points[timeframe], TIMEFRAMES.toPeriod(timeframe, minTimeframe), smoothType))
            })
        })
    }

    onChangeMinTimeframe(e) {
        let minTimeframe = e.target.value
        localStorage.setItem("minTimeframe", minTimeframe)
        let TimeFrames = Object.values(TIMEFRAMES).map(tf => tf).filter(tf => typeof (tf) === "string" && TIMEFRAMES.toMiliSecond(minTimeframe) <= TIMEFRAMES.toMiliSecond(tf))
            .map(tf => ({ TimeFrame: tf, visible: TimeFramesDefault.includes(tf) }))

        this.setState({ TimeFrames, minTimeframe }, () => window.location.reload())
    }

    fullscreen(e) {
        window.floatControlRef = this.floatControlRef
        this.floatControlRef.current.classList.add("float-control")
        this.chartContainerRef.current.scrollIntoView()
        window.chartControlRef = this.chartControlRef
        let chartHeight = window.innerHeight - this.chartControlRef.current.clientHeight
        this.chartContainerRef.current.style.setProperty("height", chartHeight + "px")
        this.chart.applyOptions({ height: chartHeight })
    }

    onshowFloatControl(e) {
        let { showFloatControl } = this.state;
        this.setState({ showFloatControl: !showFloatControl })
        window.floatControlRef = this.floatControlRef

        let allEls = this.floatControlRef.current.children.TimeFrames.children

        for (let index = 1; index < allEls.length; index++) {
            const el = allEls[index];
            if (showFloatControl)
                el.style.setProperty("display", "none")
            else
                el.style.removeProperty("display")
        }
    }

    render() {
        let { t, } = this.props;
        let { pair, minTimeframe, TimeFrames,
            lineGreen, lineRed, TimeframeSelectAll, showOtherLines, avgs,
            timeWating, isConnected, symbol, AltcoinSeasonDataType, autoCalAltcoin,
            timezone, lineType, smoothType, showFloatControl } = this.state;

        return (
            <>
                {/* Chart */}
                <Row>
                    <div ref={this.chartContainerRef} style={{ height: "55em" }} />
                </Row>
                {/* zoom , thu phóng theo thời gian */}
                <Row ref={this.chartControlRef}>
                    <Col>
                        <Pagination>{
                            ["1m", "5m", "15m", "30m",].map(timerange => (
                                <Pagination.Item key={timerange} value={timerange} onClick={this.zoom.bind(this)}>{timerange}</Pagination.Item>
                            ))
                        }
                            <Pagination.Item key={"fit"} value={"fit"} onClick={this.zoom.bind(this)}>🎛️ {t("Full")}</Pagination.Item>
                        </Pagination>
                    </Col>

                    <Col style={{ "display": "inline-flex" }}>
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("Curve")} name='LineType' value={LineType.Curved}
                            checked={lineType === LineType.Curved} onChange={this.onLineTypeChange.bind(this)}
                        />&nbsp;&nbsp;
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("WithSteps")} name='LineType' value={LineType.WithSteps}
                            checked={lineType === LineType.WithSteps} onChange={this.onLineTypeChange.bind(this)}
                        />&nbsp;&nbsp;
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("Simple")} name='LineType' value={LineType.Simple}
                            checked={lineType === LineType.Simple} onChange={this.onLineTypeChange.bind(this)}
                        />
                    </Col>
                    <Col style={{ "display": "inline-flex" }}>
                        {/* {làm thêm nút chuyển đổi giữa EMA và dữ liệu ban đầu} */}
                        <label>| {t("Smooth")}: &nbsp;</label>
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("None")} name='smoothType' value={SmoothType.None}
                            checked={smoothType === SmoothType.None} onChange={this.onSmoothTypeChange.bind(this)}
                        />&nbsp;&nbsp;
                        <Form.Check style={{ color: "#fff" }}
                            type="radio" label={t("EMA")} name='smoothType' value={SmoothType.EMA}
                            checked={smoothType === SmoothType.EMA} onChange={this.onSmoothTypeChange.bind(this)}
                        />&nbsp;&nbsp;
                    </Col>
                    <Col>
                        <GetParams setPair={this.setPair.bind(this)} pair={this.props.pair} />
                    </Col>

                    {/* timezone múi giờ */}
                    <Col>
                        <Form.Select aria-label="timezone" defaultValue={timezone} value={timezone} onChange={this.onChangeTimezone.bind(this)}>
                            {TIMEZONES.map((_tz => (
                                <option value={_tz.offset}>{_tz.offset} · {_tz.name}</option>
                            )))}
                        </Form.Select>&nbsp;
                        <span>
                            <OverlayTrigger overlay={<Tooltip>
                                {t("full")}
                            </Tooltip>}>
                                <img src='/images/fullscreen.png' onClick={this.fullscreen.bind(this)} style={{ cursor: "move" }} />
                            </OverlayTrigger>
                        </span>
                    </Col>
                </Row>

                {/* các nút chọn timeframe */}
                <Row className='d-flex justify-content-center' ref={this.floatControlRef}>
                    <Form name="TimeFrames">

                        <Button onClick={this.onshowFloatControl.bind(this)} style={{ position: "absolute" }}>
                            {showFloatControl ? (<FontAwesomeIcon icon="fa-eye" />) : (<FontAwesomeIcon icon="fa-eye-slash" />)}
                        </Button>&nbsp;

                        <div style={{ width: "fit-content", margin: "auto" }}>
                            <OverlayTrigger overlay={<Tooltip>
                                {t("Refresh")}
                            </Tooltip>}>
                                <Button onClick={e => window.location.reload()}><FontAwesomeIcon icon="fa-arrows-rotate" /></Button>
                            </OverlayTrigger>
                            {isConnected ? (isConnected > 0 ? (<Button variant="success" disabled>connected</Button>) : (<Button variant="warning" disabled>connected</Button>)) : (<Button variant="danger" disabled>connecting...</Button>)}
                            &nbsp; &nbsp;

                            {/* Đơn vị thời gian nhỏ nhất để tính altcoin */}
                            <OverlayTrigger overlay={<Tooltip>{t("Minimum timeframe Unit to calculator altcoin season")}</Tooltip>}>
                                <Form.Select defaultValue={minTimeframe} value={minTimeframe} onChange={this.onChangeMinTimeframe.bind(this)} className='select'>
                                    {Object.values(TIMEFRAMES).map(tf => tf).filter(tf => typeof (tf) === "string").map(tf => (<>
                                        <option value={tf}>{tf}</option>
                                    </>))}
                                </Form.Select>
                            </OverlayTrigger>
                            &nbsp; &nbsp;

                            <Button onClick={this.getData.bind(this)} disabled={!pair}> <FontAwesomeIcon icon="fa-cloud-arrow-down" /> {t("get data")}</Button> &nbsp;

                            <label style={{ fontSize: "30px", color: "red" }}>
                                {timeWating}
                            </label>

                            <label style={{ minWidth: "200px" }}>
                                {symbol}
                                {/* <Loader text={symbol} /> */}
                            </label>

                            {/* bật tắt các loại đường altcoin data */}
                            {Object.values(PairDataTypes).map(type => (
                                <label className='selectTimeFrame'>
                                    <Form.Check type="switch" checked={AltcoinSeasonDataType.includes(type)}
                                        name={type} label={t(type)}
                                        onChange={this.onAltcoinSeasonDataTypeChange.bind(this)}
                                    />
                                </label>
                            ))}

                            <Button variant={"danger"} onClick={e => { localStorage.clear(); window.location.reload(); }}><FontAwesomeIcon icon="fa-trash" /> Reset</Button>
                        </div>

                        {/* các timeframe hiển thị */}
                        <div className='selectTimeFrame'>
                            {/* Tự động tính altcoin khi đóng nến ? */}
                            <OverlayTrigger overlay={<Tooltip>
                                ⏰ {t("Auto calculator Altcoin season when close candle")}
                            </Tooltip>}>
                                <Form.Check style={{ color: "#fff" }}
                                    type="switch" label="⏰"
                                    checked={autoCalAltcoin} onChange={this.onAutoCalAltcoinChange.bind(this)}
                                />
                            </OverlayTrigger>

                            {TimeFrames.map((tf, id) => (
                                <label className='selectTimeFrame' key={id} style={{ background: TIMEFRAMES.toColor(tf.TimeFrame) }}>
                                    <Form.Check style={{ color: "#fff" }}
                                        checked={tf.visible}
                                        onChange={this.onTimeFramesChanged.bind(this)}
                                        type="switch"
                                        label={tf.TimeFrame + (tf.calAltcoinSeason ? "~" : "")}
                                        value={tf.TimeFrame}
                                        name="TimeFrames"
                                    />
                                    <ProgressBar now={tf.percentageRemaining} />
                                    <div className='time-remaining'>{tf.remaining}</div>
                                </label>))
                            }

                            <OverlayTrigger overlay={<Tooltip>
                                💾 {t("Save list TimeFrames")}
                            </Tooltip>}>
                                <Button onClick={this.setTimeFramesListen.bind(this)} variant="warning" disabled={TimeFrames.every(v => !v.visible)}>💾</Button>
                            </OverlayTrigger>

                        </div>

                        {/* Ẩn - Hiện tất cả, tính trung bình */}
                        <div className='selectTimeFrame'>
                            <label className='selectTimeFrame' key={"ShowAll"}>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={TimeframeSelectAll}
                                    onClick={this.onTimeframeSelectAll.bind(this)}
                                    type="radio"
                                    label={t("Show all")}
                                    value={true}
                                    name={"TimeframeSelectAll"}
                                />
                            </label> &nbsp;

                            <label className='selectTimeFrame' key={"HideAll"}>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={!TimeframeSelectAll}
                                    onClick={this.onTimeframeSelectAll.bind(this)}
                                    type="radio"
                                    label={t("Hide all")}
                                    value={false}
                                    name={"TimeframeSelectAll"}
                                />
                            </label> &nbsp;

                            {/* Đường trùng bình */}
                            {Object.entries(PairDataTypes).map(([key, value]) => (<>
                                <label className='selectTimeFrame' style={{ background: TIMEFRAMES.toColor(value) }}>
                                    <Form.Check style={{ color: "#fff" }}
                                        checked={avgs[value]?.visible}
                                        onChange={this.averageLine.bind(this)}
                                        type="switch"
                                        label={t(value)}
                                        name={value}
                                    />
                                </label> &nbsp;
                            </>))}
                            {/* / Đường trùng bình */}


                            <label className='selectTimeFrame'>
                                <Form.Check style={{ color: "#fff" }}
                                    checked={showOtherLines}
                                    onChange={this.onShowOtherLines.bind(this)}
                                    type="switch"
                                    label={t("Show other lines")}
                                    name="showOtherLines"
                                />
                            </label> &nbsp;

                            <label>
                                <Form.Control
                                    type="number" name='lineRed'
                                    placeholder={t("red line upper")}
                                    value={lineRed} onChange={this.onChangeLine.bind(this)}
                                    style={{ color: "red" }}
                                />

                                <Form.Control
                                    type="number" name='lineGreen'
                                    placeholder={t("green line lower")}
                                    value={lineGreen} onChange={this.onChangeLine.bind(this)}
                                    style={{ color: "green" }}
                                />
                            </label>
                        </div>
                    </Form >
                </Row >


                <div style={{ textAlign: "center" }}>
                    version: {moment(1701028729665).format("YYYY-MM-DD HH:mm")}
                </div>
            </>
        );
    }
}


const tooltip = (label) => (
    <Tooltip>
        {label}
    </Tooltip>
);


const mapStateToProps = (state, ownProps) => ({
    wsClient: state.wsClientStore.client,
    wsHost: state.wsClientStore.wsHost,
});

export default connect(mapStateToProps, {
})(withTranslation()(AltcoinSeasonPair));


function GetParams(props) {
    let [pair, setPair] = useState("");
    const params = useParams();
    const mounted = useRef();

    useEffect(() => {
        // do componentDidMount logic
        if (!mounted.current) {
            if (params.pair && params.pair.toUpperCase() !== pair) {
                setPair(params.pair.toUpperCase());
                props.setPair?.(params.pair.toUpperCase());
            } else if (props.pair && props.pair !== pair) {
                setPair(props.pair.toUpperCase());
                props.setPair?.(props.pair.toUpperCase());
            }

            mounted.current = true;
        } else {
            // do componentDidUpdate logic
            if (params.pair && params.pair.toUpperCase() !== pair) {
                setPair(params.pair.toUpperCase());
                props.setPair?.(params.pair.toUpperCase());
            } else if (props.pair && props.pair !== pair) {
                log("props", props.pair)
                setPair(props.pair.toUpperCase());
                props.setPair?.(props.pair.toUpperCase());
            }
        }
    });
    return (<>
        <a href={"https://www.binance.com/trade/" + pair} target="_blank">{pair}</a>  &nbsp; <BtnCopy value={pair} /> &nbsp;
    </>);
}